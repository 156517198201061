import React from 'react';
import giphy from "../photo_anex/giphy.png"
import discour from "../photo_anex/discour.png"
import { useTranslation } from 'react-i18next';
const Discordus = () => {
    const { t } = useTranslation();
    return (
        <div className=''>
             <h1 className="about-title">{t('about-title')}</h1>
         
            <div className='discordUs'>
                <div>
                <p className='pres_disc'>{t('pres_disc')}</p>
                <div className="giff">
                <img className="abrainn2" src={discour}></img>
                <img className="abrainn" src={giphy}></img>
                </div>
                </div>
                <div>
                <iframe src="https://discord.com/widget?id=1084466438015823892&theme=dark" width="240" height="505" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                </div>
            </div>
            <div className="footerus">
                  <p>{t('apropos')}</p>
                  <p>facebook : <a className='fb_link' href='https://www.facebook.com/people/BazNos/100095523103918/'>BazNos FB</a></p>
        <p>{t('reserv')}</p>
        <p>{t('©')}</p>
    </div>
        </div>
    );
};

export default Discordus;