import React,{useState} from 'react';
import imageSp1 from "../../nostale/all_arme/escri/30h-60h/45h.png"
import imageSp2 from "../../nostale/all_arme/escri/30h-60h/45ha.png"
import imageSp3 from "../../nostale/all_arme/escri/30h-60h/55h.png"
import imageSp4 from "../../nostale/all_arme/escri/30h-60h/55ha.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const E30h_60h = (props) => {
   const {navStyle3} = props;
   const { textHidden, toggleText } = useTextHidden();
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
      <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("sword_45h", imageSp1)}><img src={imageSp1}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("sword_45ha", imageSp2)}><img src={imageSp2}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("sword_55h", imageSp3)}><img src={imageSp3}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("sword_55ha", imageSp4)}><img src={imageSp4}></img></button>
    </div>
    );
};

export default E30h_60h;