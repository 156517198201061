import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';



const RuneC = (props) => {
  const [valeurSelect, setValeurSelect] = useState('deg_sup');
  const [valeurInput, setValeurInput] = useState(0);
  const { t } = useTranslation();
  const [check, setCheck] = useState(false);

  const checkChanges = () => {
    // Vérifier si les champs ont été modifiés
    // Par exemple, si valeurSelect et valeurInput ont été modifiés
    if (valeurInput !== 0 && valeurInput !== null) {
      // Mettre à jour setCheck si les champs ont été modifiés
      setCheck(true);
    } else {
      // Les champs n'ont pas été modifiés, pas besoin de mettre à jour setCheck
      setCheck(false);
    }
  };

  // Use useEffect to call onValueChange with initial values

  function handleButtonClick() {
    // Appeler props.onValueChange avec les valeurs actuelles de select et de l'input
    checkChanges()
    props.onValueChange(props.index, valeurSelect, valeurInput);
  }
  const confirm_rune = document.querySelector('.confirm_rune');
if (check){
  confirm_rune.style.display = 'none';
}else {
  confirm_rune.style.display = 'flex';
}

  const armec = [
    'deg_sup', 'saign_min', 'saign', 'saign_ser', 'trou_noi',
     'trou_noir_mort', 'cong', 'deg_peti_ani', 'deg_ani', 'deg_plant', 'deg_démon','deg_zomb'
     , 'chanc_cri', 'deg_crit', 'aug_feu', 'aug_eau', 'aug_lulu'
     , 'aug_obsc', 'conso_mp', 'regen_hp_vict', 'regen_mp_vict', 'att_sp'
     , 'def_sp', 'elem_sp', 'hpmp_sp'
  ];
  return (
    
    <div>
      <select className='select-add armec' value={valeurSelect} disabled={check}  onChange={e=>setValeurSelect(e.target.value)}>
            <option value={armec[0]}>{t('deg_sup')}</option>
            <option value={armec[1]}>{t('saign_min')}</option>
            <option value={armec[2]}>{t('saign')}</option>
            <option value={armec[3]}>{t('saign_ser')}</option>
            <option value={armec[4]}>{t('trou_noi')}</option>
            <option value={armec[5]}>{t('trou_noir_mort')}</option>
            <option value={armec[6]}>{t('cong')}</option>
            <option value={armec[7]}>{t('deg_peti_ani')} </option>
            <option value={armec[8]}>{t('deg_ani')}</option>
            <option value={armec[9]}>{t('deg_plant')}</option>
            <option value={armec[10]}>{t('deg_démon')}</option>
            <option value={armec[11]}>{t('deg_zomb')}</option>
            <option value={armec[12]}>{t('chanc_cri')}</option>
            <option value={armec[13]}>{t('deg_crit')}</option>
            <option value={armec[14]}>{t('aug_feu')}</option>
            <option value={armec[15]}>{t('aug_eau')}</option>
            <option value={armec[16]}>{t('aug_lulu')}</option>
            <option value={armec[17]}>{t('aug_obsc')}</option>
            <option value={armec[18]}>{t('conso_mp')}</option>
            <option value={armec[19]}>{t('regen_hp_vict')}</option>
            <option value={armec[20]}>{t('regen_mp_vict')} </option>
            <option value={armec[21]}>{t('att_sp')}</option>
            <option value={armec[22]}>{t('def_sp')}</option>
            <option value={armec[23]}>{t('elem_sp')}</option>
            <option value={armec[24]}>{t('hpmp_sp')} </option>
          </select>
          <input type="number" className="input_1"  value={valeurInput} disabled={check} min='0'  onChange={e=>setValeurInput(e.target.value)} />
          {!check ? <button type='button' className='btn_rune' onClick={handleButtonClick}>
        <span className="material-symbols-outlined">
          done_outline
        </span>
      </button>
        :
        <></>
        }
        </div>
    );
};

export default RuneC;