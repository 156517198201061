import React,{useState} from 'react';
import imageSp1 from "../../../nostale/res/chaussure/30h-60h/48h.png"
import imageSp2 from "../../../nostale/res/chaussure/30h-60h/58h.png"
import imageSp3 from "../../../nostale/res/chaussure/30h-60h/58hg.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Shoes30h_60h = (props) => {
    const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
        <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
        <button  type='button' id="sp1a" onClick={() => handleClick("shoes_48h", imageSp1)}><img src={imageSp1}></img></button>
        <button  type='button' id="sp1a" onClick={() => handleClick("shoes_58h", imageSp2)}><img src={imageSp2}></img></button>
        <button  type='button' id="sp1a" onClick={() => handleClick("shoes_58hg", imageSp3)}><img src={imageSp3}></img></button>
        </div>
    );
};

export default Shoes30h_60h;