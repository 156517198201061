import React from 'react';
import Gestion from '../Components/Gestion';
import Navbar from '../Components/Navbar';
const Gestion_Sell = () => {
    return (
        <div>                    
            <Navbar/>
            <Gestion/>
        </div>
    );
};

export default Gestion_Sell;