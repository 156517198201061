import React from 'react';
import Navbar from '../Components/Navbar';
import GoldNosBuy from '../Components/GoldNosBuy';
const Gold_Nostale = () => {
    return (
        <div>
        <Navbar/>
        <GoldNosBuy/>
        </div>
    );
};

export default Gold_Nostale;