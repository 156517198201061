import React,{useState} from 'react';
import imageSp1 from "../../../nostale/res/chaussure/60h-80h/60h.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
import imageSp2 from "../../../nostale/res/chaussure/60h-80h/80h.png"

const Shoes60_80 = (props) => {
    const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
        <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80"style={navStyle3}>
      <button type='button' id="sp1a" onClick={() => handleClick("bgant_60h", imageSp1)}><img src={imageSp1}></img></button>
        <button type='button' id="sp1a" onClick={() => handleClick("bgant_80h", imageSp2)}><img src={imageSp2}></img></button>
        </div>
    );
};
export default Shoes60_80;