import React from 'react';
import { BrowserRouter}from "react-router-dom"
import ReactDOM from 'react-dom/client';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import App from './App';
import { AuthContextProvider } from './Context/AuthContext';

const port = process.env.PORT || 3000;
const environment = process.env.ENVIRONMENT||"sandbox";
const endpoint_url = environment === "sandbox" ? "sandbox" : "product";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthContextProvider>
      <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_CLIENT_ID, "currency": "EUR" }}>

          <App/>

        </PayPalScriptProvider>
      </AuthContextProvider>
    </BrowserRouter>
  </React.StrictMode>

);

