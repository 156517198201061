import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const RuneFee = (props) => {
  const [valeurSelect, setValeurSelect] = useState('Hp_aug');
  const [valeurInput, setValeurInput] = useState(0);
  const { t } = useTranslation();
    const cellon = [
        'Hp_aug', 'Mp_aug', 'Mp_aug%', 'critic_reduc%', 'pro_receive_crit%', 'Def_aug',
        'Deg_crit%','Exp%','No_effect','damag_pvp%','Def_aug%' , 'elem_resis',
        'Exp_h','fairy','atq%','pro_crit%','Atq_aug'
      ];

  const [check, setCheck] = useState(false);
  const checkChanges = () => {
    // Vérifier si les champs ont été modifiés
    // Par exemple, si valeurSelect et valeurInput ont été modifiés
    if (valeurInput !== 0 && valeurInput !== null) {
      // Mettre à jour setCheck si les champs ont été modifiés
      setCheck(true);
    } else {
      // Les champs n'ont pas été modifiés, pas besoin de mettre à jour setCheck
      setCheck(false);
    }
  };

  const confirm_rune = document.querySelector('.confirm_rune_acces');

  if (check){
    confirm_rune.style.display = 'none';
  }else {
    confirm_rune.style.display = 'flex';
  }
  // Use useEffect to call onValueChange with initial values

  function handleButtonClick() {
    // Appeler props.onValueChange avec les valeurs actuelles de select et de l'input
    checkChanges()
    props.onValueChange(props.index, valeurSelect, valeurInput);
  }
    return (
        <div>
             <select  className='select-add armec' value={valeurSelect} disabled={check}  onChange={e=>setValeurSelect(e.target.value)}>
            <option value={cellon[0]}>{t('Hp_aug')}</option>
            <option value={cellon[1]}>{t('Mp_aug')}</option>
            <option value={cellon[2]}>{t('Mp_aug%')}</option>
            <option value={cellon[3]}>{t('critic_reduc%')}</option>
            <option value={cellon[4]}>{t('pro_receive_crit%')}</option>
            <option value={cellon[5]}>{t('Def_aug')}</option>
            <option value={cellon[6]}>{t('Deg_crit%')}</option>
            <option value={cellon[7]}>{t('Exp%')}</option>
            <option value={cellon[8]}>{t('No_effect')}</option>
            <option value={cellon[9]}>{t('damag_pvp%')}</option>
            <option value={cellon[10]}>{t('Def_aug%')}</option>
            <option value={cellon[11]}>{t('Exp_h')}</option>
            <option value={cellon[12]}>{t('fairy')}</option>
            <option value={cellon[12]}>{t('atq%')}</option>
            <option value={cellon[14]}>{t('pro_crit%')}</option>
            <option value={cellon[15]}>{t('Atq_aug')}</option>
          </select>
          <input type="number" className="input_1" value={valeurInput} disabled={check} min='0' onChange={e=>setValeurInput(e.target.value)} />
          {!check ? <button type='button' className='btn_rune' onClick={handleButtonClick}>
        <span className="material-symbols-outlined">
          done_outline
        </span>
      </button>
        :
        <></>
        }
        </div>
    );
};

export default RuneFee;