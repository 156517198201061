export const userInputs = [
    {
      id: "username",
      label: "Username :",
      type: "text",
      placeholder: "john_doe",
    },
    {
      id: "displayNaezeazme",
      label: "Name and surname :",
      type: "text",
      placeholder: "John Doe",
    },
    {
      id: "email",
      label: "Email :",
      type: "mail",
      placeholder: "john_doe@gmail.com",
    },
    {
      id: "phone",
      label: "Phone :",
      type: "text",
      placeholder: "+1 234 567 89",
    },
    {
      id: "password",
      label: "Password :",
      type: "password",
    },
    {
      id: "address",
      label: "Address :",
      type: "text",
      placeholder: "Elton St. 216 NewYork",
    },
    {
      id: "country",
      label: "Country",
      type: "text",
      placeholder: "USA",
    },
  ];
  export const productInputs = [
    {
      id: 1,
      label: "Title",
      type: "text",
      placeholder: "Apple Macbook Pro",
    },
    {
      id: 2,
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    {
      id: 3,
      label: "Category",
      type: "text",
      placeholder: "Computers",
    },
    {
      id: 4,
      label: "Price",
      type: "text",
      placeholder: "100",
    },
    {
      id: 5,
      label: "Stock",
      type: "text",
      placeholder: "in stock",
    },
  ];

  export const point_sp = [
    {
      id: "att",
      label: "ATTAQUE :",
      type: "number",
      placeholder: "?",
    },
    {
      id: "def",
      label: "DEFENSE :",
      type: "number",
      placeholder: "?",
    },
    {
      id: "ele",
      label: "ELLEMENT :",
      type: "number",
      placeholder: "?",
    },
    {
      id: "hp",
      label: "HP/MP :",
      type: "number",
      placeholder: "?",
    },
    {
      id: "att",
      label: "ATTAQUE :",
      type: "number",
      placeholder: "?",
    },
    {
      id: "res_feu",
      label: "RES FEU :",
      type: "number",
      placeholder: "?",
    },
    {
      id: "res_eau",
      label: "RES EAU :",
      type: "number",
      placeholder: "?",
    },
    {
      id: "res_lulu",
      label: "RES LULU :",
      type: "number",
      placeholder: "?",
    },
    {
      id: "res_obsc",
      label: "RES OBSCU :",
      type: "number",
      placeholder: "?",
    },
  ];

  export const runes_armeC = [
      { value: '0', label: 'Dégâts supplémentaires' },
      { value: '+1/+5', label: 'Saignée mineure' },
      { value: '+6/+9', label: 'Saignée' },
      { value: '+10', label: 'Saignée sérieuse' },
      { value: '+11', label: 'Trou noir' },
      { value: '+12', label: 'Trou noir mortel' },
      { value: '+13', label: 'Congelé' },
      { value: '+14', label: 'Augmente les dégâts petits animaux' },
      { value: '+15', label: 'Augmente les dégâts animaux' },
      { value: '+16', label: 'Augmente les dégâts plantes' },
      { value: '+17', label: 'Augmente les dégâts démons' },
      { value: '+18', label: 'Augmente les dégâts zombies' },
      { value: '+19', label: 'Augmente les chance  critique' },
      { value: '+20', label: 'Augmente les dégâts critiques' },
      { value: '0', label: 'incantation ne peut être interrompu' },
      { value: '+1/+5', label: 'Augmente l\'élément feu' },
      { value: '+6/+9', label: 'Augmente l\'élément eau' },
      { value: '+10', label: 'Augmente l\'élément lumière' },
      { value: '+11', label: 'Augmente l\'élément d\'obscurité' },
      { value: '+12', label: 'Réduit la consommation de MP' },
      { value: '+13', label: 'Régénération de HP par victoire' },
      { value: '+14', label: 'Régénération de MP par victoire' },
      { value: '+15', label: 'Augmente l\'attaque de la SP' },
      { value: '+16', label: 'Augmente la défense de la SP' },
      { value: '+17', label: 'Augmente l\'élément de la SP' },
      { value: '+18', label: 'Augmente les HP/MP de la SP' }
  ];

  export const runes_armeS = [
    { value: '0', label: '% pour les dégâts' },
    { value: '+1/+5', label: 'Augmente tous les éléments' },
    { value: '+6/+9', label: 'Augmentation générale des points de la SP' },
    { value: '+10', label: 'Augmente les dégâts sur les monstres géants' }
];

export const runes_armeSpécial = [
  { value: '0', label: 'Gagne plus d\'or' },
  { value: '+1/+5', label: 'Gagne plus d\'xp' },
  { value: '+6/+9', label: 'Gagne plus de lv de métier' }
];

export const runes_armePvp = [
  { value: '0', label: 'Diminue la défense de l\'adversaire en PVP' },
  { value: '+1/+5', label: 'Diminue la résistance au feu de l\'adversaire en PVP' },
  { value: '0', label: 'Diminue la résistance à l\'eau de l\'adversaire en PVP' },
  { value: '+1/+5', label: 'Diminue la résistance à la lumière de l\'adversaire en PVP' },
  { value: '0', label: 'Diminue la résistance à l\'obscurité de l\'adversaire en PVP' },
  { value: '+1/+5', label: ' Réduit toutes les résistances de l\'adversaire en PVP' },
  { value: '+6/+9', label: 'x% de dégâts à 15% de probabilité en PVP' },
  { value: '0', label: '% pour les dégâts en pvp' },
  { value: '+1/+5', label: 'Retire des MP à l\'adversaire en PVP' },
];


  