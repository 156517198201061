import React,{useState} from 'react';
import imageSp1 from "../../nostale/all_armure/archer/60h-90h/65h.png"
import imageSp2 from "../../nostale/all_armure/archer/60h-90h/80h.png"
import imageSp3 from "../../nostale/all_armure/archer/60h-90h/81h.png"
import imageSp4 from "../../nostale/all_armure/archer/60h-90h/86h.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const Aarmure60_80 = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
      <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("tunique_65h", imageSp1)}><img src={imageSp1}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("tunique_80h", imageSp2)}><img src={imageSp2}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("tunique_81h", imageSp3)}><img src={imageSp3}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("tunique_86h", imageSp4)}><img src={imageSp4}></img></button>
    </div>
      
    );
};

export default Aarmure60_80;