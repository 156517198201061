import React,{useState} from 'react';

import imageSp2 from "../../../nostale/res/chaussure/0h-30h/25hbetecopie.png"
import imageSp3 from "../../../nostale/res/chaussure/0h-30h/25hexpritcoppie.png"
import imageSp4 from "../../../nostale/res/chaussure/0h-30h/28hc.png"
import imageSp5 from "../../../nostale/res/chaussure/0h-30h/28hi.png"
import imageSp6 from "../../../nostale/res/chaussure/0h-30h/30h.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Shoes0h_30h = (props) => {
    const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
        <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
        <button type='button' id="sp1a" onClick={() => handleClick("25hbetecopie", imageSp2)}><img src={imageSp2}></img></button>
        <button type='button' id="sp1a" onClick={() => handleClick("25hexpritcoppie", imageSp3)}><img src={imageSp3}></img></button>
        <button type='button' id="sp1a" onClick={() => handleClick("28hc", imageSp4)}><img src={imageSp4}></img></button>
        <button type='button' id="sp1a" onClick={() => handleClick("28hi", imageSp5)}><img src={imageSp5}></img></button>
        <button type='button' id="sp1a" onClick={() => handleClick("30h", imageSp6)}><img src={imageSp6}></img></button>
        </div>
    );
};

export default Shoes0h_30h;