import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RuneS = (props) => {
  const [valeurSelect, setValeurSelect] = useState('aug_all_elem');
  const [valeurInput, setValeurInput] = useState(0);
  const { t } = useTranslation();

      const [check, setCheck] = useState(false);
      const checkChanges = () => {
        // Vérifier si les champs ont été modifiés
        // Par exemple, si valeurSelect et valeurInput ont été modifiés
        if (valeurInput !== 0 && valeurInput !== null) {
          // Mettre à jour setCheck si les champs ont été modifiés
          setCheck(true);
        } else {
          // Les champs n'ont pas été modifiés, pas besoin de mettre à jour setCheck
          setCheck(false);
        }
      };

    
      const confirm_rune = document.querySelector('.confirm_rune');
    
      if (check){
        confirm_rune.style.display = 'none';
      }else {
        confirm_rune.style.display = 'flex';
      }
  // Use useEffect to call onValueChange with initial values


  function handleButtonClick() {
    // Appeler props.onValueChange avec les valeurs actuelles de select et de l'input
    checkChanges()
    props.onValueChange(props.index, valeurSelect, valeurInput);
  }
    const armeS = [
        'aug_all_elem', 'aug_elem', 'aug_all_sp', 'aug_monster_gean',
      ];

    return (
        <div>
                          <select className='select-add armec' value={valeurSelect} disabled={check}  onChange={e=>setValeurSelect(e.target.value)}>
                <option value={armeS[0]}>{t('aug_all_elem')}</option>
                <option value={armeS[1]}>{t('aug_elem')}</option>
                <option value={armeS[2]}>{t('aug_all_sp')}</option>
                <option value={armeS[3]}>{t('aug_monster_gean')}</option>
              </select>
              <input type="number" className="input_1"  value={valeurInput} disabled={check} min='0' onChange={e=>setValeurInput(e.target.value)} />
              {!check ? <button type='button' className='btn_rune' onClick={handleButtonClick}>
        <span className="material-symbols-outlined">
          done_outline
        </span>
      </button>
        :
        <></>
        }
        </div>
    );
};

export default RuneS;