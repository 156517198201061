import React,{useState} from 'react';
import imageSp1 from "../../nostale/Famillier/avatar.png"
import imageSp2 from "../../nostale/Famillier/bori.png"
import imageSp3 from "../../nostale/Famillier/chirropt.png"
import imageSp4 from "../../nostale/Famillier/darko.png"
import imageSp5 from "../../nostale/Famillier/dino.png"
import imageSp6 from "../../nostale/Famillier/elfe.png"
import imageSp7 from "../../nostale/Famillier/fibi.png"
import imageSp8 from "../../nostale/Famillier/foot.png"
import imageSp9 from "../../nostale/Famillier/hurlu.png"
import imageSp10 from "../../nostale/Famillier/Lancemort.png"
import imageSp11 from "../../nostale/Famillier/lapin_noir.png"
import imageSp12 from "../../nostale/Famillier/léo.png"
import imageSp13 from "../../nostale/Famillier/Loutre.png"
import imageSp14 from "../../nostale/Famillier/mécha.png"
import imageSp15 from "../../nostale/Famillier/mimi_diable.png"
import imageSp16 from "../../nostale/Famillier/nouvelle_ans.png"
import imageSp17 from "../../nostale/Famillier/paladin.png"
import imageSp18 from "../../nostale/Famillier/panda.png"
import imageSp19 from "../../nostale/Famillier/petit_soricere.png"
import imageSp20 from "../../nostale/Famillier/pu_dorée.png"
import imageSp21 from "../../nostale/Famillier/pu.png"
import imageSp22 from "../../nostale/Famillier/raton.png"
import imageSp23 from "../../nostale/Famillier/ratufu.png"
import imageSp24 from "../../nostale/Famillier/reine.png"
import imageSp25 from "../../nostale/Famillier/Souricette.png"
import imageSp26 from "../../nostale/Famillier/superchance.png"
import imageSp27 from "../../nostale/Famillier/tigre_nouvelle.png"
import imageSp28 from "../../nostale/Famillier/cupcake15.png"
import imageSp29 from "../../nostale/Famillier/niege_apri.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const Familier = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
<div className='propose-ritem-group'>
            <div className='propose-ritem propose-item' style={navStyle3}>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("avatar", imageSp1)}><img src={imageSp1}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("bori", imageSp2)}><img src={imageSp2}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("chirropt", imageSp3)}><img src={imageSp3}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("darko", imageSp4)}><img src={imageSp4}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("dino", imageSp5)}><img src={imageSp5}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("elfe", imageSp6)}><img src={imageSp6}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("fibi", imageSp7)}><img src={imageSp7}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("foot", imageSp8)}><img src={imageSp8}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("hurlu", imageSp9)}><img src={imageSp9}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("Lancemort", imageSp10)}><img src={imageSp10}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("lapin_noir", imageSp11)}><img src={imageSp11}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("léo", imageSp12)}><img src={imageSp12}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("Loutre", imageSp13)}><img src={imageSp13}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("mécha", imageSp14)}><img src={imageSp14}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("mimi_diable", imageSp15)}><img src={imageSp15}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("nouvelle_ans", imageSp16)}><img src={imageSp16}></img></button>
            
            </div>
            <div className='propose-item item2 propose-ritem' style={navStyle3}>

            <button type='button' id="vente_sp1"  onClick={() => handleClick("paladin", imageSp17)}><img src={imageSp17}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("panda", imageSp18)}><img src={imageSp18}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("petit_soricere", imageSp19)}><img src={imageSp19}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("pu_dorée", imageSp20)}><img src={imageSp20}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("pu", imageSp21)}><img src={imageSp21}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("raton", imageSp22)}><img src={imageSp22}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("ratufu", imageSp23)}><img src={imageSp23}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("reine", imageSp24)}><img src={imageSp24}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("Souricette", imageSp25)}><img src={imageSp25}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("superchance", imageSp26)}><img src={imageSp26}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("tigre_nouvelle", imageSp27)}><img src={imageSp27}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("cupcak15", imageSp28)}><img src={imageSp28}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("neige_apri", imageSp29)}><img src={imageSp29}></img></button>
            </div>
        </div>
    );
};

export default Familier;