import React, { useEffect, useState,useContext } from 'react';

import { collection, query, where, getDocs,updateDoc,doc,deleteDoc } from 'firebase/firestore';

import {  getDownloadURL, ref, deleteObject } from 'firebase/storage';

import { format } from 'date-fns';

import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imageatq from "../nostale/SP/img_up/atq.png"
import imagedef from "../nostale/SP/img_up/def.png"
import imageelem from "../nostale/SP/img_up/elem.png"
import imagehpmp from "../nostale/SP/img_up/hpmp.png"
import imagefeu_res from "../nostale/SP/img_up/res_feu.png"
import imageeau_res from "../nostale/SP/img_up/res_eau.png"
import imagelulu_res from "../nostale/SP/img_up/res_lulu.png"
import imageobscu_res from "../nostale/SP/img_up/res_obscu.png"
import { AuthContext } from '../Context/AuthContext';
import nopict from "../photo_anex/no_picture.png"
import { db2, storage} from '../firebase-config' // replace with the path to your firebase configuration
import { useTranslation } from 'react-i18next';
import imgload from "../photo_anex/load.gif";
import { useTextHidden } from './TextHiddenContext';
const Gestion = () => {
  const { textHidden, toggleText } = useTextHidden();
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);

  const [showChangeprix, setShowChangeprix] = useState(false);

  const [pictureBazzar,setPictureBazzar]= useState('')

  const [modalOpen, setModalOpen] = useState(Array(data.length).fill(false));

  const [newPrice, setNewPrice] = useState(doc.prix);
    const [currentPage, setCurrentPage] = useState(1);
const [documentsPerPage] = useState(10);
  const bazarCollectionRef = collection(db2, 'vente');

            // Fonction pour calculer l'index du dernier document de chaque page
  const indexOfLastDocument = currentPage * documentsPerPage;
  // Fonction pour calculer l'index du premier document de chaque page
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  // Fonction pour récupérer les documents de la page courante
  const currentDocuments = data.slice(indexOfFirstDocument, indexOfLastDocument);
  // Fonction pour changer de page

  const paginate = (pageNumber) => {
    if (pageNumber <= Math.ceil(data.length / documentsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
  const handleShowChangePrix = (index) => {
    const newData = [...data];
    newData[index] = { ...newData[index], showChangeprix: !newData[index].showChangeprix };
    setData(newData);
  };

  useEffect(() => {
    const hourglassElement = document.querySelector('.loader');
    const fetchDocuments = async () => {
      hourglassElement.style.display = 'inline-block';



      try {
    
        const querySnapshot = await getDocs(
          query(collection(db2, 'vente'), where('Pseudonim', '==', currentUser.displayName))
        );

        const list = await Promise.all(

          querySnapshot.docs.map(async (doc) => {

            const url = await getDownloadURL(ref(storage, doc.data().image_url));

            const timeStamp = doc.data().timeStamp;

            const formattedDate = format(new Date(timeStamp.seconds * 1000), 'dd-MM-yyyy');

            return { id: doc.id, ...doc.data(), imageUrl: url, date: formattedDate ,showChangeprix: false};

          })

        );
        list.sort((a, b) => b.timeStamp.seconds - a.timeStamp.seconds);
        setData(list);
        setModalOpen(Array(list.length).fill(false)); 
 hourglassElement.style.display = 'none';
      } catch (error) {

        console.error(error);
 hourglassElement.style.display = 'none';
      }

    };

    fetchDocuments();

  }, [currentUser.displayName]);

  const handleChangePrice = (e) => {
    setNewPrice(e.target.value);
    
  };
  const handleOpenModal = (index) => {
    setSelectedDoc(data[index]);
    setModalOpen((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });
  };

  function formatDate(timeStamp) {
    const date = new Date(timeStamp.seconds * 1000);
    
   
  const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
  }

  const handleCloseModal = (index) => {
    setModalOpen((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
  };  

  const handleAcceptPrice = async (index, docId, newPrice) => {
    const docRef = doc(bazarCollectionRef, docId);
    await updateDoc(docRef, { prix: newPrice });
    const newData = [...data];
    newData[index] = { ...newData[index], prix: newPrice, showChangeprix: false };
    toast.success (t('price_suc'), {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
    setData(newData);
  };
  const [modalOpenDelete, setModalOpenDelete] = useState([]);

  const OpenDelete = (index) => {
    const newModalOpenDelete = [...modalOpenDelete];
    newModalOpenDelete[index] = true;
    setModalOpenDelete(newModalOpenDelete);
  }
  const handleDeleteItem = async (docId, index,image_url) => {
    const hourglassElement = document.querySelector('.loader');
    hourglassElement.style.display = 'inline-block';
    const docRef = doc(bazarCollectionRef, docId);
    await deleteDoc(docRef);
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
    handleCloseModalDelete(index)
    toast.success (t('ann_sup'), {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
      window.location.reload();
      hourglassElement.style.display = 'none';
    deleteObject(ref(storage, image_url)).then(() => {
})
.catch((error) => {
  toast.warn (t('err_ann'), {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    });
    hourglassElement.style.display = 'none';
});
  };
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };
  const [items, setItems] = useState(["item1", "item2", "item3"]);
  const handleCloseModalDelete = (index) => {
    const newModalOpenDelete = [...modalOpenDelete];
    newModalOpenDelete[index] = false;
    setModalOpenDelete(newModalOpenDelete);
  };
  const handleTradeSell = async (index) => {
    const docId = data[index].id;
    const newTradeValue = !data[index].trade;
  
    const docRef = doc(bazarCollectionRef, docId);
    await updateDoc(docRef, { trade: newTradeValue });
  
    const newData = [...data];
    newData[index] = { ...newData[index], trade: newTradeValue };
    setData(newData);
    toast.success (t('choic_succ'), {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
  };
  const navStyle = {
    width: textHidden ? '105%' : '100%', // Adjust '100px' to your desired width
    marginLeft: textHidden ? '-130px' : '-30px',
    transition: 'margin-left 2s',
  };
  const navStyle4 = { // Adjust '100px' to your desired width
    left: textHidden ? '45.6%' : '49%',
    transition: 'left 2s',
  };
    return (
        <div>
          <div className='div_top_gest'>
                    
                    <div className="msg_gestion">
                    <span className="material-symbols-outlined stop_esti" id="stip">
                          report
                        </span>
                        <div className="Esti_carac">
                          <p>{t('esti_1')} <span className="material-symbols-outlined text_succes">
                      redeem
                      </span> </p>
                          <p>{t('esti_2')}</p>
                          <p>{t('esti_3')}</p>
                          </div>
                    </div>
                    <h1  style={navStyle4}>{t('Geestionn_title')}</h1>
                    <div className='ta240'></div>
                    </div>
        <div className="tableau">
            <table className='Gestion_Sell' style={navStyle}>
              <thead>
                <tr>
                    <th>{t('th1')}</th>
                    <th>Name</th>
                    <th>{t('th4')}</th>
                    <th>{t('quanti')}</th>
                    <th>{t('each')}</th>
                    <th>{t('th2')}</th>
                    <th>{t('trade')}</th>
                    <th>Contact</th>
                </tr>
              </thead>
              <div className="loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
              <tbody>
              {currentDocuments.map((doc, index) => (
                  <tr key={doc.id}>
                    <th className='itemooop'>{doc.FirstPost ? 
                    <span className="material-symbols-outlined free_ad">
                      redeem
                      <span className="free_ad_text"> Free ad</span>
                      </span>
                    :
                    <></>}<img src={doc.image_gestion}/></th>
                    <th>{t(doc.itemo)}</th>
                    <th>{doc.serveur}</th>
                    <th className="quantity">
                    {doc.quantity ? doc.quantity : 1 }
                    </th>
                   
                  <th >

                    {!doc.showChangeprix ? (
                      <div className="price">
                     <div className="pricax"><p className='Gold'>{formatNumber(doc.prix * 1000000)} or</p> 
                          <p className='goldkk'>( {doc.prix}kk ) </p></div>
                      <button
                        className="modify"
                        id="change-value-button"
                        onClick={() => handleShowChangePrix(index)}
                      >
                        <span className="material-symbols-outlined" onClick={() => setNewPrice(doc.prix)}>settings</span>
                      </button>
                      </div>
                    ) : (
                      <div key={doc.id} className="Changeprix">
                        <input type="number" id="change-price-input" value={newPrice} onChange={(e) => setNewPrice(e.target.value)} />
                        <button
                          className="Accept"
                          id="change-price-Accept"
                          onClick={() => handleAcceptPrice(index, doc.id, newPrice)}
                        >
                          <span className="material-symbols-outlined">done_all</span>
                        </button>
                        <button
                          className="Canc"
                          id="change-price-Canc"
                          onClick={() => handleShowChangePrix(index)}
                        >
                          <span className="material-symbols-outlined">close</span>
                        </button>
                      </div>

                    )}
                  </th>
                  <th>{doc.date}</th>
                  <th>
                  <button
                    className={`btn_trade_sell ${doc.trade ? 'blue' : 'red'}`}
                    onClick={() => handleTradeSell(index)}
                  >
                    <span className="material-symbols-outlined">
                      deployed_code_alert
                    </span>
                  </button>
                    </th>
                  <th className='thdel'>                   
                                 <button
                                    className="detail"
                                    id="toggle-overlay"
                                    onClick={() => handleOpenModal(index)}
                                  >
                                   <span>{t('see_detai')}</span> 
                                  </button>

                                  <button className="buttonn" onClick={() => OpenDelete(index)}>
                                    <svg viewBox="0 0 448 512" className="svgIconn" ><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
                                  </button>
                                  {modalOpenDelete[index] && (
                                    <> 
                                     <div
                                      className="modal-overlay"
                                      onClick={() => handleCloseModalDelete(index)}
                                    ></div>
                                    <div key={doc.id} className="modal_del">

                                    <div className="card">
                                    <div className="card-content">
                                      <p className="card-heading">Delete file?</p>
                                      <p className="card-description">{t('real_sup')}</p>
                                    </div>
                                    <div className="card-button-wrapper">
                                      <button className="card-button secondary" onClick={() => handleCloseModalDelete(index)}>Cancel</button>
                                      <button className="card-button primary" onClick={() => handleDeleteItem(doc.id, index, doc.imageUrl)}>Delete</button>
                                    </div>
                                    <button className="exit-button" onClick={() => handleCloseModalDelete(index)}>
                                      <svg height="20px" viewBox="0 0 384 512">
                                        <path
                                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
                                    </div>
                                    </>
                                  )}
                                {modalOpen[index] && (
                                    <div
                                      className="modal-overlay"
                                      
                                    >
                                      <div key={doc.id} className="modal">
                                        <div className="picture_seller">
                                           
                                              <img src={doc.imageUrl} alt="item" />

                                        </div>
                                        <div className="Caractéristique">
                                          <button id="close-crosshair-button"> 
                                           <span className="material-symbols-outlined" onClick={() => handleCloseModal(index)}>close</span>
                                          </button> 
                                          <div className='caradroit'>
                                          <h1 className="TitleSell">{t('TitleSell')}</h1>
                                          {doc.runeValues ? (
                                          <ul className='ul_weapons'>
                                            {doc.up ? <div className='caract'>
                                              <p className='upeustre'>UP : +{doc.up}</p>
                                              <p className='raritu'>Rarity : +{doc.rarity}</p>
                                            </div> : <></>
                                            }
                                            {doc.runeValues.map((rune, index) => (
                                              <li key={index}>
                                                {rune.hasOwnProperty('selectValue') 
                                                ? `${t(`${rune.selectValue}`)}: ${rune.inputValue}`
                                                : `${t(`${rune.field}`)}: ${rune.value}`
                                                }
                                              </li>
                                            ))}
                                          </ul>
                                          ) : (
                                            
                                          <li className="list-carac">
                                            {doc.Attaque === 0 || doc.Attaque ?<><ul>
                                              <div className='caract'>
                                              <p className='upeustre'>Up : +{doc.up}</p>
                                              <p className='upeustre'>Amélio : +{doc.amelio}</p>
                                              </div>
                                              {t('Attaque')}  <img src={imageatq} className='img_sp'/> : <a>{doc.Attaque}</a>
                                            </ul>
                                            <ul>
                                              {t('Element')} <img src={imageelem} className='img_sp'/> : <a>{doc.Element}</a>
                                            </ul>
                                            <ul>
                                              {t('HpMp')} <img src={imagehpmp} className='img_sp'/> : <a>{doc.HpMp}</a>
                                            </ul>
                                            <ul>
                                              {t('Defense')} <img src={imagedef} className='img_sp'/> : <a>{doc.Defense}</a>
                                            </ul>
                                            <ul>
                                              {t('Res_Eau')}<img src={imageeau_res} className='img_sp'/> : <a>{doc.Res_Eau}</a>
                                            </ul>
                                            <ul>
                                              {t('Res_Feu')} <img src={imagefeu_res} className='img_sp'/> : <a>{doc.Res_Feu}</a>
                                            </ul>
                                            <ul>
                                              {t('Res_Obscu')} <img src={imageobscu_res} className='img_sp'/> : <a>{doc.Res_Lulu}</a>
                                            </ul>
                                            <ul>
                                              {t('Res_Lulu')} <img src={imagelulu_res} className='img_sp'/> : <a>{doc.Res_obscu}</a>
                                            </ul></> :
                                             <> 
                                             
                                             {doc.atq ?<div className='petSkill'>
                                             
                                                    <ul>
                                                    {t('att_pet')} : <a>+{doc.atq}</a>
                                                    </ul>
                                                    <ul>
                                                    {t('def_pet')} : <a>+{doc.def}</a>
                                                    </ul>
                                            </div> : <div className="Petskill">
                                            {doc.firstSkill ? 
                                          <>
                                        <ul>
                                        {t('1 Skill')}  :<a>  {doc.firstSkill} </a>
                                      </ul>
                                      <ul>
                                        {t('2 Skill')} : <a>  {doc.secondSkill}</a>
                                      </ul>
                                      <ul>
                                        {t('3 Skill')} : <a>  {doc.threeSkill}</a>
                                      </ul>
                                      </>                            
                                      : 
                                      <>
                                       <ul>
                                      {t('Res_Eau')}  <img src={imageeau_res} className='img_sp'/> : <a>{doc.Res_Eau} </a>
                                    </ul>
                                    <ul>
                                      {t('Res_Feu')}  <img src={imagefeu_res} className='img_sp'/> :  <a>{doc.Res_Feu}</a>
                                    </ul>
                                    <ul>
                                      {t('Res_Obscu')}  <img src={imageobscu_res} className='img_sp'/> :  <a>{doc.Res_Lulu}</a>
                                    </ul>
                                    <ul>
                                      {t('Res_Lulu')}  <img src={imagelulu_res} className='img_sp'/> :  <a>{doc.Res_obscu}</a>
                                    </ul>
                                     </>
                                      }
                                            </div>
                                            
                                            }
                                              </>
                                              
                                             }
                                            
                                          </li>
                                          )}
                                          </div>
                                        </div>
                                        
                                      </div>
                                    </div>
                                    )}
        </th>

      </tr> 
                 )) 
                }
<div className="pagination">
  {data.length > 0 &&
    [...Array(Math.min(9, Math.ceil(data.length / documentsPerPage))).keys()].map((pageNumber) => (
      <button key={pageNumber} onClick={() => paginate(pageNumber + 1)}>
        {pageNumber + 1}
      </button>
    ))}
  {currentPage < Math.ceil(data.length / documentsPerPage) && (
    <button onClick={() => paginate(currentPage + 1)}>{">"}</button>
  )}
</div>
              </tbody>
            </table>

          </div>
          <ToastContainer />
          <div className="footerus">
                  <p>{t('apropos')}</p>
                  <p>facebook : <a className='fb_link' href='https://www.facebook.com/people/BazNos/100095523103918/'>BazNos FB</a></p>
        <p>{t('reserv')}</p>
        <p>{t('©')}</p>
    </div>
        </div>
    );
};

export default Gestion;