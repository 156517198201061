import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RuneSpe = (props) => {
  const [valeurSelect, setValeurSelect] = useState('more_gold');
  const [valeurInput, setValeurInput] = useState(0);
  const { t } = useTranslation();

      const [check, setCheck] = useState(false);
      const checkChanges = () => {
        // Vérifier si les champs ont été modifiés
        // Par exemple, si valeurSelect et valeurInput ont été modifiés
        if (valeurInput !== 0) {
          // Mettre à jour setCheck si les champs ont été modifiés
          setCheck(true);
        } else {
          // Les champs n'ont pas été modifiés, pas besoin de mettre à jour setCheck
          setCheck(false);
        }
      };

    
      const confirm_rune = document.querySelector('.confirm_rune');
    
      if (check){
        confirm_rune.style.display = 'none';
      }else {
        confirm_rune.style.display = 'flex';
      }
  // Use useEffect to call onValueChange with initial values
  function handleButtonClick() {
    // Appeler props.onValueChange avec les valeurs actuelles de select et de l'input
    checkChanges()
    props.onValueChange(props.index, valeurSelect, valeurInput);
  }
  const armeSpé = ['more_gold', 'more_xp', 'lvl_job'];

  return (
    <div>
      <select className='select-add armec'  value={valeurSelect} disabled={check}  onChange={e=>setValeurSelect(e.target.value)}>
        <option value={armeSpé[0]}>{t('more_gold')}</option>
        <option value={armeSpé[1]}>{t('more_xp')}</option>
        <option value={armeSpé[2]}>{t('lvl_job')}</option>
      </select>
      <input type="number" className="input_1" value={valeurInput} disabled={check} min='0' onChange={e=>setValeurInput(e.target.value)} />
      {!check ? <button type='button' className='btn_rune' onClick={handleButtonClick}>
        <span className="material-symbols-outlined">
          done_outline
        </span>
      </button>
        :
        <></>
        }
    </div>
  );
};

export default RuneSpe;