import React, {  useEffect,useState, useContext } from 'react';
import Select from 'react-select';
import { AuthContext } from '../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { db2, storage,auth } from '../firebase-config';
import { collection, doc ,getDocs, where,query,serverTimestamp,addDoc,getDoc,deleteDoc,updateDoc } from 'firebase/firestore';
  import { getStorage, ref,uploadBytes,getDownloadURL } from "firebase/storage";
import { useTranslation } from 'react-i18next';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import imgload from "../photo_anex/load.gif" ;
import{ Cz,
  De,
  Us,
  Es,
  Fr,
  Hk,
  It,
  Pl,
  Tr } from 'react-flags-select';
  import { useTextHidden } from './TextHiddenContext';
const CountriePlayer = ({ country }) => {
  switch (country) {
    case "CZ":
      return <Cz />;
    case "DE":
      return <De />;
    case "US":
      return <Us />;
    case "ES":
      return <Es />;
    case "FR":
      return <Fr />;
    case "HK":
      return <Hk />;
    case "IT":
      return <It />;
    case "PL":
      return <Pl />;
    case "TR":
      return <Tr />;
    default:
      return <div>Country not supported</div>;
  }
};

const GoldNosBuy = () => {
  const [pseudo,setPseudo]=useState('')
  const [dislikeprofil, setdisLikeProfil] = useState(0);
  const [likeprofil, setLikeProfil] = useState(0);
  const { textHidden, toggleText } = useTextHidden();
    const { t } = useTranslation();
    const [gif_user_url, setGif_user_url] = useState('');
    const [serveur,setServeur]= useState();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [otheruid, setotheruid] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const hourglassElement = document.querySelector('.loader');
    const [documentsPerPage] = useState(10);
    const indexOfLastDocument = currentPage * documentsPerPage;
    const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
    const currentDocuments = data.slice(indexOfFirstDocument, indexOfLastDocument);
    const [country,setCountry]=useState('');
    const [discord, setDiscord] = useState('');
    const [comment, setComment] = useState('');
    const [pictureSpUserUrl, setPicture_sp_user_url] = useState('');
    const [modalOpen, setModalOpen] = useState(Array(data.length).fill(false));
    const { currentUser } = useContext(AuthContext);
    
    const [overlayVisible, setOverlayVisible] = useState(false);

    const handleBtnShowClick = async (index, doc) => {

      hourglassElement.style.display = 'inline-block';
      setLoading(true); // set loading state
      try {
        const recipientUid = doc.senderUid;
        await fetchUserCountry(recipientUid);
        setOverlayVisible(true);
        // ... rest of your code
      } catch (error) {
        console.error('Error handling button click:', error);
        hourglassElement.style.display = 'none';
      } finally {
        setLoading(false); // clear loading state, whether successful or not
        hourglassElement.style.display = 'none';
      }
    };
    function handleBtnMasqClick() {
      setOverlayVisible(false);
    }
    const paginate = (pageNumber) => {
      if (pageNumber <= Math.ceil(data.length / documentsPerPage)) {
        setCurrentPage(pageNumber);
      }
    };
    const navStyle = {
      width: textHidden ? '90%' : '85%', // Adjust '100px' to your desired width
      marginLeft: textHidden ? '120px' : '235px',
      transition: 'margin-left 2s',
    };

  const [sortAscending, setSortAscending] = useState(true); // State to track sorting order

    const handleSortClick = () => {
        // Toggle the sorting order when the button is clicked
        setSortAscending((prevSortAscending) => !prevSortAscending);
        sortData(); // Call the sorting function
    };

    const sortData = () => {
        const sortedData = [...data]; // Create a copy of the data array
        sortedData.sort((a, b) => {
            // Compare the prices (doc.prix) of two documents
            return sortAscending ? a.prix - b.prix : b.prix - a.prix;
        });
        setData(sortedData); // Update the data with the sorted array
    };
    
    function formatDate(timeStamp) {
      const date = new Date(timeStamp.seconds * 1000);
     
    const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
    }

    const [modalOpenDelete, setModalOpenDelete] = useState([]);

    const OpenDelete = (index) => {
      const newModalOpenDelete = [...modalOpenDelete];
      newModalOpenDelete[index] = true;
      setModalOpenDelete(newModalOpenDelete);
    }
    
    const handleDeleteAnnouncement = async (docId) => {
      try {
          // Delete the announcement with the specified docId
          await deleteDoc(doc(db2, "Sell_gold", docId));
          await handleSelectChange();
          // Re-fetch the data after deletion
          // ... (code to re-fetch the data)
      } catch (error) {
          console.error("Error deleting announcement:", error);
      }
  };

    const handleCloseModalDelete = (index) => {
      const newModalOpenDelete = [...modalOpenDelete];
      newModalOpenDelete[index] = false;
      setModalOpenDelete(newModalOpenDelete);
    };
    const Sellgold = (e)=>{
        navigate('/sellgoldnst');
    }
    const handleCloseModal = (index) => {
      setModalOpen((prev) => {
        const newState = [...prev];
        newState[index] = false;
        return newState;
      });
    };  

    const handleSelectChange = async (selectedOption) => {
      setData([])
      const selectedServeur = selectedOption.value;
      setServeur(selectedServeur); // Updating serveur state

        try {
          hourglassElement.style.display = 'inline-block';
          let queryRef = collection(db2, "Sell_gold");
          const querySnapshot = await getDocs(query(queryRef, where("serveur", "==", selectedServeur)));
    

          const list = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              const timeStamp = doc.data().timeStamp;
              const formattedDate = format(new Date(timeStamp.seconds * 1000), 'dd-MM-yyyy');
        
              // retrieve the senderUid value from the current document
              const senderUid = doc.data().senderUid;
        
              // Assuming there's a function called 'fetchUserCountry' defined somewhere
              

              return { id: doc.id, ...doc.data(), date: formattedDate };
            })     
          );
          
          setData(list);
          hourglassElement.style.display = 'none';
        } catch (err) {
          console.error(err);
          hourglassElement.style.display = 'none';
        }
      }
      const fetchUserCountry = async (senderUid) => {
        try {
          const uid = currentUser.uid;
      
          const docRef = doc(db2, 'users', senderUid);
          const docSnap = await getDoc(docRef);
      
          const docRef2 = doc(db2, 'score', senderUid);
          const docSnap2 = await getDoc(docRef2);
      
          if (docSnap.exists()) {
            const data = docSnap.data();
            const userDiscord = data.Discord || '';
            const userCountry = data.country || '';
            const userComment = data.comment || '';
            const userPicture = data.pdp_src || '';
            const userGif = data.gif_src || '';
            const userpseudo = data.displayName || '';
            setPseudo(userpseudo)
            setotheruid(senderUid);
            setCountry(userCountry);
            setDiscord(userDiscord);
            setComment(userComment);
      
            const data2 = docSnap2.data();
            const likeCount = (data2.like && data2.like.length) || 0;
            const dislikeCount = (data2.dislike && data2.dislike.length) || 0;
      
            setLikeProfil(likeCount);
            setdisLikeProfil(dislikeCount);
      
            const picture_sp_user = await getDownloadURL(ref(storage, userPicture));
            setPicture_sp_user_url(picture_sp_user);
      
            const gif_user_url = await getDownloadURL(ref(storage, userGif));
            setGif_user_url(gif_user_url);
          }
        } catch (error) {
          console.error('Error fetching user country:', error);
        } finally {
          setLoading(false);
        }
      };

      const Serveur = [
        { value: 'Valehir', label: 'Valehir' },
        { value: 'Alzanor', label: 'Alzanor' },
        { value: 'Dragonveil', label: 'Dragonveil' },
        { value: 'Cosmos', label: 'Cosmos' },
        { value: 'Jotunheim', label: 'Jotunheim' }
      ];
      const customStyles = {
        container: (provided) => ({
          ...provided,
          width: '150px',
          fontSize: '15px',
          position: 'relative',
          cursor: 'pointer',
          display: 'inline-block' // add this to align with the original .list-choice
        }),
        control: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          color: '#FFF',
          textShadow: '0 1px 0 rgba(144, 142, 142, 0.66)',
          borderRadius: '0.2em',
          border: 'none',
          cursor: 'pointer',
          display: 'flex', // center selected value
          justifyContent: 'center', // center selected value
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          borderRadius: '0.2em',
          overflow: 'hidden'
        }),
        option: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          color: '#FFF',
          cursor: 'pointer'
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#FFF',
          background: 'rgb(116,214,142)',
          background: 'linear-gradient(102deg, rgba(116,214,142,1) 0%, rgba(116,214,142,0.04525560224089631) 59%)',
          height: '39px',
          borderRadius: '2.4px 6px 6px 2.4px',
          marginLeft:'-8px',
          width: '113px', // fill width of control
          display: 'flex',
          alignItems: 'center',
          marginTop: '-1.8px',
          marginBottom: '-2px',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#FFF'
        })
      };
      useEffect(() => {
        // Fetch the initial data and sort it
        handleSelectChange();
        sortData();
    }, [serveur]); // Re-fetch and sort data when the serveur state changes

    const handleDeleteItem = async (docId, index,image_url) => {
      
      await deleteDoc(doc(db2, "Sell_gold", docId));
      await handleSelectChange();
      const newData = [...data];
      newData.splice(index, 1);
      setData(newData);
      handleCloseModalDelete(index)

      .catch((error) => {
  });
    };

    async function handledisLike(index, docs) {
      const recipientUid = docs[index]?.senderUid;
  
      if (!recipientUid) {
          console.error("L'UID du destinataire est manquant.");
          return;
      }
      if (recipientUid === currentUser.uid) {
        toast.warn ('Vous ne pouvez pas votez pour vous même ', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        return}
      const docRef = doc(db2, 'score', recipientUid);
      const docSnapshot = await getDoc(docRef);
      const uid = currentUser.uid;
  
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();

        // Vérifier si current.uid est dans like
        if (!userData.like || userData.like.indexOf(uid) === -1) {
            // Vérifier si current.uid est déjà dans dislike
            if (!userData.dislike || userData.dislike.indexOf(uid) === -1) {
                // Ajouter current.uid à dislike s'il n'est pas déjà présent
                const updatedDislikeprofil = [...(userData.dislike || []), uid];

                // Mettre à jour le document avec la nouvelle liste dislike
                await updateDoc(docRef, { dislike: updatedDislikeprofil });

                // Incrémenter la valeur affichée
                const dislikeCount = updatedDislikeprofil.length;
                const dislikeTextContent = document.querySelector('.dislike-text-content');
                if (dislikeTextContent) {
                    dislikeTextContent.innerText = dislikeCount;
                } else {
                    console.error("L'élément .dislike-text-content est manquant.");
                }
            } else {
              toast.warn ('Vous Dislike déjà cette personne', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            }
        } else {
          toast.warn ('L utilisateur like cette personne ', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            })
        }
    } else {
        console.error("Le document n'existe pas pour l'UID du destinataire : ", recipientUid);
    }
  }

  async function handleLike(index, docs) {
    const recipientUid = docs[index]?.senderUid;

    if (!recipientUid) {
        console.error("L'UID du destinataire est manquant.");
        return;
    }
    if (recipientUid === currentUser.uid) {
      toast.warn ('Vous ne pouvez pas votez pour vous même ', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
      return}
    const docRef = doc(db2, 'score', recipientUid);
    const docSnapshot = await getDoc(docRef);
    const uid = currentUser.uid;

    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();

      // Vérifier si current.uid est dans dislike
      if (!userData.dislike || userData.dislike.indexOf(uid) === -1) {
          // Vérifier si current.uid est déjà dans like
          if (!userData.like || userData.like.indexOf(uid) === -1) {
              // Ajouter current.uid à like s'il n'est pas déjà présent
              const updatedLikeprofil = [...(userData.like || []), uid];

              // Mettre à jour le document avec la nouvelle liste like
              await updateDoc(docRef, { like: updatedLikeprofil });

              // Incrémenter la valeur affichée
              const likeCount = updatedLikeprofil.length;
              const likeTextContent = document.querySelector('.like-text-content');
              if (likeTextContent) {
                  likeTextContent.innerText = likeCount;
              } else {
                  console.error("L'élément .like-text-content est manquant.");
              }
          } else {
            toast.warn ('L utilisateur like déjà cette personne ', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              })
          }
      } else {
        toast.warn ('Vous Dislike cette personne', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
      }
  } else {
      console.error("Le document n'existe pas pour l'UID du destinataire : ", recipientUid);
  }
}
    return (
        <>
        <div className='buy_goldnos'>
            <h3>{t('buygoldnos')}</h3>
            <div className='btn_top'>
            <Select styles={customStyles} options={Serveur} placeholder={t('Serveur')} onChange={handleSelectChange}/>
            <button className='creat_post' onClick={Sellgold}>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"></path></svg> Create
              </span>
            </button>
            </div>
        </div>
        <div className="loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
         <div>
            
         <table className='tablegold' style={navStyle}>
             <thead>
                 <th>
                    <p>{t('prixsellgold')}
                    <button className='btn_tri' onClick={handleSortClick}>
                    <span className="material-symbols-outlined">
                        {sortAscending ? "expand_more" : "expand_less"}
                    </span>
                </button>
                    </p>
                 </th>
                 <th>
                 {t('quanti')} 
                 </th>
                 <th>
                 {t('th2')}              
                 </th>
                 <th>
                 {t('Paiement')}                
                 </th>
                 <th>
                 {t('disc_nav')}               
                 </th>
           
             </thead>

             <tbody>
             {data.length > 0 ? (
               currentDocuments.map((doc, index) => (
                <tr key={doc.id} className='lign_gold'>
             
                <th>{doc.prix}€/1KK</th>
                <th>{doc.quantity} KK</th>
                <th>{doc.date}</th>
                <th>
                  <div className='pay_info'>
                    <div>
                  <p>
                  {t('CarteB')}:
                    {doc.cb ? (
                      <span className="material-symbols-outlined text_succes">check</span>
                    ) : (
                      <span className="material-symbols-outlined text_errir">close</span>
                    )}
                  </p>
                    <p> {t('PayPal')}:
                    {doc.paypal ? (
                      <span className="material-symbols-outlined text_succes">check</span>
                    ) : (
                      <span className="material-symbols-outlined text_errir">close</span>
                    )}
                 </p>
                 </div>
                 <div>
                    <p> {t('Paysafecard')}: 
                     {doc.psf ? (
                      <span className="material-symbols-outlined text_succes">check</span>
                    ) : (
                      <span className="material-symbols-outlined text_errir">close</span>
                    )}
                  </p>
                    <p> {t('Crypto')}: 
                    {doc.crypto ? (
                      <span className="material-symbols-outlined text_succes">check</span>
                    ) : (
                      <span className="material-symbols-outlined text_errir">close</span>
                    )}
                  </p>
                  </div>
                  </div>
                </th>
                <th className='thdel'><div>                                    
                <button className="learn-more" onClick={() => handleBtnShowClick(index, doc)}>
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow"></span>
                  </span>
                  <span className="button-text">Show Page</span>
                </button>
                                         </div>

                {overlayVisible && (
                                            <div className='modal-overlay'>
                                              <div className='PagePlayer'>  
                                                <div className='topeur'>
                                                <button className='close__pagplayer' onClick={handleBtnMasqClick}> 
                                                  <span className="material-symbols-outlined">close</span>
                                                 </button> 
                                                  <h1>{pseudo}</h1>
                                                  <div className='Info_playeer'>
                                                    <img src={pictureSpUserUrl} className="Picture_profil player__src" alt="Profile"></img>
                                                    <CountriePlayer country={country} className="picture_countr" />
                                                    <div className='discrd playyyer'>
                                                    Discord : {discord} 
                                                    </div>

                                                  </div>
                                                  <div className='Commantère'>
                                                  <p className='Com_playeur'>{comment}</p>
                                                  <div className='gif_note'>
                                                  <div >  
                                                    <div className='Lik'>
                                                    <div className="like-dislike-container">
                                                          <div className="tool-box">
                                                            <button className="btn-close">×</button>
                                                          </div>
                                                          <p className="text-content">What did you think<br/>of this Guys?</p>				
                                                          <div className="icons-box">
                                                            <div className="icons">
                                                              <label className="btn-label" for="like-checkbox">
                                                                <span className="like-text-content">{likeprofil}</span>
                                                                <input className="input-box"  onClick={() => handleLike(index, data)} id="like-checkbox" type="checkbox"/>
                                                                <svg className="svgs" id="icon-like-solid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"></path></svg>
                                                                <svg className="svgs" id="icon-like-regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.1s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z"></path></svg>
                                                                <div className="fireworks">
                                                                  <div className="checked-like-fx"></div>
                                                                </div>
                                                              </label>
                                                            </div>
                                                            <div className="icons">
                                                              <label className="btn-label" for="dislike-checkbox">
                                                                <input onClick={() => handledisLike(index, data)} className="input-box" id="dislike-checkbox" type="checkbox"/>
                                                                <div className="fireworks">
                                                                  <div className="checked-dislike-fx"></div>
                                                                </div>
                                                                <svg className="svgs" id="icon-dislike-solid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"></path></svg>
                                                                <svg className="svgs" id="icon-dislike-regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.1s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z"></path></svg>
                                                                <span className="dislike-text-content">{dislikeprofil}</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                  <img  className="real_gif_player" src={gif_user_url}></img>
                                                  </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
          <button className="buttonn"  onClick={() => OpenDelete(index)} style={{ display: currentUser.uid === doc.senderUid ? '' : 'none' }}>
          <svg viewBox="0 0 448 512" className="svgIconn" ><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path></svg>
          </button>
          {modalOpenDelete[index] && (
          <>
            <div
              className="modal-overlay"
              onClick={() => handleCloseModalDelete(index)}
            ></div>
            <div key={doc.id} className="modal_del">
            <div className="card">
                                    <div className="card-content">
                                      <p className="card-heading">Delete file?</p>
                                      <p className="card-description">{t('real_sup')}</p>
                                    </div>
                                    <div className="card-button-wrapper">
                                      <button className="card-button secondary" onClick={() => handleCloseModalDelete(index)}>Cancel</button>
                                      <button className="card-button primary" onClick={() => handleDeleteItem(doc.id, index, doc.imageUrl)}>Delete</button>
                                    </div>
                                    <button className="exit-button" onClick={() => handleCloseModalDelete(index)}>
                                      <svg height="20px" viewBox="0 0 384 512">
                                        <path
                                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
            </div>
          </>
        )}
          </th>

              </tr>
              ))
            ) : (
              // Sinon, affichez une colonne spéciale indiquant l'absence de données (en utilisant la traduction "no_data").

                                          <>
                                          <th>  <br></br></th>
                                          <tr className='thmid'>
                                          <th></th>
                                          <th><div>
                    {t('no_data')}
                    <p className='text_danger'>❗{t('midlaner')}❗</p>
                  </div></th>
                                          <th>                </th>
                                          <th></th>
                                          <th></th>
                                          <th></th>
                                          </tr>
                                          <th>  <br></br></th>
                                          </>
            )}
             </tbody>
             
             <div className="pagination">
  {data.length > 0 &&
    [...Array(Math.min(9, Math.ceil(data.length / documentsPerPage))).keys()].map((pageNumber) => (
      <button key={pageNumber} onClick={() => paginate(pageNumber + 1)}>
        {pageNumber + 1}
      </button>
    ))}
  {currentPage < Math.ceil(data.length / documentsPerPage) && (
    <button onClick={() => paginate(currentPage + 1)}>{">"}</button>
  )}
</div>
<ToastContainer />
         </table>
         <div className="footerus">
                  <p>{t('apropos')}</p>
                  <p>facebook : <a className='fb_link' href='https://www.facebook.com/people/BazNos/100095523103918/'>BazNos FB</a></p>
        <p>{t('reserv')}</p>
        <p>{t('©')}</p>
    </div>
    
         </div>
         
         </>
    );
};

export default GoldNosBuy;