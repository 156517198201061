import React, {  useEffect,useState, useContext } from 'react';
import Select from 'react-select';
import { auth,db2,storage } from '../firebase-config';
import { collection, doc ,getDocs, where,query,serverTimestamp,addDoc,getDoc,deleteDoc,orderBy,updateDoc } from 'firebase/firestore';
import { getStorage, ref,uploadBytes,getDownloadURL } from "firebase/storage";
import { format } from 'date-fns';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from '../Context/AuthContext';
import SpArcher from '../tri_Baz_acheter/SP/SpArcher';
import { useTextHidden } from './TextHiddenContext';
import AilesVente from '../tri_Baz_acheter/Divers/Ailes'
import BoxRaid from '../tri_Baz_acheter/Divers/BoxRaid'
import Diversuu from '../tri_Baz_acheter/Divers/Divers'
import Item_nosmall from '../tri_Baz_acheter/Divers/item_nosmall'
import Matériaux_amelio from '../tri_Baz_acheter/Divers/matériaux_amelio'
import Matériaux_up from '../tri_Baz_acheter/Divers/matériaux_up'
import Parchemin from '../tri_Baz_acheter/Divers/parchemin'
import imageatq from "../nostale/SP/img_up/atq.png"
import imagedef from "../nostale/SP/img_up/def.png"
import imageelem from "../nostale/SP/img_up/elem.png"
import imagehpmp from "../nostale/SP/img_up/hpmp.png"
import imagefeu_res from "../nostale/SP/img_up/res_feu.png"
import imageeau_res from "../nostale/SP/img_up/res_eau.png"
import imagelulu_res from "../nostale/SP/img_up/res_lulu.png"
import imageobscu_res from "../nostale/SP/img_up/res_obscu.png"
import SpEscri from '../tri_Baz_acheter/SP/SpEscri';
import SpMage from '../tri_Baz_acheter/SP/SpMage';
import SpAutre from '../tri_Baz_acheter/SP/SpAutre';
import SpAM from '../tri_Baz_acheter/SP/Sp_AM';
import imagetest from '../nostale/anex/test.PNG'
import A80_95 from '../tri_Baz_acheter/Arme_a/A80_95';
import A0h_30h from '../tri_Baz_acheter/Arme_a/A0h_30h';
import A30h_60h from '../tri_Baz_acheter/Arme_a/A30h_60h';
import A60h_80h from '../tri_Baz_acheter/Arme_a/A60h_80h';

import M80_95 from '../tri_Baz_acheter/Arme_m/Marmure80_95';
import M0h_30h from '../tri_Baz_acheter/Arme_m/M0h_30h';
import M30h_60h from '../tri_Baz_acheter/Arme_m/M30h_60h';
import M60_80h from '../tri_Baz_acheter/Arme_m/M60h_80h';

import Am0h_30h from '../tri_Baz_acheter/Arme_Am/Am0h_30h';
import Am30h_60h from '../tri_Baz_acheter/Arme_Am/Am30h_60h';
import Am80_95 from '../tri_Baz_acheter/Arme_Am/Am80_95';
import Am60h_80h from '../tri_Baz_acheter/Arme_Am/Am60h_80h';

import E80_95 from '../tri_Baz_acheter/Arme_e/E80_95';
import E0h_30h from '../tri_Baz_acheter/Arme_e/E0h_30h';
import E30h_60h from '../tri_Baz_acheter/Arme_e/E30h_60h';
import Eh60_80 from '../tri_Baz_acheter/Arme_e/E60h_80h';

import Aarmure80_95 from '../tri_Baz_acheter/Armure_a/Aarmure80_95';
import Aarmure0h_30h from '../tri_Baz_acheter/Armure_a/Aarmure0h_30h';
import Aarmure30h_60h from '../tri_Baz_acheter/Armure_a/Aarmure30h_60h';
import Aarmure60h_80h from '../tri_Baz_acheter/Armure_a/Aarmure60h_80h';

import Marmure80_95 from '../tri_Baz_acheter/Armure_m/Marmure80_95';
import Marmure0h_30h from '../tri_Baz_acheter/Armure_m/Marmure0h_30h';
import Marmure30h_60h from '../tri_Baz_acheter/Armure_m/Marmure30h_60h';
import Marmure60_80h from '../tri_Baz_acheter/Armure_m/Marmure60h_80h';

import AMarmure0h_30h from '../tri_Baz_acheter/Armure_Am/AMarmure0h_30h';
import AMarmure30h_60h from '../tri_Baz_acheter/Armure_Am/AMarmure30h_60h';
import AMarmure80_95 from '../tri_Baz_acheter/Armure_Am/AMarmure80_95';
import AMarmure60h_80h from '../tri_Baz_acheter/Armure_Am/AMarmure60h_80h';

import Earmure80_95 from '../tri_Baz_acheter/Armure_e/Earmure80_95';
import Earmure0h_30h from '../tri_Baz_acheter/Armure_e/Earmure0h_30h';
import Earmure30h_60h from '../tri_Baz_acheter/Armure_e/Earmure30h_60h';
import Earmureh60_80 from '../tri_Baz_acheter/Armure_e/Earmure60h_80h';

import Anneau80_95 from '../tri_Baz_acheter/Accesoire/Anneau/Anneau80_95';
import Anneau0_30h from '../tri_Baz_acheter/Accesoire/Anneau/Anneau0_30h';
import Anneau30h_60h from '../tri_Baz_acheter/Accesoire/Anneau/Anneau30h_60h';
import Anneau60h_80h from '../tri_Baz_acheter/Accesoire/Anneau/Anneau60h_80h';

import Collier80_95 from '../tri_Baz_acheter/Accesoire/Collier/Collier80_95';
import Collier0_30h from '../tri_Baz_acheter/Accesoire/Collier/Collier0_30h';
import Collier30h_60h from '../tri_Baz_acheter/Accesoire/Collier/Collier30h_60h';
import Collier60h_80h from '../tri_Baz_acheter/Accesoire/Collier/Collier60h_80h';

import Bracelet80_95 from '../tri_Baz_acheter/Accesoire/Bracelet/Bracelet80_95';
import Bracelet0_30h from '../tri_Baz_acheter/Accesoire/Bracelet/Bracelet0_30h';
import Bracelet30h_60h from '../tri_Baz_acheter/Accesoire/Bracelet/Bracelet30h_60h';
import Bracelet60h_80h from '../tri_Baz_acheter/Accesoire/Bracelet/Bracelet60h_80h';

import Chapeau80_95 from '../tri_Baz_acheter/Accesoire/Cheapeau/Chapeau80_95';
import Chapeau0_30h from '../tri_Baz_acheter/Accesoire/Cheapeau/Chapeau0_30h';
import Chapeau30h_60h from '../tri_Baz_acheter/Accesoire/Cheapeau/Chapeau30h_60h';
import Chapeau60h_80h from '../tri_Baz_acheter/Accesoire/Cheapeau/Chapeau60h_80h';


import Grant80_95 from '../tri_Baz_acheter/res/gant/Grant80_95';
import Granto0h_30h from '../tri_Baz_acheter/res/gant/Granto0h_30h';
import Grant30h_60h from '../tri_Baz_acheter/res/gant/Grant30h_60h';
import Grant60h_80h from '../tri_Baz_acheter/res/gant/Grant60h_80h';

import Shoes80_95 from '../tri_Baz_acheter/res/shoes/Shoes80_95';
import Shoes0h_30h from '../tri_Baz_acheter/res/shoes/Shoes0h_30h';
import Shoes30h_60h from '../tri_Baz_acheter/res/shoes/Shoes30h_60h';
import Shoes60h_80h from '../tri_Baz_acheter/res/shoes/Shoes60h_80h';

import Costume from '../tri_Baz_acheter/Accesoire/Costume/Costume';

import Ailes from '../tri_Baz_acheter/Accesoire/Costume_ail/Ailes_Cost';

import Fairy from '../tri_Baz_acheter/Accesoire/Fée/Fairy';
import Titre from '../tri_Baz_acheter/Accesoire/Titre/titre';

import Cost_arm from '../tri_Baz_acheter/Accesoire/Costume_arm/Cost_arm';

import ChapeauCost from '../tri_Baz_acheter/Accesoire/Cheapeau_Cost/ChapeauCost';

import Tableaubazar from './Tableaubazar';
import AnnonceBazar from './AnnonceBazar';

import Ailes_sp from '../tri_Baz_acheter/Divers/Ailes';
import Box_raid from '../tri_Baz_acheter/Divers/BoxRaid';

import Diver from '../tri_Baz_acheter/Divers/Divers';

import item_nosmal from '../tri_Baz_acheter/Divers/item_nosmall';

import mat_up from '../tri_Baz_acheter/Divers/matériaux_up';

import mat_amelio from '../tri_Baz_acheter/Divers/matériaux_amelio';

import parcho from '../tri_Baz_acheter/Divers/parchemin';

import Familier from '../tri_Baz_acheter/Famillier/Familier'
import imgload from "../photo_anex/load.gif" ;
import Part_cac from '../tri_Baz_acheter/Partennaire/Cac'
import Part_magie from '../tri_Baz_acheter/Partennaire/magie'
import Part_distance from '../tri_Baz_acheter/Partennaire/Distance'
import{ Cz,
  De,
  Us,
  Es,
  Fr,
  Hk,
  It,
  Pl,
  Tr } from 'react-flags-select';
  import { useTranslation } from 'react-i18next';
import { use } from 'i18next';
const CountriePlayer = ({ country }) => {
  switch (country) {
    case "CZ":
      return <Cz />;
    case "DE":
      return <De />;
    case "US":
      return <Us />;
    case "ES":
      return <Es />;
    case "FR":
      return <Fr />;
    case "HK":
      return <Hk />;
    case "IT":
      return <It />;
    case "PL":
      return <Pl />;
    case "TR":
      return <Tr />;
    default:
      return <div>Country not supported</div>;
  }
};


const Bazar_online_page = () => {
  const { textHidden, toggleText } = useTextHidden();
  const { t } = useTranslation();
  const [gif_user_url, setGif_user_url] = useState('');
  const [serveur,setServeur]= useState('');
  const mode = 'vente/';
  const [loading, setLoading] = useState(true);
  const [whatIBuy,setWhatIBuy] = useState("");
  const { currentUser } = useContext(AuthContext);
    const [selectedOption, setSelectedOption] = useState(null);
    const [otherFieldsDisabled, setOtherFieldsDisabled] = useState(true);
    const [country,setCountry]=useState('');
    const [classeDisabled, setClasseDisabled] = useState(true);
    const [lvlDisabled, setLvlDisabled] = useState(true);
    const [classSpDisabled, setClassSpDisabled] = useState(true);
    const [upSPDisabled, setUpSPDisabled] = useState(true);
    const [amelioSPDisabled, setAmelioSPDisabled] = useState(true);
    const [resDisabled, setResDisabled] = useState(true);
    const [rarityDisabled, setRarityDisabled] = useState(true);
    const [uparmDisabled, setUparmDisabled] = useState(true);
    const [diversDisabled, setDiverdisabled] = useState(true);
    const [accesoireDisabled, setAccesoireDisabled] = useState(true);
    const[whatsell,setWhatsell]=useState();
    const [otheruid, setotheruid] = useState();
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [selectedSp, setSelectedSp] = useState(null);
    const navStyle = {
      width: textHidden ? '90%' : '85%', // Adjust '100px' to your desired width
      marginLeft: textHidden ? '120px' : '235px',
      transition: 'margin-left 2s',
    };
    const navStyle2 = { // Adjust '100px' to your desired width
      marginLeft: textHidden ? '0px' : '140px',
      transition: 'margin-left 2s',
    };    
    const navStyle3 = { // Adjust '100px' to your desired width
      left: textHidden ? '75px' : '250px',
      transition: 'left 2s',
    };
    const navStyle4 = { // Adjust '100px' to your desired width
      left: textHidden ? '45.6%' : '49%',
      transition: 'left 2s',
    };
    const handleSelectChange = (selectedOption) => {
      setSelectedOption(selectedOption);
      setServeur(selectedOption.value);
      if ([ 'Valehir', 'Alzanor', 'Dragonveil', 'Cosmos','Jotunheim']) {
        setOtherFieldsDisabled(false);
      } else {
        setOtherFieldsDisabled(true);
      }
    }
    const [selectedCat2, setSelectedCat2] = useState(null);
    const [selectedCat, setSelectedCat] = useState(null);
    const handleSelectChange3 = (selectedOption) => {
      setSelectedCat2(selectedOption);
        if (selectedOption.value === 'Anneau' ) {
            setClasseDisabled(true);
            setLvlDisabled(false);
            setUpSPDisabled(true);
            setClassSpDisabled(true);
            setRarityDisabled(true);
            setAmelioSPDisabled(true);
            setResDisabled(true);
            setUparmDisabled(true);
            setAccesoireDisabled(false);
        } else if (selectedOption.value === 'Bracelet' ) {
          setClasseDisabled(true);
          setLvlDisabled(false);
          setUpSPDisabled(true);
          setClassSpDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false);
        } else if (selectedOption.value === 'Chapeau' ) {
          setClasseDisabled(true);
          setLvlDisabled(false);
          setUpSPDisabled(true);
          setClassSpDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false);
        } else if (selectedOption.value === 'Chapeau_cost' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false);
        } else if (selectedOption.value === 'Collier' ) {
          setClasseDisabled(true);
          setLvlDisabled(false);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false);
        } else if (selectedOption.value === 'Costumme' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false);
        } else if (selectedOption.value === 'Costume_arm' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false);
        } else if (selectedOption.value === 'Costume_aile' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false); 
        }else if (selectedOption.value === 'Fairy' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false); 
        }else if (selectedOption.value === 'Titre' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false); 
        }else if (selectedOption.value === 'aile_sp' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false); 
        }else{
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false); 
        }
      }


      const handleSelectChange2 = (selectedOption) => {
        setSelectedLvl('')
        setSelectedUpSp('')
        setSelectedAmelioSp('')
        setSelectedRarity('')
        setSelectedUpArm('')
        setSelectedCat(selectedOption);
        setSelectedOption(selectedOption);
        if (selectedOption.value === 'Arme' ) {
            setClasseDisabled(false);
            setLvlDisabled(false);
            setUpSPDisabled(true);
            setClassSpDisabled(true);
            setRarityDisabled(false);
            setDiverdisabled(true);
            setAmelioSPDisabled(true);
            setResDisabled(true);
            setUparmDisabled(false);
            setAccesoireDisabled(true);
        } else if (selectedOption.value === 'Armure' ) {
          setClasseDisabled(false);
          setLvlDisabled(false);
          setUpSPDisabled(true);
          setClassSpDisabled(true);
          setRarityDisabled(false);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(false);
          setDiverdisabled(true);
          setAccesoireDisabled(true);
        } else if (selectedOption.value === 'Accesoire' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setUpSPDisabled(true);
          setClassSpDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setDiverdisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false);
        } else if (selectedOption.value === 'Spécialiste' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(false);
          setUpSPDisabled(false);
          setRarityDisabled(true);
          setAmelioSPDisabled(false);
          setResDisabled(true);
          setDiverdisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(true);
        } else if (selectedOption.value === 'Familier' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setDiverdisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(true);
        } else if (selectedOption.value === 'Partenaire' ) {
          setClasseDisabled(false);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setDiverdisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(true);
        } else if (selectedOption.value === 'Diver' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setDiverdisabled(false);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(true);
        } else if (selectedOption.value === 'Res' ) {
          setClasseDisabled(true);
          setLvlDisabled(false);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(false);
          setDiverdisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(true); 
        }  else{ 
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setDiverdisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(true);
        }
      }
        const handleCloseModal = (index) => {
          setModalOpen((prev) => {
            const newState = [...prev];
            newState[index] = false;
            return newState;
          });
        };


    const Serveur = [
        { value: 'Valehir', label: 'Valehir' },
        { value: 'Alzanor', label: 'Alzanor' },
        { value: 'Dragonveil', label: 'Dragonveil' },
        { value: 'Cosmos', label: 'Cosmos' },
        { value: 'Jotunheim', label: 'Jotunheim' }
      ];


      const Categorie = [
        { value: 'Arme', label: t('Arme') },
        { value: 'Armure', label: t('Armure') },
        { value: 'Accesoire', label: t('Accesoire') },
        { value: 'Spécialiste', label: t('Spécialiste') },
        { value: 'Familier', label: t('Familier') },
        { value: 'Partenaire', label: t('Partenaire') },
        { value: 'Res', label: t('Res') },
        { value: 'Diver', label: t('Diver') }
      ];

      const Classe = [
        { value: 'Archer', label: t('Archer') },
        { value: 'Escri', label: t('Escri') },
        { value: 'Mage', label: t('Mage') },
        { value: 'Artiste-Martial', label: t('Artiste-Martial') }
      ];

      const Lvl = [
        { value: '80_95', label: '80-95' },
        { value: '0h_30h', label: '0h-30h' },
        { value: '31h_60h', label: '31h-60h' },
        { value: '61h_80h', label: '61h-90h' }
      ];


      const Classe_sp = [
        { value: 'Archer', label: t('Archer') },
        { value: 'Escri', label: t('Escri') },
        { value: 'Mage', label: t('Mage') },
        { value: 'Artiste-Martial', label:t('Artiste-Martial') },
        { value: 'Autre', label: t('Other') }
      ];
      const Up_sp = [
        { value: '0', label: '0' },
        { value: '1', label: '+1' },
        { value: '2', label: '+2' },
        { value: '3', label: '+3' },
        { value: '4', label: '+4' },
        { value: '5', label: '+5' },
        { value: '6', label: '+6' },
        { value: '7', label: '+7' },
        { value: '8', label: '+8' },
        { value: '9', label: '+9' },
        { value: '10', label: '+10' },
        { value: '11', label: '+11' },
        { value: '12', label: '+12' },
        { value: '13', label: '+13' },
        { value: '14', label: '+14' },
        { value: '15', label: '+15' },
        { value: '16', label: '+16' },
        { value: '17', label: '+17' },
        { value: '18', label: '+18' },
        { value: '19', label: '+19' },
        { value: '20', label: '+20' }
      ];

      const Amelio_sp = [
        { value: ['0','1','2','3','4','5','6','7','8','9','10'], label: '0🔛10' },
        { value: ['11','12','13','14','15','16','17','18','19','20'], label: '11🔛20' },
        { value: ['21','22','23','24','25','26','27','28','29','30'], label: '21🔛30' },
        { value: ['31','32','33','34','35','36','37','38','39','40'], label: '31🔛40' },
        { value: ['41','42','43','44','45','46','47','48','49','50'], label: '41🔛50' },
        { value: ['51','52','53','54','55','56','57','58','59','60'], label: '51🔛60' },
        { value: ['61','62','63','64','65','66','67','68','69','70'], label: '61🔛70' },
        { value: ['71','72','73','74','75','76','78','79','80'], label: '71🔛80' },
        { value: ['81','82','83','84','85','86','87','88','89','90'], label: '81🔛90' },
        { value: ['91','92','93','94','95','96','97','98','99','100'], label: '91🔛100' }
      ];

      const res = [
        { value: 'gants', label: t('gants') },
        { value: 'chaussure', label: t('chaussure') }
      ];

      const rarity = [
        { value: '-2', label: 'r-2' },
        { value: '-1', label: 'r-1' },
        { value: '0x', label: 'r0' },
        { value: '1', label: 'r1' },
        { value: '2', label: 'r2' },
        { value: '3', label: 'r3' },
        { value: '4', label: 'r4' },
        { value: '5', label: 'r5' },
        { value: '6', label: 'r6' },
        { value: '7', label: 'r7' },
        { value: '8', label: 'r8' }
      ]

      const up_arm = [
        { value: '0', label: '+0' },
        { value: '1', label: '+1' },
        { value: '2', label: '+2' },
        { value: '3', label: '+3' },
        { value: '4', label: '+4' },
        { value: '5', label: '+5' },
        { value: '6', label: '+6' },
        { value: '7', label: '+7' },
        { value: '8', label: '+8' },
        { value: '9', label: '+9' },
        { value: '10', label: '+10' }
        
      ]

      
      const Accesoire = [
        { value: 'Anneau', label: t('Anneau')},
        { value: 'Bracelet', label: t('Bracelet') },
        { value: 'Chapeau', label: t('Chapeau')},
        { value: 'Chapeau_cost', label: t('Chapeau_cost') },
        { value: 'Collier', label: t('Collier') },
        { value: 'Costumme', label: t('Costumme') },
        { value: 'Costume_arm', label: t('Costume_arme') },
        { value: 'Costume_aile', label: t('Costume_Aile') },
        { value: 'aile_sp', label: t('Aile SP') },
        { value: 'Fairy', label: t('Fée') },
        { value: 'Titre', label: t('Titre') }
        
      ]

      const Divers = [
        { value: 'AilesVente', label: t('Ailes') },
        { value: 'BoxRaid', label: t('Box Raid') },
        { value: 'Divers', label:  t('Divers')},
        { value: 'Item_nosmall', label: t('item_Nosmal') },
        { value: 'Matériaux_amelio', label: t('Matériaux_Amélio') },
        { value: 'Matériaux_up', label: t('Matériaux_Up') },
        { value: 'Parchemin', label: t('Parchemin') }
        
      ]
      const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      };
      const [dislikeprofil, setdisLikeProfil] = useState(0);
      const [likeprofil, setLikeProfil] = useState(0);
      const [selectedDivers, setSelectedDivers] = useState(null);
      const [selectedClasse, setSelectedClasse] = useState(null);
      const [selectedLvl, setSelectedLvl] = useState(null);
      const [selectedClassSp, setSelectedClassSp] = useState(null);
      const [selectedUpSp, setSelectedUpSp] = useState('');
      const [selectedAmelioSp, setSelectedAmelioSp] = useState([]); 
      const [selectedRes, setSelectedRes] = useState(null);
      const [selectedRarity, setSelectedRarity] = useState('');
      const [selectedUpArm, setSelectedUpArm] = useState('');
      const [selectedAcces, setSelectedAcces] = useState(null);
      const [data, setData] = useState([]);
      const [pictureBazzar,setPictureBazzar]= useState('')
      const [modalOpen, setModalOpen] = useState(Array(data.length).fill(false));
      const [pictureSpUserUrl, setPicture_sp_user_url] = useState('');
      const [currentPage, setCurrentPage] = useState(1);
      const [documentsPerPage] = useState(10);
        const bazarCollectionRef = collection(db2, 'vente');
      
                  // Fonction pour calculer l'index du dernier document de chaque page
        const indexOfLastDocument = currentPage * documentsPerPage;
        // Fonction pour calculer l'index du premier document de chaque page
        const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
        // Fonction pour récupérer les documents de la page courante
        const currentDocuments = data.slice(indexOfFirstDocument, indexOfLastDocument);
        // Fonction pour changer de page
      
        const paginate = (pageNumber) => {
          if (pageNumber <= Math.ceil(data.length / documentsPerPage)) {
            setCurrentPage(pageNumber);
          }
        };
const handleOpenModal = async (index) => {
  setSelectedDoc(data[index]);
  setModalOpen((prev) => {
    const newState = [...prev];
    newState[index] = true;
    return newState;
  });

  // Call fetchUserCountry here
  await fetchUserCountry(data[index].senderUid);
};

      function formatDate(timeStamp) {
        const date = new Date(timeStamp.seconds * 1000);
        
       
      const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
      }

      const customStyles = {
        container: (provided) => ({
          ...provided,
          width: '150px',
          fontSize: '12px',
          position: 'relative',
          cursor: 'pointer',
          display: 'inline-block' // add this to align with the original .list-choice
        }),
        control: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          color: '#FFF',
          textShadow: '0 1px 0 rgba(144, 142, 142, 0.66)',
          borderRadius: '0.2em',
          border: 'none',
          cursor: 'pointer',
          display: 'flex', // center selected value
          justifyContent: 'center', // center selected value
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          borderRadius: '0.2em',
          overflow: 'hidden'
        }),
        option: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          color: '#FFF',
          cursor: 'pointer'
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#FFF',
          background: 'rgb(116,214,142)',
          background: 'linear-gradient(102deg, rgba(116,214,142,1) 0%, rgba(116,214,142,0.04525560224089631) 59%)',
          height: '39px',
          borderRadius: '2.4px 6px 6px 2.4px',
          marginLeft:'-8px',
          width: '113px', // fill width of control
          display: 'flex',
          alignItems: 'center',
          marginTop: '-1.8px',
          marginBottom: '-2px',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#FFF'
        })
      };

      const [modalOpenmp, setModalOpenmp] = useState(Array(data.length).fill(false));

      const handleOpenModalmp = (index) => {
        setModalOpenmp(prevModalOpenmp => {
          const newModalOpenmp = [...prevModalOpenmp];
          newModalOpenmp[index] = true;
          return newModalOpenmp;
        });
      }
      
      const handleCloseModalmp = (index) => {
        setModalOpenmp(prevModalOpenmp => {
          const newModalOpenmp = [...prevModalOpenmp];
          newModalOpenmp[index] = false;
          return newModalOpenmp;
        });
      }


      const handleSpSelection = (sp) => {
        setSelectedSp(sp);
      };  
      const [message, setMessage] = useState('');

      const handleMessageChange = (event) => {
        setMessage(event.target.value);
      };


      const[discordPlayer,SetDiscordPlayer] = useState('')
      const [discord, setDiscord] = useState('');
      const [comment, setComment] = useState('');
      const hourglassElement = document.querySelector('.loader');

      async function handleParentClick(id, image) {
        try {

          hourglassElement.style.display = 'inline-block';
          let queryRef = collection(db2, "vente");

          // Add the common where clauses
          queryRef = query(queryRef, where("itemo", "==", id), where("serveur", "==", serveur));
  
          // Add the rarity where clause if selectedRarity is not empty
          if (selectedRarity) {
              queryRef = query(queryRef, where("rarity", "==", selectedRarity.value));
          }
          if (selectedUpArm) {
            queryRef = query(queryRef, where("up", "==", selectedUpArm.value));
          }
          if (selectedUpSp) {
            queryRef = query(queryRef, where("up", "==", selectedUpSp.value));
          }
          if(selectedAmelioSp) {
            queryRef = query(queryRef, where("amelio", "in" , selectedAmelioSp.value));
          }
          const querySnapshot = await getDocs(queryRef);
  

          const list = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              const url = await getDownloadURL(ref(storage, doc.data().image_url));
              const timeStamp = doc.data().timeStamp;
              const formattedDate = format(new Date(timeStamp.seconds * 1000), 'dd-MM-yyyy');
        
              // retrieve the senderUid value from the current document
              const senderUid = doc.data().senderUid;
        
              // Assuming there's a function called 'fetchUserCountry' defined somewhere
        
              return { id: doc.id, ...doc.data(), imageUrl: url, date: formattedDate };
            })     
          );
          list.sort((a, b) => a.prix - b.prix);
          setSelectedSp(image);
          setData(list);
          hourglassElement.style.display = 'none';
        } catch (err) {
          console.error(err);
          hourglassElement.style.display = 'none';
        }
      }
      const fetchUserCountry = async (senderUid) => {
        try {
          const uid = currentUser.uid;
      
          const docRef = doc(db2, 'users', senderUid);
          const docSnap = await getDoc(docRef);
      
          const docRef2 = doc(db2, 'score', senderUid);
          const docSnap2 = await getDoc(docRef2);
      
          if (docSnap.exists()) {
            const data = docSnap.data();
            const userDiscord = data.Discord || '';
            const userCountry = data.country || '';
            const userComment = data.comment || '';
            const userPicture = data.pdp_src || '';
            const userGif = data.gif_src || '';
      
            setotheruid(senderUid);
            setCountry(userCountry);
            setDiscord(userDiscord);
            setComment(userComment);
      
            const data2 = docSnap2.data();
            const likeCount = (data2.like && data2.like.length) || 0;
            const dislikeCount = (data2.dislike && data2.dislike.length) || 0;
      
            setLikeProfil(likeCount);
            setdisLikeProfil(dislikeCount);
      
            const picture_sp_user = await getDownloadURL(ref(storage, userPicture));
            setPicture_sp_user_url(picture_sp_user);
      
            const gif_user_url = await getDownloadURL(ref(storage, userGif));
            setGif_user_url(gif_user_url);
          }
        } catch (error) {
          console.error('Error fetching user country:', error);
        } finally {
          setLoading(false);
        }
      };

      const handleSendMessage = (index, docs) => {
        hourglassElement.style.display = 'inline-block';
        const message = document.getElementById(`mp_msg-${index}`).value;
        const recipientId = docs[index]?.Pseudonim; // use optional chaining to avoid accessing properties of undefined
        const recipientUid = docs[index]?.senderUid;
        const recipientRef = doc(collection(db2, "users"), recipientId);
        getDoc(recipientRef).then((recipientDoc) => {
          addDoc(collection(db2, "messages"), {
            senderUid: currentUser.uid,
            recipient: recipientId,
            recipientUid: recipientUid, // copy recipient's RecipientId field
            message: message,
            timeStamp: serverTimestamp(),
          })
          
          .then(() => {
            // ... handle success
          })
          .catch((error) => {
            // ... handle error
          });
        });
        toast.success (t('Msg Send ✉️'), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
          hourglassElement.style.display = 'none';
        handleCloseModalmp(index);
      };
      const [overlayVisible, setOverlayVisible] = useState(false);

      function handleBtnShowClick() {
        setOverlayVisible(true);
      }
      function handleBtnMasqClick() {
        setOverlayVisible(false);
      }


      useEffect(() => {
        // Appeler deleteExpiredDocuments toutes les 24 heures
        const interval = setInterval(deleteExpiredDocuments, 24 * 60 * 60 * 1000);
    
        return () => {
          // Nettoyer l'intervalle lors de la suppression du composant
          clearInterval(interval);
        };
      }, []);
    
      const deleteExpiredDocuments = async () => {
        // Récupérer les documents expirés
        const expirationDate = new Date(Date.now() - 3 * 30 * 24 * 60 * 60 * 1000); // 3 mois
        const q = query(collection(db2, 'vente'), where('timeStamp', '<', expirationDate));
        const querySnapshot = await getDocs(q);
    
        // Supprimer les documents expirés
        querySnapshot.forEach((doc) => {
          deleteDoc(doc.ref)
            .then(() => {
              toast.warn (t('ann_sup'), {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                })
            })
            .catch((error) => {
              toast.warn ('Error removing document: ', error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                })
            });
        });
      }

      async function handleLike(index, docs) {
        const recipientUid = docs[index]?.senderUid;
    
        if (!recipientUid) {
            console.error("L'UID du destinataire est manquant.");
            return;
        }
        if (recipientUid === currentUser.uid) {
          toast.warn ('Vous ne pouvez pas votez pour vous même ', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
          return}
        const docRef = doc(db2, 'score', recipientUid);
        const docSnapshot = await getDoc(docRef);
        const uid = currentUser.uid;
    
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
  
          // Vérifier si current.uid est dans dislike
          if (!userData.dislike || userData.dislike.indexOf(uid) === -1) {
              // Vérifier si current.uid est déjà dans like
              if (!userData.like || userData.like.indexOf(uid) === -1) {
                  // Ajouter current.uid à like s'il n'est pas déjà présent
                  const updatedLikeprofil = [...(userData.like || []), uid];
  
                  // Mettre à jour le document avec la nouvelle liste like
                  await updateDoc(docRef, { like: updatedLikeprofil });
  
                  // Incrémenter la valeur affichée
                  const likeCount = updatedLikeprofil.length;
                  const likeTextContent = document.querySelector('.like-text-content');
                  if (likeTextContent) {
                      likeTextContent.innerText = likeCount;
                  } else {
                      console.error("L'élément .like-text-content est manquant.");
                  }
              } else {
                toast.warn ('L utilisateur like déjà cette personne ', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                  })
              }
          } else {
            toast.warn ('Vous Dislike cette personne', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              });
          }
      } else {
          console.error("Le document n'existe pas pour l'UID du destinataire : ", recipientUid);
      }
    }

      async function handledisLike(index, docs) {
        const recipientUid = docs[index]?.senderUid;
    
        if (!recipientUid) {
            console.error("L'UID du destinataire est manquant.");
            return;
        }
        if (recipientUid === currentUser.uid) {
          toast.warn ('Vous ne pouvez pas votez pour vous même ', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
          return;
      }
        const docRef = doc(db2, 'score', recipientUid);
        const docSnapshot = await getDoc(docRef);
        const uid = currentUser.uid;
    
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
  
          // Vérifier si current.uid est dans like
          if (!userData.like || userData.like.indexOf(uid) === -1) {
              // Vérifier si current.uid est déjà dans dislike
              if (!userData.dislike || userData.dislike.indexOf(uid) === -1) {
                  // Ajouter current.uid à dislike s'il n'est pas déjà présent
                  const updatedDislikeprofil = [...(userData.dislike || []), uid];
  
                  // Mettre à jour le document avec la nouvelle liste dislike
                  await updateDoc(docRef, { dislike: updatedDislikeprofil });
  
                  // Incrémenter la valeur affichée
                  const dislikeCount = updatedDislikeprofil.length;
                  const dislikeTextContent = document.querySelector('.dislike-text-content');
                  if (dislikeTextContent) {
                      dislikeTextContent.innerText = dislikeCount;
                  } else {
                      console.error("L'élément .dislike-text-content est manquant.");
                  }
              } else {
                toast.warn ('Vous Dislike déjà cette personne', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
                  });
              }
          } else {
            toast.warn ('L utilisateur like cette personne ', {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              })
          }
      } else {
          console.error("Le document n'existe pas pour l'UID du destinataire : ", recipientUid);
      }
    }

    return (  
        <div className='Bazar'>
          <div className='Bazar'> 
            <h1 style={navStyle4}>{t('buy_nav')}</h1>
            <form className='info_jeux' style={navStyle2}>

                    <Select styles={customStyles} options={Serveur} placeholder={t('Serveur')} onChange={handleSelectChange} />
                    {!otherFieldsDisabled && <Select styles={customStyles} options={Categorie} placeholder={t('Catego')} disabled={otherFieldsDisabled} onChange={handleSelectChange2} />}  
                    {!classeDisabled && <Select styles={customStyles} options={Classe} placeholder={t('Classe')} disabled={classeDisabled} onChange={(selectedOption) => setSelectedClasse(selectedOption)}/>}
                    {!resDisabled && <Select styles={customStyles} options={res} placeholder={t('Resi')} disabled={resDisabled} onChange={(selectedOption) => setSelectedRes(selectedOption)}/>}
                    {!lvlDisabled && <Select styles={customStyles} options={Lvl} placeholder={t('LVL')} disabled={lvlDisabled} onChange={(selectedOption) => setSelectedLvl(selectedOption)}/>}
                    {!classSpDisabled && <Select styles={customStyles} options={Classe_sp} placeholder={t('Classe')} disabled={classSpDisabled} onChange={(selectedOption) => setSelectedClassSp(selectedOption)}/>}
                    {!accesoireDisabled && <Select styles={customStyles} options={Accesoire} placeholder={t('Accesoire')} disabled={accesoireDisabled} onChange={handleSelectChange3}/>}
                    {!upSPDisabled && <Select styles={customStyles} options={Up_sp} placeholder="Up SP" disabled={upSPDisabled} onChange={(selectedOption) => setSelectedUpSp(selectedOption)}/>}
                    {!amelioSPDisabled && <Select styles={customStyles} options={Amelio_sp} placeholder={t('point_amélio')} disabled={amelioSPDisabled} onChange={(selectedOption) => setSelectedAmelioSp(selectedOption)}/>}
                    {!rarityDisabled && <Select styles={customStyles} options={rarity} placeholder={t('Rare')} disabled={rarityDisabled} onChange={(selectedOption) => setSelectedRarity(selectedOption)}/>}
                    {!uparmDisabled && <Select styles={customStyles} options={up_arm} placeholder={t('up')} disabled={uparmDisabled} onChange={(selectedOption) => setSelectedUpArm(selectedOption)}/>}
                    {!diversDisabled && <Select styles={customStyles} options={Divers} placeholder="Divers" disabled={diversDisabled} onChange={(selectedOption) => setSelectedDivers(selectedOption)}/>}


                    {selectedCat && selectedCat.value === 'Partenaire' && selectedClasse && selectedClasse.value === 'Escri' && 
                    <Part_cac  handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3} />}
                    {selectedCat && selectedCat.value === 'Partenaire' && selectedClasse && selectedClasse.value === 'Mage' && 
                    <Part_magie   handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Partenaire' && selectedClasse && selectedClasse.value === 'Archer' && 
                    <Part_distance  handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}


                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '80_95' && 
                      <E80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <E0h_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <E30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <Eh60_80 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    
                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '80_95' && 
                      <A80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <A0h_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Diver' && selectedDivers && selectedDivers.value === 'AilesVente' && <AilesVente handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Diver' && selectedDivers && selectedDivers.value === 'BoxRaid' && <BoxRaid handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Diver' && selectedDivers && selectedDivers.value === 'Divers' && <Diversuu handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Diver' && selectedDivers && selectedDivers.value === 'Item_nosmall' && <Item_nosmall handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Diver' && selectedDivers && selectedDivers.value === 'Matériaux_amelio' && <Matériaux_amelio handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Diver' && selectedDivers && selectedDivers.value === 'Matériaux_up' && <Matériaux_up handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Diver' && selectedDivers && selectedDivers.value === 'Parchemin' && <Parchemin handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <A30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} mode={mode}  navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <A60h_80h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '80_95' && 
                      <M80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <M0h_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <M30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <M60_80h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '80_95' && 
                      <Am80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <Am0h_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <Am30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <Am60h_80h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Spécialiste' && selectedClassSp && selectedClassSp.value === 'Archer' &&
                      <SpArcher handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Spécialiste' && selectedClassSp && selectedClassSp.value === 'Escri' &&
                      <SpEscri handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Spécialiste' && selectedClassSp && selectedClassSp.value === 'Mage' &&
                      <SpMage handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Spécialiste' && selectedClassSp && selectedClassSp.value === 'Artiste-Martial' && 
                      <SpAM handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Spécialiste' && selectedClassSp && selectedClassSp.value === 'Autre' && 
                      <SpAutre handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '80_95' && 
                      <Earmure80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <Earmure0h_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <Earmure30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <Earmureh60_80 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    
                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '80_95' && 
                      <Aarmure80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <Aarmure0h_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <Aarmure30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <Aarmure60h_80h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '80_95' && 
                      <Marmure80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <Marmure0h_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <Marmure30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <Marmure60_80h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '80_95' && 
                      <AMarmure80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <AMarmure0h_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <AMarmure30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <AMarmure60h_80h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                  {selectedCat && selectedCat.value === 'Familier' && 
                      <Familier handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Anneau' && selectedLvl && selectedLvl && selectedLvl.value === '80_95' && 
                      <Anneau80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Anneau' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <Anneau0_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Anneau' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <Anneau30h_60h/>}

                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Anneau' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <Anneau60h_80h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Collier' && selectedLvl && selectedLvl.value === '80_95' && 
                      <Collier80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Collier' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <Collier0_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Collier' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <Collier30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Collier' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <Collier60h_80h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}


                  {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Bracelet' && selectedLvl && selectedLvl.value === '80_95' && 
                      <Bracelet80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Bracelet' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <Bracelet0_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Bracelet' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <Bracelet30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Bracelet' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <Bracelet60h_80h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Chapeau' && selectedLvl && selectedLvl.value === '80_95' && 
                      <Chapeau80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Chapeau' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <Chapeau0_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Chapeau' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <Chapeau30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Chapeau' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <Chapeau60h_80h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}


                    {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'gants' && selectedLvl && selectedLvl.value === '80_95' && 
                      <Grant80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection}  navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'gants' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <Granto0h_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'gants' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <Grant30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'gants' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <Grant60h_80h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'chaussure' && selectedLvl && selectedLvl.value === '80_95' && 
                      <Shoes80_95 handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3} />}

                    {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'chaussure' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <Shoes0h_30h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'chaussure' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <Shoes30h_60h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                    {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'chaussure' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <Shoes60h_80h handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}

                  {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Costumme' && 
                      <Costume handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                  
                  {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Costume_aile' && 
                      <Ailes handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                 {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Fairy' && 
                      <Fairy handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                  {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Titre' && 
                      <Titre handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                   {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Costume_arm' && 
                      <Cost_arm handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                                  
                   {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Chapeau_cost' && 
                      <ChapeauCost handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                    {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'aile_sp' && 
                      <Ailes_sp handleSpClick={handleParentClick}  handleSpSelection={handleSpSelection} navStyle3={navStyle3}/>}
                </form>


            </div>
                <table className='tableSell' style={navStyle}>
                    <Tableaubazar/>
                    <tbody>
                      <>
                      </>
                      {data.length > 0 ? (
                          currentDocuments.map((doc, index) => (
                        <tr key={doc.id}>
                          <th>
                          {selectedSp && <img src={selectedSp} />}
                          </th>
                        <th>{doc.quantity ?doc.quantity : 1} </th>
                        <th className='priced'><p className='Gold'>{formatNumber(doc.prix * 1000000)} or</p> 
                        <p className='goldkk'>( {doc.prix}kk ) </p>
                    </th>
                        <th>{doc.date}</th>
                        <th>{doc.Pseudonim}</th>
                        <th className='thpmdet'>
                        <button className='SendMsg' onClick={() => handleOpenModalmp(index)}>
                                        <div className="svg-wrapper-1">
                                          <div className="svg-wrapper">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                              <path fill="none" d="M0 0h24v24H0z"></path>
                                              <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                        <span>Send</span>
                                      </button>

                                {modalOpenmp[index] && (  
                                  <div className='modal-overlay'>
                                  <div className='modal_msg'>
                                    <div>
                                      <h5>  PM {doc.Pseudonim}</h5>
                                  <button id="close-crosshair-button"> 
                                    <span className="material-symbols-outlined" onClick={() => handleCloseModalmp(index)}>close</span>
                                  </button> 
                                  
                                  </div>
                                  <label  htmlFor={`mp_msg-${index}`}/>
                                               
                                              
                                              <form onSubmit={(e) => handleSendMessage(index, data)} className='form_mlsg'> {/* Prevent the default form submission */}
                                                  {/* Form inputs */}
                                                  <div className="Message">
                                                    <input title="Write Message" tabindex="i" pattern="\d+" placeholder="Message.." className="MsgInput"                 
                                                    type="text"
                                                    id={`mp_msg-${index}`} 
                                                    value={message} 
                                                    onChange={handleMessageChange}
                                               // Ajouter la gestion de l'événement keydown
                                            />
                                                      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="30.000000pt" height="30.000000pt" viewBox="0 0 30.000000 30.000000" preserveAspectRatio="xMidYMid meet" className="SendSVG">
                                                      <g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)" fill="#ffffff70" stroke="none">
                                                      <path                 type="sumbit" // Change the button type to "button"
                                                    
                                                    onClick={() => handleSendMessage(index, data)} d="M44 256 c-3 -8 -4 -29 -2 -48 3 -31 5 -33 56 -42 28 -5 52 -13 52 -16 0 -3 -24 -11 -52 -16 -52 -9 -53 -9 -56 -48 -2 -21 1 -43 6 -48 10 -10 232 97 232 112 0 7 -211 120 -224 120 -4 0 -9 -6 -12 -14z"></path>
                                                    </g>
                                                  </svg><span className="l"></span>
                                                    
                                                  </div>
                                                
                                                  
                                                </form>
                                    

                                  </div>
                                  </div>
                                )}
                                
                                <button
                                  className="detail"
                                  id="toggle-overlay"
                                  onClick={() => handleOpenModal(index)}
                                >
                                 <span>{t('see_detai')}</span> 
                                </button>
                                {modalOpen[index] && (

                                  <div
                                    className="modal-overlay"
                                  >
                                    <div key={doc.id} className="modal">
                                    <div className='card_users'>
                                      <div className='div_pict'>
                                        <img src={pictureSpUserUrl} className="Picture_profil" alt="Profile"></img>
                                      </div>
                                      
                                        <div className='info_player'>
                                          <p>Pseudo : {doc.Pseudonim} </p> {/* This line uses an undefined variable, replace with actual data */}
                                          <p>Discord : {discord} </p>
                                          <p>Country: <CountriePlayer country={country} className="picture_countr" /></p>
                                          <button className="learn-more" onClick={handleBtnShowClick}>
                                            <span className="circle" aria-hidden="true">
                                            <span className="icon arrow"></span>
                                            </span>
                                            <span className="button-text">Show Page</span>
                                          </button>
                                          
                                          {overlayVisible && (
                                            <div className='modal-overlay'>
                                              <div className='PagePlayer'>  
                                                <div className='topeur'>
                                                <button className='close__pagplayer' onClick={handleBtnMasqClick}> 
                                                  <span className="material-symbols-outlined">close</span>
                                                 </button> 
                                                  <h1>{doc.Pseudonim}</h1>
                                                  <div className='Info_playeer'>
                                                    <img src={pictureSpUserUrl} className="Picture_profil player__src" alt="Profile"></img>
                                                    <CountriePlayer country={country} className="picture_countr" />
                                                    <div className='discrd playyyer'>
                                                    Discord : {discord} 
                                                    </div>

                                                  </div>
                                                  <div className='Commantère'>
                                                  <p className='Com_playeur'>{comment}</p>
                                                  <div className='gif_note'>
                                                  <div >  
                                                    <h2>Scores :</h2>
                                                    <div className='Lik'>
                                                    <div className="like-dislike-container">
                                                          <div className="tool-box">
                                                            <button className="btn-close">×</button>
                                                          </div>
                                                          <p className="text-content">What did you think<br/>of this Guys?</p>				
                                                          <div className="icons-box">
                                                            <div className="icons">
                                                              <label className="btn-label" for="like-checkbox">
                                                                <span className="like-text-content">{likeprofil}</span>
                                                                <input className="input-box"  onClick={() => handleLike(index, data)} id="like-checkbox" type="checkbox"/>
                                                                <svg className="svgs" id="icon-like-solid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"></path></svg>
                                                                <svg className="svgs" id="icon-like-regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.1s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z"></path></svg>
                                                                <div className="fireworks">
                                                                  <div className="checked-like-fx"></div>
                                                                </div>
                                                              </label>
                                                            </div>
                                                            <div className="icons">
                                                              <label className="btn-label" for="dislike-checkbox">
                                                                <input onClick={() => handledisLike(index, data)} className="input-box" id="dislike-checkbox" type="checkbox"/>
                                                                <div className="fireworks">
                                                                  <div className="checked-dislike-fx"></div>
                                                                </div>
                                                                <svg className="svgs" id="icon-dislike-solid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"></path></svg>
                                                                <svg className="svgs" id="icon-dislike-regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.1s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z"></path></svg>
                                                                <span className="dislike-text-content">{dislikeprofil}</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                  <img  className="real_gif_player" src={gif_user_url}></img>
                                                  </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                      <div className='Playerse'>
                                      <button className='SendMsg' onClick={() => handleOpenModalmp(index)}>
                                        <div className="svg-wrapper-1">
                                          <div className="svg-wrapper">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                              <path fill="none" d="M0 0h24v24H0z"></path>
                                              <path fill="currentColor" d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"></path>
                                            </svg>
                                          </div>
                                        </div>
                                        <span>Send</span>
                                      </button>

                                          {modalOpenmp[index] && (  
                              <div className='modal-overlay'>
                              <div className='modal_msg'>
                                <div>
                                  <h5>  PM {doc.Pseudonim}</h5>
                              <button id="close-crosshair-button"> 
                                <span className="material-symbols-outlined" onClick={() => handleCloseModalmp(index)}>close</span>
                              </button> 
                              
                              </div>
                              <label  htmlFor={`mp_msg-${index}`}/>
                                           
                                          
                                          <form onSubmit={(e) => handleSendMessage(index, data)} className='form_mlsg'> {/* Prevent the default form submission */}
                                              {/* Form inputs */}
                                              <div className="Message">
                                                <input title="Write Message" tabindex="i" pattern="\d+" placeholder="Message.." className="MsgInput"                 
                                                type="text"
                                                id={`mp_msg-${index}`} 
                                                value={message} 
                                                onChange={handleMessageChange}
                                           // Ajouter la gestion de l'événement keydown
                                        />
                                                  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="30.000000pt" height="30.000000pt" viewBox="0 0 30.000000 30.000000" preserveAspectRatio="xMidYMid meet" className="SendSVG">
                                                  <g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)" fill="#ffffff70" stroke="none">
                                                  <path                 type="sumbit" // Change the button type to "button"
                                                
                                                onClick={() => handleSendMessage(index, data)} d="M44 256 c-3 -8 -4 -29 -2 -48 3 -31 5 -33 56 -42 28 -5 52 -13 52 -16 0 -3 -24 -11 -52 -16 -52 -9 -53 -9 -56 -48 -2 -21 1 -43 6 -48 10 -10 232 97 232 112 0 7 -211 120 -224 120 -4 0 -9 -6 -12 -14z"></path>
                                                </g>
                                              </svg><span className="l"></span>
                                                
                                              </div>
                                            
                                              
                                            </form>
                                            </div>
                                            </div>
                                          )}
                                          <div className='RealPrice'>
                                            <p>Or : </p><p> {formatNumber(doc.prix * 1000000)}</p>
                                          </div>
                                          </div>
                                    </div>
                                      <div className="picture_seller">
                                        <img src={doc.imageUrl} alt="item" />
                                      </div>
                                      <div className="Caractéristique">
                                        <button id="close-crosshair-button"> 
                                         <span className="material-symbols-outlined" onClick={() => handleCloseModal(index)}>close</span>
                                        </button> 
                                        <div className='caradroit'>
                                        <h1 className="TitleSell">{t('TitleSell')}</h1>
                                        {selectedDoc.runeValues ? (
                                        <ul className='ul_weapons'>
                                          {doc.up ? <div className='caract'>
                                            <p className='upeustre'>UP : +{doc.up}</p>
                                            <p className='raritu'>Rarity : +{doc.rarity}</p>
                                          </div> : <></>
                                          }
                                          {selectedDoc.runeValues.map((rune, index) => (
                                            <li key={index}>
                                              {rune.hasOwnProperty('selectValue') 
                                              ? `${t(`${rune.selectValue}`)}: ${rune.inputValue}`
                                              : `${t(`${rune.field}`)}: ${rune.value}`
                                              }
                                            </li>
                                          ))}
                                        </ul>
                                        ) : (
                                          
                                        <li className="list-carac">
                                          {doc.Attaque === 0 || doc.Attaque ?<><ul>
                                            <div className='caract'>
                                            <p className='upeustre'>Up : +{doc.up}</p>
                                            <p className='upeustre'>Amélio : +{doc.amelio}</p>
                                          </div>
                                            {t('Attaque')}  <img src={imageatq} className='img_sp'/> : <a>{doc.Attaque}</a>
                                          </ul>
                                          <ul>
                                            {t('Element')} <img src={imageelem} className='img_sp'/> : <a>{doc.Element}</a>
                                          </ul>
                                          <ul>
                                            {t('HpMp')} <img src={imagehpmp} className='img_sp'/> : <a>{doc.HpMp}</a>
                                          </ul>
                                          <ul>
                                            {t('Defense')} <img src={imagedef} className='img_sp'/> : <a>{doc.Defense}</a>
                                          </ul>
                                          <ul>
                                            {t('Res_Eau')}<img src={imageeau_res} className='img_sp'/> : <a>{doc.Res_Eau}</a>
                                          </ul>
                                          <ul>
                                            {t('Res_Feu')} <img src={imagefeu_res} className='img_sp'/> : <a>{doc.Res_Feu}</a>
                                          </ul>
                                          <ul>
                                            {t('Res_Obscu')} <img src={imageobscu_res} className='img_sp'/> : <a>{doc.Res_Lulu}</a>
                                          </ul>
                                          <ul>
                                            {t('Res_Lulu')} <img src={imagelulu_res} className='img_sp'/> : <a>{doc.Res_obscu}</a>
                                          </ul></> :
                                           <> 
                                           
                                           {doc.atq ?<div className='petSkill'>
                                           
                                                  <ul>
                                                  {t('att_pet')} : <a>{doc.atq}%</a>
                                                  </ul>
                                                  <ul>
                                                  {t('def_pet')} : <a>{doc.def}%</a>
                                                  </ul>
                                                  </div> : <div className="Petskill">
                                    {doc.firstSkill ? 
                                        <>
                                      <ul>
                                      {t('1 Skill')} : <a>{doc.firstSkill} </a>
                                    </ul>
                                    <ul>
                                      {t('2 Skill')} : <a>{doc.secondSkill}</a>
                                    </ul>
                                    <ul>
                                      {t('3 Skill')} : <a>{doc.threeSkill}</a>
                                    </ul>
                                    </>                            
                                    : 
                                    <>
                                     <ul>
                                    {t('Res_Eau')} <img src={imageeau_res} className='img_sp'/> : <a>{doc.Res_Eau} </a>
                                  </ul>
                                  <ul>
                                    {t('Res_Feu')} <img src={imagefeu_res} className='img_sp'/> :  <a>{doc.Res_Feu} </a>
                                  </ul>
                                  <ul>
                                    {t('Res_Obscu')} <img src={imageobscu_res} className='img_sp'/> :  <a>{doc.Res_Lulu} </a>
                                  </ul>
                                  <ul>
                                    {t('Res_Lulu')} <img src={imagelulu_res} className='img_sp'/> :  <a>{doc.Res_obscu} </a>
                                  </ul>
                                   </>
                                    }
                                  
                                   </div>
                                          }
                                            </>
                                           }
                                          
                                        </li>
                                        
                                        
                                        )}
                                        </div>
                                      </div>
                                      
                                    </div>
                                  </div>
                                  )}
                              </th>
                            </tr>
                            
                          ))
                        

                        ) : (
                          <>
                      <th>  <br></br></th>
                      <tr className='thmid'>
                      <th></th>
                      <th></th>
                      <th><div>{t('no_data')}<p className='text_danger'>❗{t('midlaner')}❗</p></div></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      </tr>
                      <th>  <br></br></th>
                      </>
                        )}
                    </tbody>
                    <div className="pagination">
                      {data.length > 0 &&
                        [...Array(Math.min(9, Math.ceil(data.length / documentsPerPage))).keys()].map((pageNumber) => (
                          <button key={pageNumber} onClick={() => paginate(pageNumber + 1)}>
                            {pageNumber + 1}
                          </button>
                        ))}
                      {currentPage < Math.ceil(data.length / documentsPerPage) && (
                        <button onClick={() => paginate(currentPage + 1)}>{">"}</button>
                      )}
                    </div>
                    <div className="loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                </table>
                <ToastContainer />
        <div className="footerus">
                <p>{t('apropos')}</p>
                <p>facebook : <a className='fb_link' href='https://www.facebook.com/people/BazNos/100095523103918/'>BazNos FB</a></p>
      <p>{t('reserv')}</p>
      <p>{t('©')}</p>
  </div>

        </div>
        
    );
};

export default Bazar_online_page;