import React from 'react';
import { useTranslation } from 'react-i18next';
const Tableaubazar = () => {
  const { t } = useTranslation();
    return (
              <thead>
                <tr>
                    <th>{t("th1")}</th>
                    <th>{t("quanti")}</th>
                    <th>{t("each")}</th>
                    <th>{t("th2")}</th>
                    <th>{t("th3")}</th>
                    <th>{t("th5")}</th>
                </tr>
              </thead>
    );
};

export default Tableaubazar;