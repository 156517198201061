
import React, { useState,useContext } from 'react';
import Navbar from '../Components/Navbar'
import { useTranslation } from 'react-i18next';
import { auth, db2, storage} from "../firebase-config";
import {v4} from "uuid";
import { AuthContext } from '../Context/AuthContext';
import { ToastContainer,toast } from 'react-toastify';
import { doc, serverTimestamp, setDoc,getDocs,query,collection,where,getDoc,updateDoc,FieldValue } from "firebase/firestore"; 
import 'react-toastify/dist/ReactToastify.css';
import { useTextHidden } from './TextHiddenContext';
import { ref, uploadBytes } from 'firebase/storage'; // Add the missing Firebase Storage methods
const RapBug = () => {
  const { textHidden, toggleText } = useTextHidden();
        const { t } = useTranslation();
        const [description, setDescription] = useState('');
        const [stepsToReproduce, setStepsToReproduce] = useState('');
        const [file, setFile] = useState('');
        const { currentUser } = useContext(AuthContext);
        const [picture_sp,SetpictureSp] = useState({})

        const handlePictureChange = (e) => {
            SetpictureSp(e.target.files[0]);
          };
          const repotyle = {
            transition: 'margin-left 2s', // 2-second transition for the width property
            marginLeft: textHidden ? '0px' : '200px',
          };
    
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const uid_form = v4();
    const uid = currentUser.uid;
    const displayNamer = currentUser.displayName;

    const executeSetDoc = async () => {
      try {
        const docSnapshot = await getDoc(doc(db2, 'users', currentUser.uid));
        const data = docSnapshot.data();
        const imageRef = ref(storage, `ReportBug/${picture_sp.name + uid_form}`);
        await uploadBytes(imageRef, picture_sp);
        await setDoc(doc(db2, 'ReportBug', uid_form), {
          senderUid: currentUser.uid,
          currentUserUid: currentUser.uid,
          timeStamp: serverTimestamp(),
          description: description,
          Accede: stepsToReproduce,
          image_url: `ReportBug/${picture_sp + uid_form}`,
          Pseudonim: displayNamer,
          uid_form: uid_form,
        });
        toast.success(t('rapbug_10'), {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        // Optionally, setShowModal(false) if it is defined elsewhere
        return; // Stops the execution of the function here
      } catch (error) {
        console.error('Error submitting bug report:', error);
        // Handle any errors that occurred during the form submission
        // Optionally, show an error message to the user
      }
    };

    await executeSetDoc();
    setDescription('')
    setStepsToReproduce('')
    setFile()
  };
    return (
        <div>
            <Navbar/>
             <div className='Report_bug' style={repotyle}> 
             <h1 className='titl_rep'>{t('rapbug_1')}</h1>
             <div className='textrep'>
                <p>{t('rapbug_2')}</p>

                <p>{t('rapbug_3')}</p>

                <p>{t('rapbug_4')}</p>

                <p>{t('rapbug_5')}</p>
             </div>
             <div className="bug-report-form-container">
                        <h2>{t('rapbug_6')}</h2>
                        <form onSubmit={handleFormSubmit}>

                            <label>{t('rapbug_7')}</label>
                            <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />

                            <label>{t('rapbug_8')}</label>
                            <textarea value={stepsToReproduce} onChange={(e) => setStepsToReproduce(e.target.value)} />

                            <label>{t('rapbug_9')}</label>
                            <input
                            type="file"
                            onChange={handlePictureChange}
                            />

                            <button type="submit">{t('Sumbit')}</button>
                        </form>
                        </div>
            </div>
            <div className="footerus">
                  <p>{t('apropos')}</p>
        <p>{t('reserv')}</p>
        <p>{t('©')}</p>

    </div>
            <ToastContainer />
        </div>
    );
};

export default RapBug;
