import React,{useState} from 'react';
import imageSp1 from "../../nostale/Divers/item_Nosmal/clé_pricipal.png"
import imageSp2 from "../../nostale/Divers/item_Nosmal/durabilite.png"
import imageSp3 from "../../nostale/Divers/item_Nosmal/fabrication.png"
import imageSp4 from "../../nostale/Divers/item_Nosmal/jeu_tarot.png"
import imageSp5 from "../../nostale/Divers/item_Nosmal/medaille_nos.png"
import imageSp6 from "../../nostale/Divers/item_Nosmal/parfum.png"
import imageSp7 from "../../nostale/Divers/item_Nosmal/récip_scelle.png"
import imageSp8 from "../../nostale/Divers/item_Nosmal/réinitialisation_point.png"
import { useTextHidden } from '../../Components/TextHiddenContext';


const Item_nosmall = (props) => {
   const {navStyle3} = props;
   const { textHidden, toggleText } = useTextHidden();
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
            <div className='propose-item' style={navStyle3}>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("clé_pricipal", imageSp1)}><img src={imageSp1}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("durabilite", imageSp2)}><img src={imageSp2}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("fabrication", imageSp3)}><img src={imageSp3}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("jeu_tarot", imageSp4)}><img src={imageSp4}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("medaille_nos", imageSp5)}><img src={imageSp5}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("parfum", imageSp6)}><img src={imageSp6}></img></button> 
          <button type='button' id="vente_sp1"  onClick={() => handleClick("récip_scelle", imageSp7)}><img src={imageSp7}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("réinitialisation_point", imageSp8)}><img src={imageSp8}></img></button>
          </div>
    );
};

export default Item_nosmall;