import React ,{useState} from 'react';
import imageSp1 from "../../../nostale/Accesoire/Collier/0h-30h/10h.png"
import imageSp2 from "../../../nostale/Accesoire/Collier/0h-30h/15h.png"
import imageSp3 from "../../../nostale/Accesoire/Collier/0h-30h/18h.png"
import imageSp4 from "../../../nostale/Accesoire/Collier/0h-30h/18he.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Collier0_30h = (props) => {
      const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
<div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
        <button type='button'  onClick={() => handleClick( "collier_10h", imageSp2)}><img src={imageSp2}></img></button>
        <button type='button'  onClick={() => handleClick( "collier_15h", imageSp3)}><img src={imageSp3}></img></button>
        <button type='button'  onClick={() => handleClick( "collier_18h", imageSp4)}><img src={imageSp4}></img></button>
        <button type='button'  onClick={() => handleClick( "collier_18he", imageSp1)}><img src={imageSp1}></img></button>
</div>
    );
};

export default Collier0_30h;