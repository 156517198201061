import React from 'react';
import Navbar from '../Components/Navbar';
import Pagemsg from '../Components/Messagess/Pagemsg';
const Messagerie = () => {
    return (
        <div>
            <Navbar/>
            <Pagemsg/>
        </div>
    );
};

export default Messagerie;