import React,{useState}  from 'react';
import imageSp1 from "../../nostale/SP/mage/sp1.png"
import imageSp2 from "../../nostale/SP/mage/sp2.png"
import imageSp3 from "../../nostale/SP/mage/sp3.png"
import imageSp4 from "../../nostale/SP/mage/sp4.png"
import imageSp5 from "../../nostale/SP/mage/sp5.png"
import imageSp6 from "../../nostale/SP/mage/sp6.png"
import imageSp7 from "../../nostale/SP/mage/sp7.png"
import imageSp8 from "../../nostale/SP/mage/sp8.png"
import imageSp9 from "../../nostale/SP/mage/sp9.png"
import imageSp10 from "../../nostale/SP/mage/sp10.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const SpMage = (props) => {
      const { textHidden, toggleText } = useTextHidden();
  const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
        <div className='propose-item' style={navStyle3}>
        <button type='button'   id="sp1a"  onClick={() => handleClick("Sp1a", imageSp1)} ><img src={imageSp1} ></img></button>
          <button type='button'   id="sp2a"  onClick={() => handleClick( "Sp2a",imageSp2)} ><img src={imageSp2}></img></button>
          <button type='button'   id="sp3a" onClick={() => handleClick( "Sp3a", imageSp3)}><img src={imageSp3}></img></button>
          <button type='button'   id="sp4a" onClick={() => handleClick( "Sp4a", imageSp4)}><img src={imageSp4}></img></button>
          <button type='button'   id="sp5a" onClick={() => handleClick( "Sp5a", imageSp5)}><img src={imageSp5}></img></button>
          <button type='button'   id="sp6a" onClick={() => handleClick( "sp6a", imageSp6)}><img src={imageSp6}></img></button> 
          <button type='button'   id="sp7a" onClick={() => handleClick( "Sp7a", imageSp7)}><img src={imageSp7}></img></button>
          <button type='button'   id="sp8a" onClick={() => handleClick( "Sp8a", imageSp8)}><img src={imageSp8}></img></button>
          <button type='button'   id="sp9a" onClick={() => handleClick( "Sp9a", imageSp9)}><img src={imageSp9}></img></button>
          <button type='button'  id="sp10a" onClick={() => handleClick("Sp10a",imageSp10)} ><img src={imageSp10}></img></button>
        </div>
    );
};

export default SpMage;