import React from 'react';
import Navbar from '../Components/Navbar';
import Discordus from '../Components/Discordus';
const Discordjoin = () => {
    return (
        <div>
            <Navbar/>
            <Discordus/>            
        </div>
    );
};

export default Discordjoin;