import React,{useState} from 'react';
import imageSp3 from "../../nostale/Divers/Ailes/ange.png"
import imageSp4 from "../../nostale/Divers/Ailes/angelique.png"
import imageSp5 from "../../nostale/Divers/Ailes/crystal.png"
import imageSp7 from "../../nostale/Divers/Ailes/demon.png"
import imageSp8 from "../../nostale/Divers/Ailes/dorées.png"
import imageSp9 from "../../nostale/Divers/Ailes/éclair.png"
import imageSp11 from "../../nostale/Divers/Ailes/fée.png"
import imageSp12 from "../../nostale/Divers/Ailes/flamboyantes.png"
import imageSp13 from "../../nostale/Divers/Ailes/florailes.png"
import imageSp14 from "../../nostale/Divers/Ailes/algide.png"
import imageSp15 from "../../nostale/Divers/Ailes/lunaires.png"
import imageSp16 from "../../nostale/Divers/Ailes/onyx.png"
import imageSp17 from "../../nostale/Divers/Ailes/retro.png"
import imageSp18 from "../../nostale/Divers/Ailes/titaneques.png"
import imageSp19 from "../../nostale/Divers/Ailes/archidemoniaque.png"
import imageSp20 from "../../nostale/Divers/Ailes/tranchantes.png"
import imageSp21 from "../../nostale/Divers/Ailes/vent.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const Ailes = (props) => {
   const {navStyle3} = props;
   const { textHidden, toggleText } = useTextHidden();
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
<div className='propose-ritem-group'>
            <div className='propose-ritem propose-item' style={navStyle3}>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("ange", imageSp3)}><img src={imageSp3}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("angelique", imageSp4)}><img src={imageSp4}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("crystal", imageSp5)}><img src={imageSp5}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("demon", imageSp7)}><img src={imageSp7}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("dorées", imageSp8)}><img src={imageSp8}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("éclair", imageSp9)}><img src={imageSp9}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("fée", imageSp11)}><img src={imageSp11}></img></button>
            </div>
            <div className='propose-item item2 propose-ritem' style={navStyle3}>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("flamboyantes", imageSp12)}><img src={imageSp12}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("florailes", imageSp13)}><img src={imageSp13}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("algide", imageSp14)}><img src={imageSp14}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("lunaires", imageSp15)}><img src={imageSp15}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("onyx", imageSp16)}><img src={imageSp16}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("retro", imageSp17)}><img src={imageSp17}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("titaneques", imageSp18)}><img src={imageSp18}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("archidemoniaque", imageSp19)}><img src={imageSp19}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("tranchantes", imageSp20)}><img src={imageSp20}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("vent", imageSp21)}><img src={imageSp21}></img></button>
            </div>   
        </div>
    );
};

export default Ailes;