import React from 'react';
import Navbar from '../Components/Navbar';
import Page_boutique from '../Components/Page_boutique';
const Boutique = () => {
    return (
        <div>
            <Navbar/>
            <Page_boutique/>
        </div>
    );
};

export default Boutique;