import React ,{useState} from 'react';
import imageSp1 from "../../../nostale/Accesoire/Costume/11anns.png"
import imageSp2 from "../../../nostale/Accesoire/Costume/bzzz.png"
import imageSp3 from "../../../nostale/Accesoire/Costume/chevalkier.png"
import imageSp4 from "../../../nostale/Accesoire/Costume/citrouille.png"
import imageSp5 from "../../../nostale/Accesoire/Costume/diable.png"
import imageSp6 from "../../../nostale/Accesoire/Costume/dracula.png"
import imageSp7 from "../../../nostale/Accesoire/Costume/fée3.png"
import imageSp8 from "../../../nostale/Accesoire/Costume/glaççonn.png"
import imageSp9 from "../../../nostale/Accesoire/Costume/guerrier.png"
import imageSp10 from "../../../nostale/Accesoire/Costume/hiver.png"
import imageSp11 from "../../../nostale/Accesoire/Costume/hoptellerie.png"
import imageSp12 from "../../../nostale/Accesoire/Costume/illuisio.png"
import imageSp13 from "../../../nostale/Accesoire/Costume/kara.png"
import imageSp14 from "../../../nostale/Accesoire/Costume/lanncemort.png"
import imageSp15 from "../../../nostale/Accesoire/Costume/lapin.png"
import imageSp16 from "../../../nostale/Accesoire/Costume/magicienn.png"
import imageSp17 from "../../../nostale/Accesoire/Costume/marionnet.png"
import imageSp18 from "../../../nostale/Accesoire/Costume/merveille.png"
import imageSp19 from "../../../nostale/Accesoire/Costume/navigateur.png"
import imageSp20 from "../../../nostale/Accesoire/Costume/persto.png"
import imageSp21 from "../../../nostale/Accesoire/Costume/plage.png"
import imageSp22 from "../../../nostale/Accesoire/Costume/plongeur.png"
import imageSp23 from "../../../nostale/Accesoire/Costume/plongeurpri.png"
import imageSp24 from "../../../nostale/Accesoire/Costume/raftinng.png"
import imageSp25 from "../../../nostale/Accesoire/Costume/squele.png"
import imageSp26 from "../../../nostale/Accesoire/Costume/tigre_blanc.png"
import imageSp27 from "../../../nostale/Accesoire/Costume/tropica.png"
import imageSp28 from "../../../nostale/Accesoire/Costume/vikinng.png"
import imageSp29 from "../../../nostale/Accesoire/Costume/voltig.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Costume = (props) => {
   const {navStyle3} = props;
       const { textHidden, toggleText } = useTextHidden();
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
<div className='propose-ritem-group'>
            <div className='propose-ritem propose-item' style={navStyle3}>
                <button type='button'  onClick={() => handleClick( "cost_11anns", imageSp1)}><img src={imageSp1}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_bzzz", imageSp2)}><img src={imageSp2}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_chevalkier", imageSp3)}><img src={imageSp3}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_citrouille", imageSp4)}><img src={imageSp4}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_diable", imageSp5)}><img src={imageSp5}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_dracula", imageSp6)}><img src={imageSp6}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_fée3", imageSp7)}><img src={imageSp7}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_glaççonn", imageSp8)}><img src={imageSp8}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_guerrier", imageSp9)}><img src={imageSp9}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_hiver", imageSp10)}><img src={imageSp10}></img></button>        
                <button type='button'  onClick={() => handleClick( "cost_hoptellerie", imageSp11)}><img src={imageSp11}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_illuisio", imageSp12)}><img src={imageSp12}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_kara", imageSp13)}><img src={imageSp13}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_lanncemort", imageSp14)}><img src={imageSp14}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_lapin", imageSp15)}><img src={imageSp15}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_magicienn", imageSp16)}><img src={imageSp16}></img></button>
                <button type='button'  onClick={() => handleClick( "cost_marionnet", imageSp17)}><img src={imageSp17}></img></button>
            </div>
            <div className='propose-item item2 propose-ritem' style={navStyle3}>
            <button type='button'  onClick={() => handleClick( "cost_merveille", imageSp18)}><img src={imageSp18}></img></button>
            <button type='button'  onClick={() => handleClick( "cost_navigateur", imageSp19)}><img src={imageSp19}></img></button>
            <button type='button'  onClick={() => handleClick( "cost_persto", imageSp20)}><img src={imageSp20}></img></button>
            <button type='button'  onClick={() => handleClick( "cost_plage", imageSp21)}><img src={imageSp21}></img></button>
            <button type='button'  onClick={() => handleClick( "cost_plongeur", imageSp22)}><img src={imageSp22}></img></button>
            <button type='button'  onClick={() => handleClick( "cost_plongeurpri", imageSp23)}><img src={imageSp23}></img></button>
            <button type='button'  onClick={() => handleClick( "cost_raftinng", imageSp24)}><img src={imageSp24}></img></button>
            <button type='button'  onClick={() => handleClick( "cost_squele", imageSp25)}><img src={imageSp25}></img></button>
            <button type='button'  onClick={() => handleClick( "cost_tigre_blanc", imageSp26)}><img src={imageSp26}></img></button>
            <button type='button'  onClick={() => handleClick( "cost_tropica", imageSp27)}><img src={imageSp27}></img></button>
            <button type='button'  onClick={() => handleClick( "cost_vikinng", imageSp28)}><img src={imageSp28}></img></button>
            <button type='button'  onClick={() => handleClick( "cost_voltig", imageSp29)}><img src={imageSp29}></img></button>    
            </div>   
        </div>
    );
};

export default Costume;