import React ,{useState} from 'react';
import imageSp1 from "../../../nostale/Accesoire/Chapeau/60h-80h/80e.png"
import imageSp2 from "../../../nostale/Accesoire/Chapeau/60h-80h/80hc.png"
import imageSp3 from "../../../nostale/Accesoire/Chapeau/60h-80h/80hh.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Chapeau60_80h = (props) => {
    const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
        <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
            <button type='button'  onClick={() => handleClick( "chap_80e", imageSp1)}><img src={imageSp1}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_80hc", imageSp2)}><img src={imageSp2}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_80hh", imageSp3)}><img src={imageSp3}></img></button>
        </div>
    );
};

export default Chapeau60_80h;