import React, {  useEffect,useState, useContext } from 'react';
import Select from 'react-select';
import Navbar from './Navbar';
import { db2, storage,auth } from '../firebase-config';
import {v4} from "uuid";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes  } from "firebase/storage";
import { doc, serverTimestamp, setDoc,getDocs,query,collection,where,getDoc,updateDoc,FieldValue } from "firebase/firestore"; 
import { useTranslation } from 'react-i18next';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imgload from "../photo_anex/load.gif" ;
const SellGoldNos = () => {
    const { t } = useTranslation();
    const [uid_form,setUid_from] = useState(v4())
    const [serveur,setServeur]= useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [otherFieldsDisabled, setOtherFieldsDisabled] = useState(true);
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [cb,setCB]=useState(false);
    const [paypal,setPaypal]=useState(false);
    const [psf,setPsf]=useState(false);
    const [crypto,setCrypto]=useState(false);
    const { currentUser } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [texteConfirme, setTextConfirm] = useState(`${t('coutera')} 3 NosCoin.`);
  const  nosdollarPay=3
  const[eurogain,SetEuroGain]=useState(0)
  const hourglassElement = document.querySelector('.loader');
  const [spModals, setspModals] = useState({
    modalsellsp:false
  })
  useEffect(() => {
    if (price && quantity) {
      const totalEuroGain = price * quantity;
      SetEuroGain(totalEuroGain);
    } else {
      SetEuroGain(0); // Reset to 0 if either price or quantity is not set
    }
  }, [price, quantity]);
    const navigate = useNavigate();
    
    
    const hideModal = () => {
        setShowModal(false);
        setspModals({ modalsellsp: false });
      };


    const tryToPay = async (e) => {

      if(!serveur){
        toast.warn(t("take_serv"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return
      }
      if(!price){
        toast.warn(t("take_price"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return
      }
      if(!quantity){
        toast.warn(t("take_quantity"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return
      }
        const executeSetDoc = async () => {
                    hourglassElement.style.display = 'inline-block';
          const docSnapshot = await getDoc(doc(db2, "users", currentUser.uid));
          const data = docSnapshot.data();
          const nosDollarValue = data.Nosdollar || 0;

          if (nosDollarValue >= nosdollarPay ) {

            // Décrémente la valeur de Nosdollar dans la table par la valeur de nosdollarPay
            const updatedNosDollarValue = nosDollarValue - nosdollarPay;
            await updateDoc(doc(db2, "users", currentUser.uid), { Nosdollar: updatedNosDollarValue });
            
            await setDoc(doc(db2, "Sell_gold", uid_form), {
                senderUid: currentUser.uid,
                prix: price,
                serveur: serveur,
                quantity: quantity,
                timeStamp: serverTimestamp(),
                cb: cb,
                paypal: paypal,
                psf: psf,
                crypto: crypto
              });
      

            hourglassElement.style.display = 'none';
            setShowModal(false);
          
            window.location.reload();
            toast.success(t("posted"), {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            return; // Arrête l'exécution de la fonction ici
          } else {
            toast.error(t("mor_nos"), {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setShowModal(false);
            navigate('/goldnostale')
            hourglassElement.style.display = 'none';
            return; // Arrête l'exécution de la fonction ici
          }
        };
        await executeSetDoc();
      };



    const BuyGold = (e)=>{
        navigate('/goldnostale');
    }


    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setServeur(selectedOption.value);
        if (['Valehir', 'Alzanor', 'Dragonveil', 'Cosmos','Jotunheim']) {
          setOtherFieldsDisabled(false);
        } else {
          setOtherFieldsDisabled(true);
        }
      }

    const Serveur = [
        { value: 'Valehir', label: 'Valehir' },
        { value: 'Alzanor', label: 'Alzanor' },
        { value: 'Dragonveil', label: 'Dragonveil' },
        { value: 'Cosmos', label: 'Cosmos' },
        { value: 'Jotunheim', label: 'Jotunheim' }
      ];
      const customStyles = {
        container: (provided) => ({
          ...provided,
          width: '150px',
          fontSize: '15px',
          position: 'relative',
          cursor: 'pointer',
          display: 'inline-block' // add this to align with the original .list-choice
        }),
        control: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          color: '#FFF',
          textShadow: '0 1px 0 rgba(144, 142, 142, 0.66)',
          borderRadius: '0.2em',
          border: 'none',
          cursor: 'pointer',
          display: 'flex', // center selected value
          justifyContent: 'center', // center selected value
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          borderRadius: '0.2em',
          overflow: 'hidden'
        }),
        option: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          color: '#FFF',
          cursor: 'pointer'
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#FFF',
          background: 'rgb(116,214,142)',
          background: 'linear-gradient(102deg, rgba(116,214,142,1) 0%, rgba(116,214,142,0.04525560224089631) 59%)',
          height: '39px',
          borderRadius: '2.4px 6px 6px 2.4px',
          marginLeft:'-8px',
          width: '113px', // fill width of control
          display: 'flex',
          alignItems: 'center',
          marginTop: '-1.8px',
          marginBottom: '-2px',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#FFF'
        })
      };


    return (
        <div>
            <Navbar/>
            <div className='buy_goldnos'>
                <h3>{t('titreSell_gold')}</h3>
                <button className='addpost'onClick={BuyGold} >{t('Annonce_buy')}</button>
            </div>
            
            <div className='form_sell'>
                
                    <h1>{t('form')}</h1>
                    
                    <form >
                        <div className='form_sellnd'>
                        <div className='sell_1'>
                    <div>
                        <label>{t('Serveur')} : </label>
                        <Select styles={customStyles} options={Serveur} placeholder={t('Serveur')} onChange={handleSelectChange} />
                    </div>
                    <div className='pricends'>
                        <label>{t('prixsellgold')}</label>
                        <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} />

                    </div>
                    
                    <div  className='pricends'>
                        <label>{t('quantitysellgold')}</label>
                        <input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} />
                    </div>
                    <div className='center'>
                    <p className='text_info'>{t('iselkl')} {quantity}.000.000 gold</p>
                    <p className='text_info'>{price}€= 1.000.000 gold</p>
                    <p className='text_info'>{eurogain}€ {t('total')}</p>
                    </div>
                    </div>
                    <div className='sell_2'>
                    <label className='paiementnd'>{t('Paiement')}</label>
                <form action="" className="containerpo">

              <input className="input-btn"  type="checkbox" id="cb" name="valueIs-radio"  checked={cb} onChange={(e) => setCB(e.target.checked)}/>
              <label className="neon-btn" for="cb">
                  <span className="span"></span>
                  <span className="txt">{t('CarteB')}</span>
              </label>

              <input className="input-btn"  type="checkbox" id="paypal" name="valueIs-radio"  checked={paypal} onChange={(e) => setPaypal(e.target.checked)}/>
              <label className="neon-btn" for="paypal">
                  <span className="span"></span>
                  <span className="txt">{t('PayPal')}</span>
              </label>

              <input className="input-btn"  type="checkbox" id="psf" name="valueIs-radio"  checked={psf} onChange={(e) => setPsf(e.target.checked)}/>
              <label className="neon-btn" for="psf">
                  <span className="span"></span>
                  <span className="txt">{t('Paysafecard')}</span>
              </label>

              <input className="input-btn"  type="checkbox" id="crypto" name="valueIs-radio"  checked={crypto} onChange={(e) => setCrypto(e.target.checked)}/>
              <label className="neon-btn" for="crypto">
                  <span className="span"></span>
                  <span className="txt">{t('Crypto')}</span>
              </label>
              </form>


              <button type="button" className='sumbit_img' onClick={(e) => setShowModal(true)}>
  <p className="button-text">{t('Submit')}</p> <p className="iconer"><svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"></path><path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="currentColor"></path></svg></p> </button>
                    </div>
                   
                  {showModal && (
                <div className="modal_del">
                  <div className="card">
                <div className="card-content">
                  <p className="card-heading">{texteConfirme}</p>
                  <p className="card-description">{t('real_sup')}</p>
                </div>
                <div className="card-button-wrapper">
                  <button className="card-button secondaryr"  type="button" onClick={hideModal}>Cancel</button>
                  <button className="card-button primaryr" type="button" onClick={tryToPay}>confirme</button>
                </div>
                <button className="exit-button" onClick={hideModal}>
                  <svg height="20px" viewBox="0 0 384 512">
                    <path
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                    ></path>
                  </svg>
                </button>
              </div>
                </div>
              )}
                   
                    </div>
                    </form>
                                        <div className="loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <p className='text_info'>{t('psell')}</p>
                    <p className='text_danger'>❗{t('midlaner')}❗</p>
                </div>
                <ToastContainer />
        </div>
    );
};

export default SellGoldNos;