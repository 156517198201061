import React,{useState} from 'react';
import imageSp1 from "../../nostale/all_arme/escri/80-95/80.png"
import imageSp2 from "../../nostale/all_arme/escri/80-95/82.png"
import imageSp3 from "../../nostale/all_arme/escri/80-95/82hono.png"
import imageSp4 from "../../nostale/all_arme/escri/80-95/83.png"
import imageSp5 from "../../nostale/all_arme/escri/80-95/85.png"
import imageSp6 from "../../nostale/all_arme/escri/80-95/85fake.png"
import imageSp7 from "../../nostale/all_arme/escri/80-95/85grand.png"
import imageSp8 from "../../nostale/all_arme/escri/80-95/85heros.png"
import imageSp9 from "../../nostale/all_arme/escri/80-95/88.png"
import imageSp10 from "../../nostale/all_arme/escri/80-95/88ahono.png"
import imageSp11 from "../../nostale/all_arme/escri/80-95/88c.png"
import imageSp12 from "../../nostale/all_arme/escri/80-95/88chef.png"
import imageSp13 from "../../nostale/all_arme/escri/80-95/88hono.png"
import imageSp14 from "../../nostale/all_arme/escri/80-95/88magma.png"
import imageSp15 from "../../nostale/all_arme/escri/80-95/88ph.png"
import imageSp16 from "../../nostale/all_arme/escri/80-95/88sc.png"
import imageSp17 from "../../nostale/all_arme/escri/80-95/90a.png"
import imageSp18 from "../../nostale/all_arme/escri/80-95/90e.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const E80_95 = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
      <div className='propose-ritem-group'>
      <div className='propose-ritem propose-item' style={navStyle3}>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_80", imageSp1)}><img src={imageSp1}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_82", imageSp2)} ><img src={imageSp2}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_82hono", imageSp3)} ><img src={imageSp3}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_83", imageSp4)} ><img src={imageSp4}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_85", imageSp5)} ><img src={imageSp5}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_85fake", imageSp6)} ><img src={imageSp6}></img></button> 
    <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_85grand", imageSp7)} ><img src={imageSp7}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_85heros", imageSp8)} ><img src={imageSp8}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_88", imageSp9)} ><img src={imageSp9}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_88ahono", imageSp10)} ><img src={imageSp10}></img></button>
  
    </div>
      <div className='propose-item item2 propose-ritem' style={navStyle3}>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_88c", imageSp11)} ><img src={imageSp11}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_88chef", imageSp12)} ><img src={imageSp12}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_88hono", imageSp13)} ><img src={imageSp13}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_88magma", imageSp14)} ><img src={imageSp14}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_88ph", imageSp15)} ><img src={imageSp15}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_88sc", imageSp16)} ><img src={imageSp16}></img></button>       
      <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_90a", imageSp17)} ><img src={imageSp17}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("lame_90e", imageSp18)} ><img src={imageSp18}></img></button>

            </div>
            </div>
    );
};

export default E80_95;