import React ,{useState} from 'react';
import imageSp1 from "../../../nostale/Accesoire/Chapeau_costume/bonnet_renne.png"
import imageSp2 from "../../../nostale/Accesoire/Chapeau_costume/bzzz.png"
import imageSp3 from "../../../nostale/Accesoire/Chapeau_costume/citrouille.png"
import imageSp4 from "../../../nostale/Accesoire/Chapeau_costume/diable.png"
import imageSp5 from "../../../nostale/Accesoire/Chapeau_costume/dracula.png"
import imageSp6 from "../../../nostale/Accesoire/Chapeau_costume/form.png"
import imageSp7 from "../../../nostale/Accesoire/Chapeau_costume/givré.png"
import imageSp8 from "../../../nostale/Accesoire/Chapeau_costume/Lanncemort.png"
import imageSp9 from "../../../nostale/Accesoire/Chapeau_costume/merveille.png"
import imageSp10 from "../../../nostale/Accesoire/Chapeau_costume/noisette.png"
import imageSp11 from "../../../nostale/Accesoire/Chapeau_costume/paque.png"
import imageSp12 from "../../../nostale/Accesoire/Chapeau_costume/perstologue.png"
import imageSp13 from "../../../nostale/Accesoire/Chapeau_costume/rafting.png"
import imageSp14 from "../../../nostale/Accesoire/Chapeau_costume/régulateur.png"
import imageSp15 from "../../../nostale/Accesoire/Chapeau_costume/ruban.png"
import imageSp16 from "../../../nostale/Accesoire/Chapeau_costume/squelette.png"
import imageSp17 from "../../../nostale/Accesoire/Chapeau_costume/tropic.png"
import imageSp18 from "../../../nostale/Accesoire/Chapeau_costume/tuba.png"
import imageSp19 from "../../../nostale/Accesoire/Chapeau_costume/voltigenieur.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const ChapeauCost = (props) => { 
      const { textHidden, toggleText } = useTextHidden();
  const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
<div className='propose-ritem-group'>
            <div className='propose-ritem propose-item' style={navStyle3}>
            <button type='button'  onClick={() => handleClick( "chap_bonnet_renne", imageSp1)}><img src={imageSp1}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_bzzz", imageSp2)}><img src={imageSp2}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_citrouille", imageSp3)}><img src={imageSp3}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_diable", imageSp4)}><img src={imageSp4}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_dracula", imageSp5)}><img src={imageSp5}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_form", imageSp6)}><img src={imageSp6}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_givré", imageSp7)}><img src={imageSp7}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_Lanncemort", imageSp8)}><img src={imageSp8}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_merveille", imageSp9)}><img src={imageSp9}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_noisette", imageSp10)}><img src={imageSp10}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_paque", imageSp11)}><img src={imageSp11}></img></button>
            </div>
            <div className='propose-item item2 propose-ritem' style={navStyle3}>
            <button type='button'  onClick={() => handleClick( "chap_perstologue", imageSp12)}><img src={imageSp12}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_rafting", imageSp13)}><img src={imageSp13}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_régulateur", imageSp14)}><img src={imageSp14}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_ruban", imageSp15)}><img src={imageSp15}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_squelette", imageSp16)}><img src={imageSp16}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_tropic", imageSp17)}><img src={imageSp17}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_tuba", imageSp18)}><img src={imageSp18}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_voltigenieur", imageSp19)}><img src={imageSp19}></img></button>
            </div>   
        </div>
    );
};

export default ChapeauCost;