import React from 'react';

const Shoes80_95 = () => {

    return (
        <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80">
        </div>
    );
};

export default Shoes80_95;