import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const RuneAcces = (props) => {
  const [valeurSelect, setValeurSelect] = useState('Aug_hp_cell');
  const [valeurInput, setValeurInput] = useState(0);
  const { t } = useTranslation();
    const cellon = [
        'Aug_hp_cell', 'Aug_mp_cell', 'reg_hp_cell', 'reg_mp_cell', 'reduc_crit_cell', 'reduc_mp_cell'
      ];

  const [check, setCheck] = useState(false);
  const checkChanges = () => {
    // Vérifier si les champs ont été modifiés
    // Par exemple, si valeurSelect et valeurInput ont été modifiés
    if (valeurInput !== 0 && valeurInput !== null) {
      // Mettre à jour setCheck si les champs ont été modifiés
      setCheck(true);
    } else {
      // Les champs n'ont pas été modifiés, pas besoin de mettre à jour setCheck
      setCheck(false);
    }
  };

  const confirm_rune = document.querySelector('.confirm_rune_acces');

  if (check){
    confirm_rune.style.display = 'none';
  }else {
    confirm_rune.style.display = 'flex';
  }
  // Use useEffect to call onValueChange with initial values

  function handleButtonClick() {
    // Appeler props.onValueChange avec les valeurs actuelles de select et de l'input
    checkChanges()
    props.onValueChange(props.index, valeurSelect, valeurInput);
  }
    return (
        <div>
             <select  className='select-add armec' value={valeurSelect} disabled={check}  onChange={e=>setValeurSelect(e.target.value)}>
            <option value={cellon[0]}>{t('Aug_hp_cell')}</option>
            <option value={cellon[1]}>{t('Aug_mp_cell')}</option>
            <option value={cellon[2]}>{t('reg_hp_cell')}</option>
            <option value={cellon[3]}>{t('reg_mp_cell')}</option>
            <option value={cellon[4]}>{t('reduc_crit_cell')}</option>
            <option value={cellon[5]}>{t('reduc_mp_cell')}</option>
          </select>
          <input type="number" className="input_1" value={valeurInput} disabled={check} min='0' onChange={e=>setValeurInput(e.target.value)} />
          {!check ? <button type='button' className='btn_rune' onClick={handleButtonClick}>
        <span className="material-symbols-outlined">
          done_outline
        </span>
      </button>
        :
        <></>
        }
        </div>
    );
};

export default RuneAcces;