import React,{useState} from 'react';
import imageSp1 from "../../nostale/Divers/boite_raide/alzanor.png"
import imageSp2 from "../../nostale/Divers/boite_raide/anceloan.png"
import imageSp3 from "../../nostale/Divers/boite_raide/araigné.png"
import imageSp4 from "../../nostale/Divers/boite_raide/bélial.png"
import imageSp5 from "../../nostale/Divers/boite_raide/bérios.png"
import imageSp6 from "../../nostale/Divers/boite_raide/bonhomme.png"
import imageSp7 from "../../nostale/Divers/boite_raide/calvina.png"
import imageSp8 from "../../nostale/Divers/boite_raide/carno.png"
import imageSp9 from "../../nostale/Divers/boite_raide/castra.png"
import imageSp10 from "../../nostale/Divers/boite_raide/cuby.png"
import imageSp11 from "../../nostale/Divers/boite_raide/draco.png"
import imageSp12 from "../../nostale/Divers/boite_raide/erenia.png"
import imageSp13 from "../../nostale/Divers/boite_raide/fafnir.png"
import imageSp14 from "../../nostale/Divers/boite_raide/feron.png"
import imageSp15 from "../../nostale/Divers/boite_raide/ginseng.png"
import imageSp16 from "../../nostale/Divers/boite_raide/glagal.png"
import imageSp17 from "../../nostale/Divers/boite_raide/grenigas.png"
import imageSp18 from "../../nostale/Divers/boite_raide/hatus.png"
import imageSp19 from "../../nostale/Divers/boite_raide/herbin.png"
import imageSp20 from "../../nostale/Divers/boite_raide/jack.png"
import imageSp21 from "../../nostale/Divers/boite_raide/kertos.png"
import imageSp22 from "../../nostale/Divers/boite_raide/kiro.png"
import imageSp23 from "../../nostale/Divers/boite_raide/morcos.png"
import imageSp24 from "../../nostale/Divers/boite_raide/namaju.png"
import imageSp25 from "../../nostale/Divers/boite_raide/Paimon.png"
import imageSp26 from "../../nostale/Divers/boite_raide/pirate.png"
import imageSp27 from "../../nostale/Divers/boite_raide/poule.png"
import imageSp28 from "../../nostale/Divers/boite_raide/roi_poulet.png"
import imageSp29 from "../../nostale/Divers/boite_raide/slade.png"
import imageSp30 from "../../nostale/Divers/boite_raide/valakus.png"
import imageSp31 from "../../nostale/Divers/boite_raide/valehir.png"
import imageSp32 from "../../nostale/Divers/boite_raide/yertirand.png"
import imageSp33 from "../../nostale/Divers/boite_raide/zenas.png"
import { useTextHidden } from '../../Components/TextHiddenContext';

const BoxRaid = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
        <div className='propose-ritem-group'>
            <div className='propose-ritem propose-item' style={navStyle3}>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("alzanor", imageSp1)}><img src={imageSp1}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("anceloan", imageSp2)}><img src={imageSp2}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("araigné", imageSp3)}><img src={imageSp3}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("bélial", imageSp4)}><img src={imageSp4}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("bérios", imageSp5)}><img src={imageSp5}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("bonhomme", imageSp6)}><img src={imageSp6}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("calvina", imageSp7)}><img src={imageSp7}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("carno", imageSp8)}><img src={imageSp8}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("castra", imageSp9)}><img src={imageSp9}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("cuby", imageSp10)}><img src={imageSp10}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("draco", imageSp11)}><img src={imageSp11}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("erenia", imageSp12)}><img src={imageSp12}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("fafnir", imageSp13)}><img src={imageSp13}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("feron", imageSp14)}><img src={imageSp14}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("ginseng", imageSp15)}><img src={imageSp15}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("glagal", imageSp16)}><img src={imageSp16}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("grenigas", imageSp17)}><img src={imageSp17}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("hatus", imageSp18)}><img src={imageSp18}></img></button>
            </div>
            <div className='propose-item item2 propose-ritem' style={navStyle3}>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("herbin", imageSp19)}><img src={imageSp19}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("jack", imageSp20)}><img src={imageSp20}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("kertos", imageSp21)}><img src={imageSp21}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("kiro", imageSp22)}><img src={imageSp22}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("morcos", imageSp23)}><img src={imageSp23}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("namaju", imageSp24)}><img src={imageSp24}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("Paimon", imageSp25)}><img src={imageSp25}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("pirate", imageSp26)}><img src={imageSp26}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("poule", imageSp27)}><img src={imageSp27}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("roi_poulet", imageSp28)}><img src={imageSp28}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("slade", imageSp29)}><img src={imageSp29}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("valakus", imageSp30)}><img src={imageSp30}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("valehir", imageSp31)}><img src={imageSp31}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("yertirand", imageSp32)}><img src={imageSp32}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("zenas", imageSp33)}><img src={imageSp33}></img></button>
            </div>
        </div>
    );
};

export default BoxRaid;