import React,{useState} from 'react';
import ImageSp1 from "../../nostale/all_arme/mage/80-95/80.png"
import ImageSp2 from "../../nostale/all_arme/mage/80-95/82.png"
import ImageSp3 from "../../nostale/all_arme/mage/80-95/82hono.png"
import ImageSp4 from "../../nostale/all_arme/mage/80-95/83.png"
import ImageSp5 from "../../nostale/all_arme/mage/80-95/85.png"
import ImageSp6 from "../../nostale/all_arme/mage/80-95/85chef.png"
import ImageSp7 from "../../nostale/all_arme/mage/80-95/85chefb.png"
import ImageSp8 from "../../nostale/all_arme/mage/80-95/85fake.png"
import ImageSp9 from "../../nostale/all_arme/mage/80-95/85r.png"
import ImageSp10 from "../../nostale/all_arme/mage/80-95/85yertilulu.png"
import ImageSp11 from "../../nostale/all_arme/mage/80-95/88.png"
import ImageSp12 from "../../nostale/all_arme/mage/80-95/88calvi.png"
import ImageSp13 from "../../nostale/all_arme/mage/80-95/88hono.png"
import ImageSp14 from "../../nostale/all_arme/mage/80-95/88magi.png"
import ImageSp15 from "../../nostale/all_arme/mage/80-95/88spc.png"
import ImageSp16 from "../../nostale/all_arme/mage/80-95/88valaket.png"
import ImageSp17 from "../../nostale/all_arme/mage/80-95/90.png"
import ImageSp18 from "../../nostale/all_arme/mage/80-95/875yertiobs.png"
import ImageSp19 from "../../nostale/all_arme/mage/80-95/b88calvi.png"
import ImageSp20 from "../../nostale/all_arme/mage/80-95/b90.png"
import ImageSp21 from "../../nostale/all_arme/mage/80-95/b88hono.png"
import ImageSp22 from "../../nostale/all_arme/mage/80-95/bb85fake.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const M80_95 = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
      <div className='propose-ritem-group'>
<div className='propose-ritem propose-item' style={navStyle3}>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_80", ImageSp1)}><img src={ImageSp1}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_82", ImageSp2)}><img src={ImageSp2} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_82hono", ImageSp3)}><img src={ImageSp3} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_83", ImageSp4)}><img src={ImageSp4} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_85", ImageSp5)}><img src={ImageSp5} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_85chef", ImageSp6)}><img src={ImageSp6} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_85chefb", ImageSp7)}><img src={ImageSp7} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_85fake", ImageSp8)}><img src={ImageSp8} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_85r", ImageSp9)}><img src={ImageSp9} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_85yertilulu", ImageSp10)}><img src={ImageSp10}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_88", ImageSp11)}><img src={ImageSp11} ></img></button>
        </div>
        <div className='propose-item item2 propose-ritem' style={navStyle3}>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_88calvi", ImageSp12)}><img src={ImageSp12} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_88hono", ImageSp13)}><img src={ImageSp13} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_88magi", ImageSp14)}><img src={ImageSp14} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_88spc", ImageSp15)}><img src={ImageSp15} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_88valaket", ImageSp16)}><img src={ImageSp16} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_90", ImageSp17)}><img src={ImageSp17} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_875yertiobs", ImageSp18)}><img src={ImageSp18} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_b88calvi", ImageSp19)}><img src={ImageSp19}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_b90", ImageSp20)}><img src={ImageSp20} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_b88hono", ImageSp21)}><img src={ImageSp21} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_bb85fake", ImageSp22)}><img src={ImageSp22} ></img></button>
</div>
        </div>
    );
};

export default M80_95;