import React,{useState} from 'react';
import imageSp1 from "../../nostale/Divers/Divers/gillion.png"
import imageSp2 from "../../nostale/Divers/Divers/popo_att.png"
import imageSp3 from "../../nostale/Divers/Divers/popo_def.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
import imageSp4 from "../../nostale/Divers/Divers/popo_exp.png"

const Divers = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
        <div className='propose-item' style={navStyle3}>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("gillion", imageSp1)}><img src={imageSp1}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("popo_att", imageSp2)}><img src={imageSp2}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("popo_def", imageSp3)}><img src={imageSp3}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("popo_exp", imageSp4)}><img src={imageSp4}></img></button>
          </div>
    );
};

export default Divers;