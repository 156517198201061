import React,{useState} from 'react';
import image1 from "../nostale/anex/ENVELOPE.webp"
import image2 from "../nostale/anex/OUAAI.webp"
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const Nouveau_account = () => {
      const { t } = useTranslation();
      const navigate = useNavigate();
      
      const BackHome =(e) => {navigate('/')}

    return (
        <div className='new_accou'>
            <h1>{t('email_1')}</h1>
            <div className='text_new'>
            <p>{t('email_2')}</p>

                <p>{t('email_3')}</p>

                <p>{t('email_4')}</p>

                <p className='mail_verif'>{t('email_5')}</p>
                <p>{t('email_6')}</p>

                <p>{t('email_7')}</p>
                <p>{t('email_8')}</p>

                <p>{t('email_9')}</p>

                <p>{t('email_10')}</p>

                <p>{t('email_11')}</p>

                <p>{t('email_12')}</p>
               
            </div>
            <div className='img_new'>
            <img src={image1}/>
            <img src={image2}/>
            </div>
            <button type="button" onClick={BackHome} className='btn_back_home'>{t('email_13')}</button>
        </div>
    );
};

export default Nouveau_account;