import React,{useState} from 'react';
import ImageSp1 from "../../nostale/all_armure/archer/80-95/80hono.png"
import ImageSp2 from "../../nostale/all_armure/archer/80-95/80voleur.png"
import ImageSp3 from "../../nostale/all_armure/archer/80-95/85.png"
import ImageSp4 from "../../nostale/all_armure/archer/80-95/85fake.png"
import ImageSp5 from "../../nostale/all_armure/archer/80-95/88.png"
import ImageSp6 from "../../nostale/all_armure/archer/80-95/88braise.png"
import ImageSp7 from "../../nostale/all_armure/archer/80-95/88hono.png"
import ImageSp8 from "../../nostale/all_armure/archer/80-95/90.png"
import { useTextHidden } from '../../Components/TextHiddenContext';

const Aarmure80_95 = (props) => {
   const {navStyle3} = props;
   const { textHidden, toggleText } = useTextHidden();
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
      <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("tunique_80hono", ImageSp1)}><img src={ImageSp1}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("tunique_80voleur", ImageSp2)}><img src={ImageSp2} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("tunique_85", ImageSp3)}><img src={ImageSp3} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("tunique_85fake", ImageSp4)}><img src={ImageSp4} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("tunique_88", ImageSp5)}><img src={ImageSp5} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("tunique_88braise", ImageSp6)}><img src={ImageSp6} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("tunique_88hono", ImageSp7)}><img src={ImageSp7} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("tunique_90", ImageSp8)}><img src={ImageSp8} ></img></button>
        </div>
    );
};

export default Aarmure80_95;