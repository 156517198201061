import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const RuneC = (props) => {
  const [valeurSelect, setValeurSelect] = useState('def_rap');
  const [valeurInput, setValeurInput] = useState(0);
  const { t } = useTranslation();


    const [check, setCheck] = useState(false);
    const checkChanges = () => {
      // Vérifier si les champs ont été modifiés
      // Par exemple, si valeurSelect et valeurInput ont été modifiés
      if (valeurInput !== 0 && valeurInput !== null) {
        // Mettre à jour setCheck si les champs ont été modifiés
        setCheck(true);
      } else {
        // Les champs n'ont pas été modifiés, pas besoin de mettre à jour setCheck
        setCheck(false);
      }
    };

    const confirm_rune = document.querySelector('.confirm_rune');
  
    if (check){
      confirm_rune.style.display = 'none';
    }else {
      confirm_rune.style.display = 'flex';
    }

  // Use useEffect to call onValueChange with initial values

  function handleButtonClick() {
    // Appeler props.onValueChange avec les valeurs actuelles de select et de l'input
    checkChanges()
    props.onValueChange(props.index, valeurSelect, valeurInput);
  }

  const armec = [
    'def_rap', 'def_dist', 'def_mag', 'expo_petit_pla', 'plai_bean',
     'gene_bean', 'black', 'chaq_black', 'main_mor', 'be_cong', 'be_aveu','be_fig'
     , 'perd_def', 'be_choc', 'para_pois', 'retab_hp', 'hp_natu'
     , 'retab_mp', 'mp_natu', 'be_crit', 'rez_feu', 'rez_eau'
     , 'rez_lulu', 'rez_obscu'
  ];
  return (
    <div>
      <select className='select-add armec'  value={valeurSelect} disabled={check}  onChange={e=>setValeurSelect(e.target.value)}>
            <option value={armec[0]}>{t('def_rap')}</option>
            <option value={armec[1]}>{t('def_dist')}</option>
            <option value={armec[2]}>{t('def_mag')}</option>
            <option value={armec[3]}>{t('expo_petit_pla')}</option>
            <option value={armec[4]}>{t('plai_bean')}</option>
            <option value={armec[5]}>{t('gene_bean')}</option>
            <option value={armec[6]}>{t('black')}</option>
            <option value={armec[7]}>{t('chaq_black')}</option>
            <option value={armec[8]}>{t('main_mor')}</option>
            <option value={armec[9]}>{t('be_cong')}</option>
            <option value={armec[10]}>{t('be_aveu')}</option>
            <option value={armec[11]}>{t('be_fig')}</option>
            <option value={armec[12]}>{t('perd_def')}</option>
            <option value={armec[13]}>{t('be_choc')}</option>
            <option value={armec[14]}>{t('para_pois')}</option>
            <option value={armec[15]}>{t('retab_hp')}</option>
            <option value={armec[16]}>{t('hp_natu')}</option>
            <option value={armec[17]}>{t('retab_mp')}</option>
            <option value={armec[18]}>{t('mp_natu')}</option>
            <option value={armec[19]}>{t('be_crit')}</option>
            <option value={armec[20]}>{t('rez_feu')}</option>
            <option value={armec[21]}>{t('rez_eau')} </option>
            <option value={armec[22]}>{t('rez_lulu')} </option>
            <option value={armec[23]}>{t('rez_obscu')} </option>
          </select>
          <input type="number" className="input_1" value={valeurInput} disabled={check} min='0' onChange={e=>setValeurInput(e.target.value)} />
          {!check ? <button type='button' className='btn_rune' onClick={handleButtonClick}>
        <span className="material-symbols-outlined">
          done_outline
        </span>
      </button>
        :
        <></>
        }
        </div>
    );
};

export default RuneC;