import React,{useState} from 'react';
import ImageSp1 from "../../nostale/all_armure/Artiste-martial/80-95/85.png"
import ImageSp2 from "../../nostale/all_armure/Artiste-martial/80-95/85aka.png"
import ImageSp3 from "../../nostale/all_armure/Artiste-martial/80-95/85voleur.png"
import ImageSp4 from "../../nostale/all_armure/Artiste-martial/80-95/87.png"
import ImageSp5 from "../../nostale/all_armure/Artiste-martial/80-95/87puiss.png"
import ImageSp6 from "../../nostale/all_armure/Artiste-martial/80-95/88.png"
import ImageSp7 from "../../nostale/all_armure/Artiste-martial/80-95/88feli.png"
import ImageSp8 from "../../nostale/all_armure/Artiste-martial/80-95/89black.png"
import imageSp9 from "../../nostale/all_armure/Artiste-martial/80-95/89sica.png"
import imageSp10 from "../../nostale/all_armure/Artiste-martial/80-95/90adv.png"
import imageSp11 from "../../nostale/all_armure/Artiste-martial/80-95/90cuarry.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const AMarmure80_95 = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
      <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_85", ImageSp1)}><img src={ImageSp1}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_85aka", ImageSp2)}><img src={ImageSp2} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_85voleur", ImageSp3)}><img src={ImageSp3} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_87", ImageSp4)}><img src={ImageSp4} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_87puiss", ImageSp5)}><img src={ImageSp5} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_88", ImageSp6)}><img src={ImageSp6} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_88feli", ImageSp7)}><img src={ImageSp7} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_89black", ImageSp8)}><img src={ImageSp8} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_89sica", imageSp9)}><img src={imageSp9} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_90adv", imageSp10)}><img src={imageSp10} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_90cuarry", imageSp11)}><img src={imageSp11} ></img></button>
        </div>
    );
};

export default AMarmure80_95;