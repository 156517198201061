import React, { useEffect, useState, useContext } from 'react';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../../Components/Navbar';
import { AuthContext } from '../../../Context/AuthContext';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes,deleteObject  } from "firebase/storage"
import {  doc, firestore, updateDoc, getDoc } from 'firebase/firestore';
import { db2,storage,auth } from '../../../firebase-config';
import { onAuthStateChanged,EmailAuthProvider,getAuth,reauthenticateWithCredential } from 'firebase/auth';
import {v4} from "uuid";
import '../../../Styles/_setting.css'
import FlagsSelect,{ Cz,
  De,
  Us,
  Es,
  Fr,
  Hk,
  It,
  Pl,
  Tr } from 'react-flags-select';
  import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { useTextHidden } from '../../../Components/TextHiddenContext';
  const CountriePlayer = ({ country }) => {
    switch (country) {
      case "CZ":
        return <Cz />;
      case "DE":
        return <De />;
      case "US":
        return <Us />;
      case "ES":
        return <Es />;
      case "FR":
        return <Fr />;
      case "HK":
        return <Hk />;
      case "IT":
        return <It />;
      case "PL":
        return <Pl />;
      case "TR":
        return <Tr />;
      default:
        return <div>Country not supported</div>;
    }
  };

  const LanguageSelector = () => {
   
    const { t } = useTranslation();
    const [flaglanguage, setFlagLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');

    const changeLanguage = (e) => {
      const selectedLanguage = e.target.value;
      i18n.changeLanguage(selectedLanguage);
      setFlagLanguage(selectedLanguage);
      localStorage.setItem('selectedLanguage', selectedLanguage);
    };
  
    useEffect(() => {
      // Set the initial language on component mount
      i18n.changeLanguage(flaglanguage);
    }, [flaglanguage]);
  
    return (
      <div className='language_web'>
        <p>Languague WebSite : </p>
        <div className='flag_language'>
          {flaglanguage === 'fr' ? (
            <Fr />
          ) : flaglanguage === 'en' ? (
            <Us />
          ) : flaglanguage === 'de' ? (
            <De />
          ) : flaglanguage === 'es' ? (
            <Es />
          ) : flaglanguage === 'it' ? (
            <It />
          ) : flaglanguage === 'pl' ? (
            <Pl />
          ) : flaglanguage === 'hk' ? (
            <Hk />
          ) : flaglanguage === 'tr' ? (
            <Tr />
          ) : (
            <></>
          )}
        </div>
        <select onChange={changeLanguage} defaultValue={flaglanguage}>
          <option value='fr'>Français</option>
          <option value='en'>English</option>
          <option value='de'>German</option>
          <option value='es'>Spanish</option>
          <option value='it'>Italian</option>
          <option value='pl'>Polski</option>
          <option value='hk'>Cantonese</option>
          <option value='tr'>Türkçe</option>
        </select>
      </div>
    );
  };
  
  
const PrivateHome = () => {
  const { textHidden, toggleText } = useTextHidden();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState('');
  const [uid_form,setUid_from] = useState('')
  const [picture_sp,SetpictureSp] = useState({})
  const [comment, setComment] = useState('');
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState('');
  const [picturesque,setPicturesque]=useState('')
  const [dislikeprofil, setdisLikeProfil] = useState(0);
  const [likeprofil, setLikeProfil] = useState(0);
  const [gifeur,setGifeur] = useState('')
  const [discord, setDiscord] = useState('');
  const [newCountry, setNewCountry] = useState();
  const [showChangeCountry, setShowChangeCountry] = useState(true);
  const [newDiscord, setNewDiscord] = useState('');
  const [showChangeDiscord, setShowChangeDiscord] = useState(true);
  const [newMDP, setNewMDP] = useState('');
  const [showChangeMDP, setShowChangeMDP] = useState(true);
  const [newemail, setNewemail] = useState('');
  const [showChangeemail, setShowChangeemail] = useState(true);
  const [gif_user_url, setGif_user_url] = useState('gif/user_default.gif');
  const [picture_sp_user_url, setPicture_sp_user_url] = useState('');
  const [showmodaladver,setShowModalAdver]= useState(true)
  const uid = currentUser.uid;
  const docRef = doc(db2, "users", uid);
  const [initialComment,setInitialComment]=useState('')
  const [displayName, setDisplayNameState] = useState(null);
  const handlePictureChange = (e) => {
    SetpictureSp(e.target.files[0]);
    setModalOpen(true);
  };
  
  const closeModal = () => {
    setModalOpen(false);
  };

  // ... Your existing code ...
  const navParamtyle = {
    transition: 'margin-left 2s', // 2-second transition for the width property
    marginLeft: textHidden ? '-150px' : '5%',
  };
  const navParamtyle2 = { // 2-second transition for the width property
    left: textHidden ? '230px' : '155px',
  };
  useEffect(() => {
    setUid_from(v4())
    if (currentUser && currentUser.displayName !== null) {
      setDisplayNameState(currentUser.displayName);
    } else {
    }
    const fetchUserCountry = async () => {
      try {
        const uid = currentUser.uid;
        const docRef = doc(db2, 'users', uid);
        const docSnap = await getDoc(docRef);
          const docRef2 = doc(db2, 'score', uid);
          const docSnap2 = await getDoc(docRef2);
        if (docSnap.exists()) {
            const data = docSnap.data();
            const data2 = docSnap2.data();
          const userDiscord = data.Discord || 'undefined';
          const userCountry = data.country || 'US';
          const userComment = data.comment || '';
          const userCrea = data.Creat || '';
          const userPicture = data.pdp_src || '';
          const userGif = data.gif_src || '';
          const likeprofil = (data2.like && data2.like.length) || 0;
          const dislikeprofil = (data2.dislike && data2.dislike.length) || 0;
          setCountry(userCountry);
          setDiscord(userDiscord);
          setComment(userComment);
          setGifeur(userGif);
          setdisLikeProfil(dislikeprofil);
          setLikeProfil(likeprofil);
          setPicturesque(userPicture)
          setInitialComment(userComment)
          const picture_sp_user = await getDownloadURL(ref(storage, userPicture));
          setPicture_sp_user_url(picture_sp_user);
          const gif_user_url = await getDownloadURL(ref(storage, userGif));
          setGif_user_url(gif_user_url);
        }
      } catch (error) {
        
      } finally {
        setLoading(false);
      }
    };

    fetchUserCountry();
  }, [currentUser]);

const handleChangeUserCountry = async () => {
  try {
    const uid = currentUser.uid;
    const docRef = doc(db2, 'users', uid);

    await updateDoc(docRef, { country: newCountry });
    setShowChangeCountry(true);
    setCountry(newCountry);
    toast.success ( t('succ_pays') , {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
  } catch (error) {
    toast.warn (t('arr_pays'), {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
  }
}

  const handleChangeUserDiscord = async () => {
    const selectedValue = newDiscord;

    try {
      const uid = currentUser.uid;
      const docRef = doc(db2, 'users', uid);

      await updateDoc(docRef, { Discord: selectedValue });
      setShowChangeDiscord(true);
      setDiscord(selectedValue);
      toast.success (t('succ_disc'), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    } catch (error) {
      toast.warn (t('arr_disc'), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
  };

  const handleChangeCommentaire = async () => {
    const selectedValue = comment;

    try {
      const uid = currentUser.uid;
      const docRef = doc(db2, 'users', uid);

      await updateDoc(docRef, { comment: selectedValue });
      setComment(selectedValue);
      toast.success (t('succ_com'), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
        setInitialComment(comment)
    } catch (error) {
      toast.warn (t('arr_com'), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
  };

  const uploadImage = async (e) => {
    setUid_from(v4());
    if (picture_sp.type !== "image/png") {    
      toast.error(t('err.png'), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
  
    if (picturesque && picturesque !== 'pdp/user_default.png') {
      deleteObject(ref(storage, picturesque))
        .then(() => {})
        .catch((error) => {});
    }
  
    const docRef = doc(db2, "users", uid);
    const imageRef = ref(storage, `pdp/${picture_sp.name + uid_form}`);
    uploadBytes(imageRef, picture_sp).then(() => {
      toast.success(t('succ.png'), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  
    await updateDoc(docRef, { pdp_src: `pdp/${picture_sp.name + uid_form}` });
    e.target.reset();
  };
  const uploadgif = async (e) => {
    setUid_from(v4());
    const docRef = doc(db2, 'users', uid);
  
    // Vérification de l'extension de fichier
    if (picture_sp.type !== 'image/gif') {
      toast.error(t('err.gif'), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }
  
    if (gifeur !== '' && gifeur !== 'gif/user_default.gif') {
      deleteObject(ref(storage, gifeur)).then(() => {
      }).catch((error) => {
        toast.error(t('err.suppr'), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
  
    const imageRef = ref(storage, `gif/${picture_sp.name + uid_form}`);
    uploadBytes(imageRef, picture_sp).then(() => {
      toast.success(t('succ.gif'), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((error) => {
      toast.error(t('err.suppr'), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    });
  
    await updateDoc(docRef, { gif_src: `gif/${picture_sp.name + uid_form}` });
    e.target.reset();
  }

  const updatePassword = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const currentPassword = prompt("Please enter your current password:");
    
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    user.reauthenticateWithCredential(user.email, currentPassword)
      .then(() => {
        user.updatePassword(newMDP)
          .then(() => {
            
          })
          .catch((error) => {
            
          });
      })
      .catch((error) => {

      });
  };
      return (
        <div>
          <Navbar/>
          {showmodaladver &&( <div className='modal-overlay' onClick={() =>setShowModalAdver(false)}>
            <div className='modal_advert'>
            <span className="material-symbols-outlined">
            priority_high
            </span>
            <p className='1_fonctio'> {t('1_fonctio')}</p>
            <p className='3_fonctio'> {t('3_fonctio')}</p>
            <p className='2_fonctio'> {t('2_fonctio')}</p>
            
            
            </div>
            <div className="clasadvert">
              <span className="material-symbols-outlined close_advertt">
              cancel
              </span>
            </div>
          </div>
          )
          }
          <div className="settings" style={navParamtyle}>
            <div className="top">
              <h1>{t('titl.param')}</h1>
              <div className='Info_player'>
              <div className='img_player'>
            {/* Add the pdp-container class to the div */}
            <div className="pdp-container">
              <img className="real_img_player" src={picture_sp_user_url} alt="Profile Picture" />
              </div>
                  <div className='uploa_pict'>           
                                <input
                                className='custom-file-input'
                      id="file_sell"
                      type="file"
                      accept="image/*"
                      onChange={handlePictureChange}
                    />

                  <button type="button" className='sumbit_imgee' onClick={uploadImage}>Sumbit</button>
                  {modalOpen && (
                        <div className="modal picture_sell">
                          <div className="modal-content">
                                                      
                            <img src={URL.createObjectURL(picture_sp)} alt="Selected" />
                            <span className="material-symbols-outlined close_picture" onClick={closeModal}>close</span>
                          </div>
                        </div>
                      )}
                </div>
                </div>
                <div className='data_player'>
                    <div className='pseudl'>
                      <a>{t('pseu')} : {currentUser.displayName ? currentUser.displayName : "Uknow"}</a>
                    </div>
                  {showChangeCountry ? (                 
                  <div className='countr'>
                    <p className='countr_use'>{t('Countrie')} :  
                    <div className="picture_countr">
                      {loading ? (
                        <div className="picture_countr">Loading...</div>
                      ) : (
                        <CountriePlayer country={country} className="picture_countr" />
                      )}
                    </div> 
                  </p>
                  <button
                  className="modify"
                  id="change-value-button"
                  onClick={() => setShowChangeCountry(!showChangeCountry)}
                  >
                  <span className="material-symbols-outlined param__set">settings</span>
                  </button> 
                  </div>      
                  ) : (  
                    
                <div className="pseudl">
                <FlagsSelect
                  countries={["CZ", "DE", "US", "ES", "FR", "HK","IT", "PL" , "TR"]}
                  searchable
                  selected={country}
                  onSelect={(code) => {
                  setNewCountry(code);
                  handleChangeUserCountry();
                }}
              />
                  <button
                      className="Canc"
                      id="change-Country-Canc"
                      onClick={() => setShowChangeCountry(!showChangeCountry)}
                  >
                    <span className="material-symbols-outlined">close</span>
                  </button>
                </div>
                    )}    
                    
              </div>
              {showChangeDiscord ? (
              <div className='discrd'>
                  <button
                  className="modify"
                  id="change-value-button"
                  onClick={() => setShowChangeDiscord(!showChangeDiscord)}
                  >
                    <span className="material-symbols-outlined param__set">settings</span>
                  </button>
                
              <p>Discord : {discord ? discord : "Undifined"}</p>
              </div>
              ) : ( 
                <div className="pseudl">
                  <input 
                    type="text" 
                    id="change-Discord-input" 
                    value={newDiscord}
                    onChange={(e) => setNewDiscord(e.target.value)}
                  />
                  <button
                    className="Accept"
                    id="change-Discord-Accept"
                    onClick={() => {
                      setShowChangeDiscord(!showChangeDiscord);
                      // save the selected country in the div
                      handleChangeUserDiscord();}}
                  >
                    <span className="material-symbols-outlined">done_all</span>
                  </button>
                  <button
                      className="Canc"
                      id="change-Discord-Canc"
                      onClick={() => setShowChangeDiscord(!showChangeDiscord)}
                  >
                    <span className="material-symbols-outlined">close</span>
                  </button>
                </div>
                    )}

                                  <LanguageSelector/>
              </div>
             
              <div className='Commantère'>
                <input
                  className="Com_player"
                  type="text"
                  placeholder='Commentaire'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                 {comment !== '' && comment !== initialComment ?
        <button type='button' onClick={handleChangeCommentaire}>
          <span className="material-symbols-outlined">
            done_all
          </span>
        </button>
        : <></>
      }
      <div className='gif_note'>                     
                                                    <div className='Lik'>
                                                    <div class="like-dislike-container">
                                                          <div class="tool-box">
                                                            <button class="btn-close">×</button>
                                                          </div>				
                                                          <h2>Scores :</h2>
                                                          <div class="icons-box">
                                                            <div class="icons">
                                                              <label class="btn-label" for="like-checkbox">
                                                                <span class="like-text-content">{likeprofil || 0}</span>
                                                                <div class="input-box" id="like-checkbox"/>
                                                                <svg class="svgs" id="icon-like-solid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"></path></svg>
                                                                <svg class="svgs" id="icon-like-regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.1s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z"></path></svg>
                                                                <div class="fireworks">
                                                                  <div class="checked-like-fx"></div>
                                                                </div>
                                                              </label>
                                                            </div>
                                                            <div class="icons">
                                                              <label class="btn-label" for="dislike-checkbox">
                                                                <div class="input-box" id="dislike-checkbox" />
                                                                <div class="fireworks">
                                                                  <div class="checked-dislike-fx"></div>
                                                                </div>
                                                                <svg class="svgs" id="icon-dislike-solid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"></path></svg>
                                                                <svg class="svgs" id="icon-dislike-regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.1s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z"></path></svg>
                                                                <span class="dislike-text-content">{dislikeprofil || 0}</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                    </div>
                                                  
                <div className='gif_player'>
                  <img className="real_gif_player"src={gif_user_url}></img>
                  <div className='uploa_gif'>
                  <input className='inpu_gif_player custom-file-input' id="file_sell" type="file" accept="image/gif" onChange={(e) => SetpictureSp(e.target.files[0])}/>
                  
                  <button type="button" className='sumbit_gif_player' onClick={uploadgif}>Sumbit</button>
                </div>
                </div>
                </div>
              </div>

              <div className='gradientus'></div>
            </div>
            <div className='utilisateur_param'>
            <div className='email_user'>
                  <p>Email : {currentUser.displayName ? currentUser.email : "Uknow"}</p>
                  
                                        </div>
              <div className='email_mdp'>
              {showChangeMDP ? (
                <>
              <button
                  className="modify"
                  id="change-value-button"
                  onClick={() => setShowChangeMDP(!showChangeMDP)}
                  >
                    <span className="material-symbols-outlined param_set">settings</span>
                  </button>
                  <p>MDP : *********</p>
                  </>
                ) : ( 
                <div className="pseudl">
                  <input 
                    type="text" 
                    id="change-MDP-input" 
                    value={newMDP}
                    onChange={(e) => setNewMDP(e.target.value)}
                  />
                  <button
                    className="Accept"
                    id="change-MDP-Accept"
                    onClick={updatePassword}
                  >
                    <span className="material-symbols-outlined">done_all</span>
                  </button>
                  <button
                      className="Canc"
                      id="change-MDP-Canc"
                      onClick={() => setShowChangeMDP(!showChangeMDP)}
                  >
                    <span className="material-symbols-outlined">close</span>
                  </button>
                </div>
                    )}
             </div>
             
            </div>
            <ToastContainer />
          </div>
        </div>
        
  );
};

export default PrivateHome;