
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import SpArcher from './tri_Baz_esti/SP/SpArcher';

import SpEscri from './tri_Baz_esti/SP/SpEscri';
import SpMage from './tri_Baz_esti/SP/SpMage';
import SpAutre from './tri_Baz_esti/SP/SpAutre';
import SpAM from './tri_Baz_esti/SP/Sp_AM';

import AarmureVente0h_30h from './tri_Baz_esti/Armure_a/AarmureVente0h_30h';
import AarmureVente30h_60h from './tri_Baz_esti/Armure_a/AarmureVente30h_60h';
import AarmureVente60h_80h from './tri_Baz_esti/Armure_a/AarmureVente60h_80h'
import AarmureVente80_95 from './tri_Baz_esti/Armure_a/AarmureVente80_95';

import AMarmureVente0h_30h from './tri_Baz_esti/Armure_Am/AMarmureVente0h_30h';
import AMarmureVente30h_60h from './tri_Baz_esti/Armure_Am/AMarmureVente30h_60h';
import AMarmureVente60h_80h from './tri_Baz_esti/Armure_Am/AMarmureVente60h_80h';
import AMarmureVente80_95 from './tri_Baz_esti/Armure_Am/AMarmureVente80_95';

import EarmureVente0h_30h from './tri_Baz_esti/Armure_e/EarmureVente0h_30h';
import EarmureVente30h_60h from './tri_Baz_esti/Armure_e/EarmureVente30h_60h';
import EarmureVente60h_80h from './tri_Baz_esti/Armure_e/EarmureVente60h_80h';
import EarmureVente80_95 from './tri_Baz_esti/Armure_e/EarmureVente80_95';

import MarmureVente0h_30h from './tri_Baz_esti/Armure_m/ArmureMVente0h_30h';
import MarmureVente30h_60h from './tri_Baz_esti/Armure_m/ArmureMVente30h_60h';
import MarmureVente60h_80h from './tri_Baz_esti/Armure_m/ArmureMVente60h_80h';
import MarmureVente80_95 from './tri_Baz_esti/Armure_m/ArmureMVente80_95';

import AVente80_95 from './tri_Baz_esti/Arme_a/AVente80_95';
import AVente0h_30h from './tri_Baz_esti/Arme_a/AVente0h_30h';
import AVente30h_60h from './tri_Baz_esti/Arme_a/AVente30h_60h';
import AVente60h_80h from './tri_Baz_esti/Arme_a/AVente60h_80h';

import MVente80_95 from './tri_Baz_esti/Arme_m/MVente80_95';
import MVente0h_30h from './tri_Baz_esti/Arme_m/MVente0h_30h';
import MVente30h_60h from './tri_Baz_esti/Arme_m/MVente30h_60h';
import MVente60_80h from './tri_Baz_esti/Arme_m/MVente60h_80h';

import AmVente0h_30h from './tri_Baz_esti/Arme_Am/AmVente0h_30h';
import AmVente30h_60h from './tri_Baz_esti/Arme_Am/AmVente30h_60h';
import AmVente80_95 from './tri_Baz_esti/Arme_Am/AmVente80_95';
import AmVente60h_80h from './tri_Baz_esti/Arme_Am/AmVente60h_80h';

import EVente80_95 from './tri_Baz_esti/Arme_e/EVente80_95';
import EVente0h_30h from './tri_Baz_esti/Arme_e/EVente0h_30h';
import EVente30h_60h from './tri_Baz_esti/Arme_e/EVente30h_60h';
import EhVente60_80 from './tri_Baz_esti/Arme_e/EVente60h_80h';

import Gants_vente0h_30h from './tri_Baz_esti/res/Gant/Gants_vente0h_30h';
import Grant80_95 from './tri_Baz_esti/res/Gant/Grant80_95';
import Grant30h_60h from './tri_Baz_esti/res/Gant/Grant30h_60h';
import Grant60h_80h from './tri_Baz_esti/res/Gant/Grant60h_80h';

import Shoes0h_30h from './tri_Baz_esti/res/shoes/Shoes0h_30h';
import ShoesVente30h_60h from './tri_Baz_esti/res/shoes/ShoesVente30h_60h';
import Shoes60h_80h from './tri_Baz_esti/res/shoes/Shoes60h_80h';
import Shoes80_95 from './tri_Baz_esti/res/shoes/Shoes80_95';

import CacVente from './tri_Baz_esti/Partennaire/CacVente';
import MagieVente from './tri_Baz_esti/Partennaire/MagieVente';
import DistanceVente from './tri_Baz_esti/Partennaire/DistanceVente';

import AnneauVente80_95 from './tri_Baz_esti/Accesoire/Anneau/AnneauVente80_95';
import AnneauVente0_30h from './tri_Baz_esti/Accesoire/Anneau/AnneauVente0_30h';
import AnneauVente30h_60h from './tri_Baz_esti/Accesoire/Anneau/AnneauVente30h_60h';
import AnneauVente60h_80h from './tri_Baz_esti/Accesoire/Anneau/AnneauVente60h_80h';

import CollierVente80_95 from './tri_Baz_esti/Accesoire/Collier/CollierVente80_95';
import CollierVente0_30h from './tri_Baz_esti/Accesoire/Collier/CollierVente0_30h';
import CollierVente30h_60h from './tri_Baz_esti/Accesoire/Collier/CollierVente30h_60h';
import CollierVente60h_80h from './tri_Baz_esti/Accesoire/Collier/CollierVente60h_80h';

import BraceletVente0_30h from './tri_Baz_esti/Accesoire/Bracelet/BraceletVente0_30h'
import BraceletVente80_95 from './tri_Baz_esti/Accesoire/Bracelet/BraceletVente80_95';
import BraceletVente30h_60h from './tri_Baz_esti/Accesoire/Bracelet/BraceletVente30h_60h'
import BraceletVente60h_80h from './tri_Baz_esti/Accesoire/Bracelet/BraceletVente60h_80h';

import Tableaubazar from './Tableaubazar';
import AnnonceBazar from './AnnonceBazar';
import Sell_item from './Sell_itemSP';
import imagevente1 from "../photo_anex/test2.jpg";
import capture from "../photo_anex/capturax.png";
import imagevente2 from "../photo_anex/test3.png";
import imagevente3 from "../photo_anex/sp__test.jpg"
import fleche from "../photo_anex/testfleche.gif"
import Navbar from './Navbar';
import { useTextHidden } from './TextHiddenContext';
import { useTranslation } from 'react-i18next';
const   Estimate_Sell = () => {
  const { textHidden, toggleText } = useTextHidden();
  const { t } = useTranslation();
  const mode = 'esti';
  const [serveur,setServeur]= useState('');
  const [selectedCat2, setSelectedCat2] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [otherFieldsDisabled, setOtherFieldsDisabled] = useState(true);

    const [classeDisabled, setClasseDisabled] = useState(true);
    const [lvlDisabled, setLvlDisabled] = useState(true);
    const [classSpDisabled, setClassSpDisabled] = useState(true);
    const [upSPDisabled, setUpSPDisabled] = useState(true);
    const [amelioSPDisabled, setAmelioSPDisabled] = useState(true);
    const [resDisabled, setResDisabled] = useState(true);
    const [rarityDisabled, setRarityDisabled] = useState(true);
    const [uparmDisabled, setUparmDisabled] = useState(true);
    const [accesoireDisabled, setAccesoireDisabled] = useState(true);
    const [isDivEntespVisible, setIsDivEntespVisible] = useState(false);

    useEffect(() => {
      const BienVendreDiv = document.querySelector('.BienVendre');
      const imagfleiche = document.querySelector('.imagfleiche');
      const imagfleche = document.querySelector('.imagfleche');
      const down_capture = document.querySelector('.down_capture');
      const footerus = document.querySelector('.footerus');
      
      if (BienVendreDiv && isDivEntespVisible) {
        BienVendreDiv.style.marginTop = '810px';
        imagfleiche.style.top ='1234px' ;
        imagfleche.style.top ='1187px' ;
        down_capture.style.top ='1254px' ;
        footerus.style.bottom='-85%';
        footerus.style.position='absolute';
      } else if (BienVendreDiv) {
        BienVendreDiv.style.marginTop = '110px';
        imagfleiche.style.top ='533px' ;
        imagfleche.style.top ='489px' ;
        down_capture.style.top ='555px' ;
      }
    }, [isDivEntespVisible]);
    const repotyle = {
      transition: 'margin-left 2s', // 2-second transition for the width property
      marginLeft: textHidden ? '0px' : '125px',
    };
    const repotyle1 = {
      transition: 'left 2s', // 2-second transition for the width property
      left: textHidden ? '56%' : '60%',
    };
    const repotyle2 = {
      transition: 'left 2s', // 2-second transition for the width property
      left: textHidden ? '42%' : '46%',
    };
    const repotyle3 = {
      transition: 'left 2s', // 2-second transition for the width property
      left: textHidden ? '45%' : '49%',
    };
    const repotyle4= {
      transition: 'margin-left 2s', // 2-second transition for the width property
      marginLeft: textHidden ? '0px' : '140px',
    };
 
    const navStyle3 = { // Adjust '100px' to your desired width
      left: textHidden ? '75px' : '250px',
      transition: 'left 2s',
    };

    const handleSelectChange = (selectedOption) => {
      setSelectedOption(selectedOption);
      if (['Valehir', 'Alzanor', 'Dragonveil', 'Cosmos','Jotunheim'].includes(selectedOption.value)) {
        setOtherFieldsDisabled(false);
        setServeur(selectedOption.value);
      } else {
        setOtherFieldsDisabled(true);
      }
    }
    const [selectedCat, setSelectedCat] = useState(null);
    const handleSelectChange2 = (selectedOption) => {
        setSelectedCat(selectedOption);
        setSelectedOption(selectedOption);
        if (selectedOption.value === 'Arme' ) {
            setClasseDisabled(false);
            setLvlDisabled(false);
            setUpSPDisabled(true);
            setClassSpDisabled(true);
            setRarityDisabled(false);
            setAmelioSPDisabled(true);
            setResDisabled(true);
            setUparmDisabled(false);
            setAccesoireDisabled(true);
        } else if (selectedOption.value === 'Armure' ) {
          setClasseDisabled(false);
          setLvlDisabled(false);
          setUpSPDisabled(true);
          setClassSpDisabled(true);
          setRarityDisabled(false);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(false);
          setAccesoireDisabled(true);
        } else if (selectedOption.value === 'Accesoire' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setUpSPDisabled(true);
          setClassSpDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(false);
        } else if (selectedOption.value === 'Spécialiste' ) {
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(false);
          setUpSPDisabled(false);
          setRarityDisabled(true);
          setAmelioSPDisabled(false);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(true);
        } else if (selectedOption.value === 'Partenaire' ) {
          setClasseDisabled(false);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(true);
        }  else if (selectedOption.value === 'Res' ) {
          setClasseDisabled(true);
          setLvlDisabled(false);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(false);
          setUparmDisabled(true);
          setAccesoireDisabled(true); 
        }  else{ 
          setClasseDisabled(true);
          setLvlDisabled(true);
          setClassSpDisabled(true);
          setUpSPDisabled(true);
          setRarityDisabled(true);
          setAmelioSPDisabled(true);
          setResDisabled(true);
          setUparmDisabled(true);
          setAccesoireDisabled(true);
        }
      }

    const Serveur = [
        { value: 'Valehir', label: 'Valehir' },
        { value: 'Alzanor', label: 'Alzanor' },
        { value: 'Dragonveil', label: 'Dragonveil' },
        { value: 'Cosmos', label: 'Cosmos' },
        { value: 'Jotunheim', label: 'Jotunheim' }
      ];


      const Categorie = [
        { value: 'Arme', label: t('Arme') },
        { value: 'Armure', label: t('Armure') },
        { value: 'Accesoire', label: t('Accesoire') },
        { value: 'Spécialiste', label: t('Spécialiste') },
        { value: 'Partenaire', label: t('Partenaire') },
        { value: 'Res', label: t('Res') },
      ];

      const Classe = [
        { value: 'Archer', label: t('Archer') },
        { value: 'Escri', label: t('Escri') },
        { value: 'Mage', label: t('Mage') },
        { value: 'Artiste-Martial', label: t('Artiste-Martial') }
      ];

      const Lvl = [
        { value: '80_95', label: '80-95' },
        { value: '0h_30h', label: '0h-30h' },
        { value: '31h_60h', label: '31h-60h' },
        { value: '61h_80h', label: '61h-90h' }
      ];


      const Classe_sp = [
        { value: 'Archer', label: t('Archer') },
        { value: 'Escri', label: t('Escri') },
        { value: 'Mage', label: t('Mage') },
        { value: 'Artiste-Martial', label:t('Artiste-Martial') },
        { value: 'Autre', label: t('Other') }
      ];
      const Up_sp = [
        { value: '0', label: '0' },
        { value: '+1/+5', label: '+1/+5' },
        { value: '+6/+9', label: '+6/+9' },
        { value: '+10', label: '+10' },
        { value: '+11', label: '+11' },
        { value: '+12', label: '+12' },
        { value: '+13', label: '+13' },
        { value: '+14', label: '+14' },
        { value: '+15', label: '+15' },
        { value: '+16', label: '+16' },
        { value: '+17', label: '+17' },
        { value: '+18', label: '+18' },
        { value: '+19', label: '+19' },
        { value: '+20', label: '+20' }
      ];

      const Amelio_sp = [
        { value: '0/20', label: '0/20' },
        { value: '21/40', label: '21/40' },
        { value: '41/60', label: '41/60' },
        { value: '61/80', label: '61/80' },
        { value: '81/90', label: '81/90' },
        { value: '91/100', label: '91/100' }
      ];

      const res = [
        { value: 'gants', label: t('gants') },
        { value: 'chaussure', label: t('chaussure') }
      ];
      const handleSelectChange3 = (selectedOption) => {
        setSelectedCat2(selectedOption);
          if (selectedOption.value === 'Anneau' ) {
              setClasseDisabled(true);
              setLvlDisabled(false);
              setUpSPDisabled(true);
              setClassSpDisabled(true);
              setRarityDisabled(true);
              setAmelioSPDisabled(true);
              setResDisabled(true);
              setUparmDisabled(true);
              setAccesoireDisabled(false);
          } else if (selectedOption.value === 'Bracelet' ) {
            setClasseDisabled(true);
            setLvlDisabled(false);
            setUpSPDisabled(true);
            setClassSpDisabled(true);
            setRarityDisabled(true);
            setAmelioSPDisabled(true);
            setResDisabled(true);
            setUparmDisabled(true);
            setAccesoireDisabled(false);
          }else if (selectedOption.value === 'Collier' ) {
            setClasseDisabled(true);
            setLvlDisabled(false);
            setClassSpDisabled(true);
            setUpSPDisabled(true);
            setRarityDisabled(true);
            setAmelioSPDisabled(true);
            setResDisabled(true);
            setUparmDisabled(true);
            setAccesoireDisabled(false);
          } else{
            setClasseDisabled(true);
            setLvlDisabled(true);
            setClassSpDisabled(true);
            setUpSPDisabled(true);
            setRarityDisabled(true);
            setAmelioSPDisabled(true);
            setResDisabled(true);
            setUparmDisabled(true);
            setAccesoireDisabled(false); 
          }
        }
      const rarity = [
        { value: 'r-2', label: 'r-2' },
        { value: 'r-1', label: 'r-1' },
        { value: 'r0', label: 'r0' },
        { value: 'r1', label: 'r1' },
        { value: 'r2', label: 'r2' },
        { value: 'r3', label: 'r3' },
        { value: 'r4', label: 'r4' },
        { value: 'r5', label: 'r5' },
        { value: 'r6', label: 'r6' },
        { value: 'r7', label: 'r7' },
        { value: 'r8', label: 'r8' }
      ]

      const up_arm = [
        { value: '0', label: '+0' },
        { value: '+1', label: '+1' },
        { value: '+2', label: '+2' },
        { value: '+3', label: '+3' },
        { value: '+4', label: '+4' },
        { value: '+5', label: '+5' },
        { value: '+6', label: '+6' },
        { value: '+7', label: '+7' },
        { value: '+8', label: '+8' },
        { value: '+9', label: '+9' },
        { value: '+10', label: '+10' }
        
      ]

      
      const Accesoire = [
        { value: 'Anneau', label: t('Anneau')},
        { value: 'Bracelet', label: t('Bracelet') },
        { value: 'Collier', label: t('Collier') },
        
      ]

      const [selectedClasse, setSelectedClasse] = useState(null);
      const [selectedLvl, setSelectedLvl] = useState(null);
      const [selectedClassSp, setSelectedClassSp] = useState(null);
      const [selectedUpSp, setSelectedUpSp] = useState(null);
      const [selectedAmelioSp, setSelectedAmelioSp] = useState(null);
      const [selectedRes, setSelectedRes] = useState(null);
      const [selectedRarity, setSelectedRarity] = useState(null);
      const [selectedUpArm, setSelectedUpArm] = useState(null);
      const [selectedAcces, setSelectedAcces] = useState(null);

      const customStyles = {
        container: (provided) => ({
          ...provided,
          width: '150px',
          fontSize: '12px',
          position: 'relative',
          cursor: 'pointer',
          display: 'inline-block' // add this to align with the original .list-choice
        }),
        control: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          color: '#FFF',
          textShadow: '0 1px 0 rgba(144, 142, 142, 0.66)',
          borderRadius: '0.2em',
          border: 'none',
          cursor: 'pointer',
          display: 'flex', // center selected value
          justifyContent: 'center', // center selected value
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          borderRadius: '0.2em',
          overflow: 'hidden'
        }),
        option: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          color: '#FFF',
          cursor: 'pointer'
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#FFF',
          background: 'rgb(116,214,142)',
          background: 'linear-gradient(102deg, rgba(116,214,142,1) 0%, rgba(116,214,142,0.04525560224089631) 59%)',
          height: '39px',
          borderRadius: '2.4px 6px 6px 2.4px',
          marginLeft:'-8px',
          width: '113px', // fill width of control
          display: 'flex',
          alignItems: 'center',
          marginTop: '-1.8px',
          marginBottom: '-2px',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#FFF'
        })
      };
    return (  
      <div>
        <Navbar/>
        <div className='Bazar'>
          <div className='Bazar'> 
            <h1 style={repotyle3} className='esti'>Estimation</h1>
            <form className='info_jeux' style={repotyle4}>

            <Select styles={customStyles} options={Serveur} placeholder="Serveur" onChange={handleSelectChange} />
                      {!otherFieldsDisabled && <Select styles={customStyles} options={Categorie} placeholder={t('Catego')} disabled={otherFieldsDisabled} onChange={handleSelectChange2} />}  
                      {!classeDisabled && <Select styles={customStyles} options={Classe} placeholder={t('Classe')} disabled={classeDisabled} onChange={(selectedOption) => setSelectedClasse(selectedOption)}/>}
                      {!resDisabled && <Select styles={customStyles} options={res} placeholder={t('Resi')} disabled={resDisabled} onChange={(selectedOption) => setSelectedRes(selectedOption)}/>}
                      {!lvlDisabled && <Select styles={customStyles} options={Lvl} placeholder={t('LVL')} disabled={lvlDisabled} onChange={(selectedOption) => setSelectedLvl(selectedOption)}/>}
                      {!classSpDisabled && <Select styles={customStyles} options={Classe_sp} placeholder={t('Classe')} disabled={classSpDisabled} onChange={(selectedOption) => setSelectedClassSp(selectedOption)}/>}
                      {!accesoireDisabled && <Select styles={customStyles} options={Accesoire} placeholder={t('Accesoire')} disabled={accesoireDisabled} onChange={handleSelectChange3}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '80_95' && 
                      <EVente80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <EVente0h_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <EVente30h_60h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <EhVente60_80 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                    
                    {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <AarmureVente0h_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '31h_60h' && 
                                        <AarmureVente30h_60h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '61h_80h' && 
                                        <AarmureVente60h_80h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '80_95' && 
                                        <AarmureVente80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '0h_30h' && 
                                        <AMarmureVente0h_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '31h_60h' && 
                                        <AMarmureVente30h_60h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                                                          {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '61h_80h' && 
                                        <AMarmureVente60h_80h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '80_95' && 
                                        <AMarmureVente80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '0h_30h' && 
                                        <EarmureVente0h_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '31h_60h' && 
                                        <EarmureVente30h_60h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '61h_80h' && 
                                        <EarmureVente60h_80h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '80_95' && 
                                        <MarmureVente80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '0h_30h' && 
                                        <MarmureVente0h_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '31h_60h' && 
                                        <MarmureVente30h_60h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                                                          {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '61h_80h' && 
                                        <MarmureVente60h_80h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                  {selectedCat && selectedCat.value === 'Armure' && selectedClasse && selectedClasse.value === 'Escri' && selectedLvl && selectedLvl.value === '80_95' && 
                                        <EarmureVente80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '80_95' && 
                      <AVente80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <AVente0h_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <AVente30h_60h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Archer' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <AVente60h_80h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '80_95' && 
                      <MVente80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <MVente0h_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <MVente30h_60h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Mage' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <MVente60_80h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '80_95' && 
                      <AmVente80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <AmVente0h_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '31h_60h' && 
                      <AmVente30h_60h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Arme' && selectedClasse && selectedClasse.value === 'Artiste-Martial' && selectedLvl && selectedLvl.value === '61h_80h' && 
                      <AmVente60h_80h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}


                    {selectedCat && selectedCat.value === 'Partenaire' && selectedClasse && selectedClasse.value === 'Escri' && 
                      <CacVente setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                    {selectedCat && selectedCat.value === 'Partenaire' && selectedClasse && selectedClasse.value === 'Mage' && 
                      <MagieVente setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                                      {selectedCat && selectedCat.value === 'Partenaire' && selectedClasse && selectedClasse.value === 'Archer' && 
                      <DistanceVente setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}


                  {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'gants' && selectedLvl && selectedLvl.value === '80_95' && 
                        <Grant80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'gants' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <Gants_vente0h_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'gants' && selectedLvl && selectedLvl.value === '61h_80h' && 
                        <Grant30h_60h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'gants' && selectedLvl && selectedLvl.value === '31h_60h' && 
                        <Grant60h_80h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                  {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'chaussure' && selectedLvl && selectedLvl.value === '0h_30h' && 
                      <Shoes0h_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'chaussure' && selectedLvl && selectedLvl.value === '31h_60h' && 
                        <ShoesVente30h_60h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'chaussure' && selectedLvl && selectedLvl.value === '61h_80h' && 
                        <Shoes60h_80h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
              {selectedCat && selectedCat.value === 'Res' && selectedRes && selectedRes.value === 'chaussure' && selectedLvl && selectedLvl.value === '80_95' && 
                        <Shoes80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  

                   {selectedCat && selectedCat.value === 'Spécialiste' && selectedClassSp && selectedClassSp.value === 'Archer' &&
                      <SpArcher setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Spécialiste' && selectedClassSp && selectedClassSp.value === 'Escri' &&
                      <SpEscri setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                    {selectedCat && selectedCat.value === 'Spécialiste' && selectedClassSp && selectedClassSp.value === 'Mage' &&
                      <SpMage setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

                    {selectedCat && selectedCat.value === 'Spécialiste' && selectedClassSp && selectedClassSp.value === 'Artiste-Martial' && 
                      <SpAM setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                    {selectedCat && selectedCat.value === 'Spécialiste' && selectedClassSp && selectedClassSp.value === 'Autre' && 
                      <SpAutre setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

            {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Anneau' && selectedLvl && selectedLvl.value === '80_95' && 
                                    <AnneauVente80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
            {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Anneau' && selectedLvl && selectedLvl.value === '0h_30h' && 
                                    <AnneauVente0_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
            {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Anneau' && selectedLvl && selectedLvl.value === '31h_60h' && 
                                    <AnneauVente30h_60h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
            {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Anneau' && selectedLvl && selectedLvl.value === '61h_80h' && 
                                    <AnneauVente60h_80h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}


                  {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Bracelet' && selectedLvl && selectedLvl.value === '0h_30h' && 
                                                      <BraceletVente0_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Bracelet' && selectedLvl && selectedLvl.value === '80_95' && 
                                                      <BraceletVente80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Bracelet' && selectedLvl && selectedLvl.value === '31h_60h' && 
                                                      <BraceletVente30h_60h  setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                  {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Bracelet' && selectedLvl && selectedLvl.value === '61h_80h' && 
                                                    <BraceletVente60h_80h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}

{selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Collier' && selectedLvl && selectedLvl.value === '80_95' && 
                                                                      <CollierVente80_95 setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Collier' && selectedLvl && selectedLvl.value === '61h_80h' && 
                                                                      <CollierVente60h_80h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Collier' && selectedLvl && selectedLvl.value === '31h_60h' && 
                                                                      <CollierVente30h_60h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                {selectedCat && selectedCat.value === 'Accesoire' && selectedCat2 && selectedCat2.value === 'Collier' && selectedLvl && selectedLvl.value === '0h_30h' && 
                                                                      <CollierVente0_30h setIsDivEntespVisible={setIsDivEntespVisible} serveur={serveur} navStyle3={navStyle3} mode={mode}/>}
                </form>
                
              <div className='BienVendre' style={repotyle}>
                <h3 className='tit_ve'>{t('esti1')}</h3>
                <ul className='ul_vete'>
                  <li className='li_vente'>
                    <span className="material-symbols-outlined">
                    counter_1
                    </span> 
                    <p>{t('esti2')}</p>
                  </li>

                  <li>
                    <img src={imagevente1} className='imag1_vente'/>
                  </li>
                  <li className='li_vente'>
                    <span className="material-symbols-outlined">
                    counter_2
                    </span>
                    <p>{t('esti3')}</p>
                  </li>
                  <li>
                  <img src={imagevente2} className='imag2_vente'/>
                  
                  <img src={fleche} className='imagfleche'  style={repotyle2}/>
                  <img src={capture} className='imag4_vente' />  
                  <a href="https://www.microsoft.com/store/productId/9MZ95KL8MR0L" className='down_capture'>Donwload Capture</a>
                  <img src={fleche} className='imagfleiche' style={repotyle1}/>
                  <img src={imagevente3} className='imag3_vente'/>

                    </li>
                </ul>
              </div>
             
                
            </div>
        </div>
        <div className="footerus">
                  <p>{t('apropos')}</p>
                  <p>facebook : <a className='fb_link' href='https://www.facebook.com/people/BazNos/100095523103918/'>BazNos FB</a></p>
        <p>{t('reserv')}</p>
        <p>{t('©')}</p>
    </div>
        </div>
    );
};



export default Estimate_Sell;

