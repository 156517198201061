import React ,{useState} from 'react';
import imageSp1 from "../../../nostale/Accesoire/fée/elkeim.png"
import imageSp2 from "../../../nostale/Accesoire/fée/ladine.png"
import imageSp3 from "../../../nostale/Accesoire/fée/rumail.png"
import imageSp4 from "../../../nostale/Accesoire/fée/varik.png"
import imageSp5 from "../../../nostale/Accesoire/fée/sellaim.png"
import imageSp6 from "../../../nostale/Accesoire/fée/tuik.png"
import imageSp7 from "../../../nostale/Accesoire/fée/woondine.png"
import imageSp8 from "../../../nostale/Accesoire/fée/eperial.png"
import imageSp9 from "../../../nostale/Accesoire/fée/zenas_eau.png"
import imageSp10 from "../../../nostale/Accesoire/fée/zenas_feu.png"
import imageSp11 from "../../../nostale/Accesoire/fée/zenas_obscu.png"
import imageSp12 from "../../../nostale/Accesoire/fée/zenas_lulu.png"
import imageSp13 from "../../../nostale/Accesoire/fée/fernon_eau.png"
import imageSp14 from "../../../nostale/Accesoire/fée/fernon_feu.png"
import imageSp15 from "../../../nostale/Accesoire/fée/fernon_obscu.png"
import imageSp16 from "../../../nostale/Accesoire/fée/fernon_lulu.png"
import imageSp17 from "../../../nostale/Accesoire/fée/erenia_feu.png"
import imageSp18 from "../../../nostale/Accesoire/fée/erenia_eau.png"
import imageSp19 from "../../../nostale/Accesoire/fée/erenia_obscu.png"
import imageSp20 from "../../../nostale/Accesoire/fée/erenia_lumiere.png"
import imageSp21 from "../../../nostale/Accesoire/fée/vap_eau.png"
import imageSp22 from "../../../nostale/Accesoire/fée/vap_feu.png"
import imageSp23 from "../../../nostale/Accesoire/fée/vap_lulu.png"
import imageSp24 from "../../../nostale/Accesoire/fée/vap_obscu.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Fairy = (props) => {
      const { textHidden, toggleText } = useTextHidden();
  const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
<div className='propose-ritem-group'>
            <div className='propose-ritem propose-item'style={navStyle3}>
            <button type='button'  onClick={() => handleClick( "elkeim", imageSp1)}><img src={imageSp1}></img></button>
            <button type='button'  onClick={() => handleClick( "ladine", imageSp2)}><img src={imageSp2}></img></button>
            <button type='button'  onClick={() => handleClick( "rumail", imageSp3)}><img src={imageSp3}></img></button>
            <button type='button'  onClick={() => handleClick( "varik", imageSp4)}><img src={imageSp4}></img></button>
            <button type='button'  onClick={() => handleClick( "sellaim", imageSp5)}><img src={imageSp5}></img></button>
            <button type='button'  onClick={() => handleClick( "tuik", imageSp6)}><img src={imageSp6}></img></button>
            <button type='button'  onClick={() => handleClick( "woondine", imageSp7)}><img src={imageSp7}></img></button>
            <button type='button'  onClick={() => handleClick( "eperial", imageSp8)}><img src={imageSp8}></img></button>
            <button type='button'  onClick={() => handleClick( "zenas_eau", imageSp9)}><img src={imageSp9}></img></button>
            <button type='button'  onClick={() => handleClick( "zenas_feu", imageSp10)}><img src={imageSp10}></img></button>
            <button type='button'  onClick={() => handleClick( "zenas_obscu", imageSp11)}><img src={imageSp11}></img></button>
            <button type='button'  onClick={() => handleClick( "zenas_lulu", imageSp12)}><img src={imageSp12}></img></button>
            <button type='button'  onClick={() => handleClick( "fernon_eau", imageSp13)}><img src={imageSp13}></img></button>
            <button type='button'  onClick={() => handleClick( "fernon_feu", imageSp14)}><img src={imageSp14}></img></button>
            <button type='button'  onClick={() => handleClick( "fernon_obscu", imageSp15)}><img src={imageSp15}></img></button>
            </div>
            <div className='propose-item item2 propose-ritem' style={navStyle3}>

            <button type='button'  onClick={() => handleClick( "fernon_lulu", imageSp16)}><img src={imageSp16}></img></button>
            <button type='button'  onClick={() => handleClick( "erenia_feu", imageSp17)}><img src={imageSp17}></img></button>
            <button type='button'  onClick={() => handleClick( "erenia_eau", imageSp18)}><img src={imageSp18}></img></button>
            <button type='button'  onClick={() => handleClick( "erenia_obscu", imageSp19)}><img src={imageSp19}></img></button>
            <button type='button'  onClick={() => handleClick( "erenia_lumiere", imageSp20)}><img src={imageSp20}></img></button>
            <button type='button'  onClick={() => handleClick( "vap_eau", imageSp21)}><img src={imageSp21}></img></button>
            <button type='button'  onClick={() => handleClick( "vap_feu", imageSp22)}><img src={imageSp22}></img></button>
            <button type='button'  onClick={() => handleClick( "vap_lulu", imageSp23)}><img src={imageSp23}></img></button>
            <button type='button'  onClick={() => handleClick( "vap_obscu", imageSp24)}><img src={imageSp24}></img></button>
            </div>   
        </div>
    );
};

export default Fairy;