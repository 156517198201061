import React,{useState} from 'react';
import imageSp1 from "../../nostale/all_arme/mage/60h-90h/60h.png"
import imageSp2 from "../../nostale/all_arme/mage/60h-90h/60hg.png"
import imageSp3 from "../../nostale/all_arme/mage/60h-90h/65h.png"
import imageSp4 from "../../nostale/all_arme/mage/60h-90h/65hg.png"
import imageSp5 from "../../nostale/all_arme/mage/60h-90h/80h.png"
import imageSp6 from "../../nostale/all_arme/mage/60h-90h/80hg.png"
import imageSp7 from "../../nostale/all_arme/mage/60h-90h/82h.png"
import imageSp8 from "../../nostale/all_arme/mage/60h-90h/83h.png"
import imageSp9 from "../../nostale/all_arme/mage/60h-90h/88h.png"
import imageSp10 from "../../nostale/all_arme/mage/60h-90h/90h.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const M60_80h = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
<div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_60h", imageSp1)}><img src={imageSp1}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_60hg", imageSp2)}><img src={imageSp2}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_65h", imageSp3)}><img src={imageSp3}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_65hg", imageSp4)}><img src={imageSp4}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_80h", imageSp5)}><img src={imageSp5}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_80hg", imageSp6)}><img src={imageSp6}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_81h", imageSp7)}><img src={imageSp7}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_83h", imageSp8)}><img src={imageSp8}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_88h", imageSp9)}><img src={imageSp9}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("baton_90h", imageSp10)}><img src={imageSp10}></img></button>
    </div>
    );
};

export default M60_80h;