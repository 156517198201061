import React ,{useState} from 'react';
import imageSp1 from "../../../nostale/Accesoire/CostumeAiles/bzzz.png"
import imageSp2 from "../../../nostale/Accesoire/CostumeAiles/diable.png"
import imageSp3 from "../../../nostale/Accesoire/CostumeAiles/fée.png"
import imageSp4 from "../../../nostale/Accesoire/CostumeAiles/lanncemort.png"
import imageSp5 from "../../../nostale/Accesoire/CostumeAiles/sorciere.png"
import imageSp6 from "../../../nostale/Accesoire/CostumeAiles/sorcier.png"
import imageSp7 from "../../../nostale/Accesoire/CostumeAiles/tropical.png"
import imageSp8 from "../../../nostale/Accesoire/CostumeAiles/volti.png"
import imageSp9 from "../../../nostale/Accesoire/CostumeAiles/arbor.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Ailes_Cost = (props) => {
      const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
<div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
        <button type='button'  onClick={() => handleClick( "ail_bzzz", imageSp1)}><img src={imageSp1}></img></button>
        <button type='button'  onClick={() => handleClick( "ail_diable", imageSp2)}><img src={imageSp2}></img></button>
        <button type='button'  onClick={() => handleClick( "ail_fée", imageSp3)}><img src={imageSp3}></img></button>
        <button type='button'  onClick={() => handleClick( "ail_lanncemort", imageSp4)}><img src={imageSp4}></img></button>
        <button type='button'  onClick={() => handleClick( "ail_sorciere", imageSp5)}><img src={imageSp5}></img></button>
        <button type='button'  onClick={() => handleClick( "ail_sorcier", imageSp6)}><img src={imageSp6}></img></button>
        <button type='button'  onClick={() => handleClick( "ail_tropical", imageSp7)}><img src={imageSp7}></img></button>
        <button type='button'  onClick={() => handleClick( "ail_volti", imageSp8)}><img src={imageSp8}></img></button>
        <button type='button'  onClick={() => handleClick( "arbor", imageSp9)}><img src={imageSp9}></img></button>
        </div>
    );
};

export default Ailes_Cost;