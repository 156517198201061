import React, { useEffect, useState, useContext } from 'react';
import { ToastContainer,toast } from 'react-toastify';
import {  setDoc,doc, firestore, updateDoc, getDoc,FieldValue,serverTimestamp,increment,where,getDocs,query,collection,arrayUnion   } from 'firebase/firestore';
import { auth,db2,storage } from '../firebase-config';
import { AuthContext } from '../Context/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import sell1 from '../photo_anex/piece.png';
import sell2 from '../photo_anex/money_Bag.png';
import sell3 from '../photo_anex/tas_dollar.png';
import { loadScript } from './utils';
import PaypalCheckoutButton from './PaypalCheckoutButton';
import { useTranslation } from 'react-i18next';
import { useTextHidden } from './TextHiddenContext';
const Page_boutique = () => {
  const { textHidden, toggleText } = useTextHidden();
  const { t } = useTranslation();
  const [inputgift,setInputGift]=useState('')
  const [amount,setAmount]= useState(0);
  const [nospoint, setNosPoint] = useState(0);
  const { currentUser } = useContext(AuthContext);
  const uid = currentUser.uid;
  const navStyle = {
    left: textHidden ? '15%' : '20%',
    transition: 'left 2s',
  };
  const navStyle2 = {
    left: textHidden ? '37%' : '40%',
    transition: 'left 2s',
  };
  const product = {
    description : "NosCoin buying ",
    amount: {
                    currency_code: 'EUR',
                    value: amount
                  }
  }
  const handleSpanClick = () => {
    setNosPoint(5);
    setAmount(3.99)
  };
  const handleSpanClick2 = () => {
setNosPoint(15);
setAmount(8.99)
  };
  const handleSpanClick3 = () => {
setNosPoint(50);
setAmount(15.99)
  };
  const trycode = async () => {
    if (!inputgift) {
      toast.error('Please enter a gift code');
      return;
    }
    try {
      const queryRef = collection(db2, "Code_Free");
      const q = query(queryRef, where("Code", "==", inputgift.toUpperCase()));
      const querySnapshot = await getDocs(q);
  
      // Check if there are any documents that match the query
      if (querySnapshot.size > 0) {
        // Code exists in the "Code_Free" collection
        const giftCodeDoc = querySnapshot.docs[0];
        const giftCodeData = giftCodeDoc.data();
        const noscoinValue = giftCodeData.Noscoin;
  
        // Mettre à jour le champ "Nosdollar" de l'utilisateur connecté avec la valeur de Noscoin
        const userDocRef = doc(db2, "users", uid);
        await updateDoc(userDocRef, {
          Nosdollar: increment(noscoinValue),
        });
  
        // Vérifier si l'UID de l'utilisateur se trouve déjà dans le tableau "UserUse"
        if (!giftCodeData.UserUse || !giftCodeData.UserUse.includes(uid)) {
          // L'UID de l'utilisateur n'est pas encore dans le tableau "UserUse"
          // Mettre à jour le champ "UserUse" du document de code avec l'UID de l'utilisateur
          const codeDocRef = doc(db2, "Code_Free", giftCodeDoc.id);
          await updateDoc(codeDocRef, {
            UserUse: arrayUnion(uid),
            people: increment(-1),
          });
  
          toast.success('Gift code successfully redeemed!');
        } else {
          // L'UID de l'utilisateur est déjà dans le tableau "UserUse"
          toast.error('You have already redeemed this gift code');
        }
      } else {
        toast.error('Invalid gift code');
      }
    } catch (error) {
      console.error('Error checking gift code:', error);
      toast.error('An error occurred while checking the gift code');
    }
  };
  return (
    <div>
      <h1 className="Boutique_title">Boutique</h1>
      <fieldset>
    <div className="Boutiques Item" style={navStyle}>
        <div className="boutique 1_sell">
          <a >
            5<span className="material-symbols-outlined">monetization_on</span>
          </a>
          <p>3.99€</p>
          <img src={sell1} alt="sell1" />
          <div></div>
          <button className="Btn" onClick={handleSpanClick}>
  Pay
  <svg className="svgIcon" viewBox="0 0 576 512"><path d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z"></path></svg>
</button>
          
          {nospoint === 5 ? (
        <div className="paypal-button-container">
          
          <PaypalCheckoutButton product={product} nospoint={nospoint} amount={amount}/>        
        </div>
      ) : (
          <></>
      )}
          <input type="radio" id="3.99" name="paypal_option" className="check_paypal"value="3.99" onClick={() => {setNosPoint(5);setAmount(3.99);}}
             />
          <label for="3.99"></label>

        </div>

        <div className="boutique 2_sell">
          <a>
            15<span className="material-symbols-outlined">monetization_on</span>
          </a>
          <p>8.99€</p>
          <img src={sell2} alt="sell2" />
          <button className="Btn" onClick={handleSpanClick2}>
  Pay
  <svg className="svgIcon" viewBox="0 0 576 512"><path d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z"></path></svg>
</button>
          
          {nospoint === 15 ? (
               <div className="paypal-button-container">
               <PaypalCheckoutButton product={product} nospoint={nospoint} amount={amount} />        
             </div>
      ) : (
          <></>
      )}
          <input type="radio" id="8.99" name="paypal_option" className="check_paypal" value="8.99" onClick={() => {setNosPoint(15);setAmount(8.99);}}
            />
      <label for="8.99"></label>

        </div>

        <div className="boutique 3_sell">
          <a>
            50<span className="material-symbols-outlined">monetization_on</span>
          </a>
          <p>18.99€</p>
          <img src={sell3} alt="sell3" />
          <button className="Btn" onClick={handleSpanClick3}>
  Pay
  <svg className="svgIcon" viewBox="0 0 576 512"><path d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z"></path></svg>
</button>
          
          {nospoint === 50 ? (
                <div className="paypal-button-container">
                <PaypalCheckoutButton product={product}  nospoint={nospoint} amount={amount}/>        
              </div>
      ) : (
          <></>
      )}
          <input type="radio" className="check_paypal"id="15.99" name="paypal_option" value="15.99" onClick={() => {setNosPoint(50);setAmount(15.99);}}
              />
      <label for="15.99"></label>

        </div>
      </div>
      </fieldset>
      <div className='code_point' style={navStyle2}>
        
        <label>
          <div className='all_top_gif'>        
          <button type='button' className='free_gift' onClick={trycode}>
          <span className="material-symbols-outlined">
          done_outline
          </span>
          
          </button> </div>
          <div className="inputBox">
            <input required="" type="text" placeholder="Write here..."          
               value={inputgift}
            onChange={(e) => setInputGift(e.target.value)}/>
            <span>Code Gift :</span>
          </div>

        </label>

      </div>
  <ToastContainer/>

    </div>
    
  );
};

export default Page_boutique;