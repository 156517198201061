import React from 'react';
import imageArmure1 from "../../../nostale/Accesoire/Titre/Mystique.png"
const titre = () => {
    return (
<div className='Arme_archer60_80 propose-item' id="Arme_archer60_80">
<button><img src={imageArmure1}></img></button>
        </div>
    );
};

export default titre;