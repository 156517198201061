import React ,{useState} from 'react';
import imageSp1 from "../../../nostale/Accesoire/Costume_arme/a_givré.png"
import imageSp2 from "../../../nostale/Accesoire/Costume_arme/a_kraken.png"
import imageSp3 from "../../../nostale/Accesoire/Costume_arme/a_steam.png"
import imageSp4 from "../../../nostale/Accesoire/Costume_arme/am_givré.png"
import imageSp5 from "../../../nostale/Accesoire/Costume_arme/am_hommar.png"
import imageSp6 from "../../../nostale/Accesoire/Costume_arme/arc_10.png"
import imageSp7 from "../../../nostale/Accesoire/Costume_arme/arc_orge.png"
import imageSp8 from "../../../nostale/Accesoire/Costume_arme/bois_a.png"
import imageSp9 from "../../../nostale/Accesoire/Costume_arme/bois_e.png"
import imageSp10 from "../../../nostale/Accesoire/Costume_arme/diablotin.png"
import imageSp11 from "../../../nostale/Accesoire/Costume_arme/e_10.png"
import imageSp12 from "../../../nostale/Accesoire/Costume_arme/e_givré.png"
import imageSp13 from "../../../nostale/Accesoire/Costume_arme/a_steam.png"
import imageSp14 from "../../../nostale/Accesoire/Costume_arme/m_10.png"
import imageSp15 from "../../../nostale/Accesoire/Costume_arme/m_corail.png"
import imageSp16 from "../../../nostale/Accesoire/Costume_arme/m_givré.png"
import imageSp17 from "../../../nostale/Accesoire/Costume_arme/m_noel.png"
import imageSp18 from "../../../nostale/Accesoire/Costume_arme/m_steam.png"
import imageSp19 from "../../../nostale/Accesoire/Costume_arme/makaira.png"
import imageSp20 from "../../../nostale/Accesoire/Costume_arme/raquette.png"
import imageSp21 from "../../../nostale/Accesoire/Costume_arme/sapin.png"
import imageSp22 from "../../../nostale/Accesoire/Costume_arme/uchiwa.png"
import imageSp23 from "../../../nostale/Accesoire/Costume_arme/e_laser.png"
import imageSp24 from "../../../nostale/Accesoire/Costume_arme/a_laser.png"
import imageSp25 from "../../../nostale/Accesoire/Costume_arme/m_laser.png"
import imageSp26 from "../../../nostale/Accesoire/Costume_arme/am_laser.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Cost_arm = (props) => {
   const {navStyle3} = props;
       const { textHidden, toggleText } = useTextHidden();
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
<div className='propose-ritem-group'>
            <div className='propose-ritem propose-item' style={navStyle3}>
            <button type='button'  onClick={() => handleClick( "a_givré", imageSp1)}><img src={imageSp1}></img></button>
            <button type='button'  onClick={() => handleClick( "a_kraken", imageSp2)}><img src={imageSp2}></img></button>
            <button type='button'  onClick={() => handleClick( "a_steam", imageSp3)}><img src={imageSp3}></img></button>
            <button type='button'  onClick={() => handleClick( "am_givré", imageSp4)}><img src={imageSp4}></img></button>
            <button type='button'  onClick={() => handleClick( "am_hommar", imageSp5)}><img src={imageSp5}></img></button>
            <button type='button'  onClick={() => handleClick( "arc_10", imageSp6)}><img src={imageSp6}></img></button>
            <button type='button'  onClick={() => handleClick( "arc_orge", imageSp7)}><img src={imageSp7}></img></button>
            <button type='button'  onClick={() => handleClick( "bois_a", imageSp8)}><img src={imageSp8}></img></button>
            <button type='button'  onClick={() => handleClick( "bois_e", imageSp9)}><img src={imageSp9}></img></button>
            <button type='button'  onClick={() => handleClick( "diablotin", imageSp10)}><img src={imageSp10}></img></button>
            <button type='button'  onClick={() => handleClick( "e_10", imageSp11)}><img src={imageSp11}></img></button>
            <button type='button'  onClick={() => handleClick( "e_givré", imageSp12)}><img src={imageSp12}></img></button>
            <button type='button'  onClick={() => handleClick( "a_steam", imageSp13)}><img src={imageSp13}></img></button>
            <button type='button'  onClick={() => handleClick( "m_10", imageSp14)}><img src={imageSp14}></img></button>

            </div>
            <div className='propose-item item2 propose-ritem' style={navStyle3}>
            <button type='button'  onClick={() => handleClick( "m_corail", imageSp15)}><img src={imageSp15}></img></button>
            <button type='button'  onClick={() => handleClick( "m_givré", imageSp16)}><img src={imageSp16}></img></button>
            <button type='button'  onClick={() => handleClick( "m_noel", imageSp17)}><img src={imageSp17}></img></button>
            <button type='button'  onClick={() => handleClick( "m_steam", imageSp18)}><img src={imageSp18}></img></button>
            <button type='button'  onClick={() => handleClick( "makaira", imageSp19)}><img src={imageSp19}></img></button>
            <button type='button'  onClick={() => handleClick( "raquette", imageSp20)}><img src={imageSp20}></img></button>
            <button type='button'  onClick={() => handleClick( "sapin", imageSp21)}><img src={imageSp21}></img></button>
            <button type='button'  onClick={() => handleClick( "uchiwa", imageSp22)}><img src={imageSp22}></img></button>
            <button type='button'  onClick={() => handleClick( "e_laser", imageSp23)}><img src={imageSp23}></img></button>
            <button type='button'  onClick={() => handleClick( "a_laser", imageSp24)}><img src={imageSp24}></img></button>
            <button type='button'  onClick={() => handleClick( "m_laser", imageSp25)}><img src={imageSp25}></img></button>
            <button type='button'  onClick={() => handleClick( "am_laser", imageSp26)}><img src={imageSp26}></img></button>
            </div>   
        </div>
    );
};

export default Cost_arm;