import React, { useState, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { AuthContext } from '../../Context/AuthContext';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes  } from "firebase/storage"
import { addDoc, serverTimestamp, collection, query, orderBy, limit, onSnapshot,getDoc, doc, where, getDocs, setDoc } from 'firebase/firestore';
import { db2, storage } from '../../firebase-config';
import { format } from 'date-fns';
import { v4 } from 'uuid';
import{ Cz,
  De,
  Us,
  Es,
  Fr,
  Hk,
  It,
  Pl,
  Tr } from 'react-flags-select';
  import { useTranslation } from 'react-i18next';
const CountriePlayer = ({ country }) => {
  switch (country) {
    case "CZ":
      return <Cz />;
    case "DE":
      return <De />;
    case "US":
      return <Us />;
    case "ES":
      return <Es />;
    case "FR":
      return <Fr />;
    case "HK":
      return <Hk />;
    case "IT":
      return <It />;
    case "PL":
      return <Pl />;
    case "TR":
      return <Tr />;
    default:
      return <div>Country not supported</div>;
  }
};
const Pagemsg = () => {
  const { currentUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [otherDude, setOtherDude] = useState('');
  const [country,setCountry]=useState('');
  const [inputValue, setInputValue] = useState('');
  const [otherDudeName, setOtherDudeName] = useState('');
  const [formValue, setFormValue] = useState('');
        const uid = currentUser.uid;
  const [picture_sp_user_url, setPicture_sp_user_url] = useState('');
  const [pictureOther, setPictureOther] = useState('');
  const handleKeyDown = (e) => {
    // Vérifier si la touche pressée est "Entrée" (code 13)
    if (e.key === 'Enter') {
      e.preventDefault(); // Empêcher le saut de ligne dans l'input
      handleSendMessage(inputValue); // Appeler la fonction pour envoyer le message
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const uid = currentUser.uid;
      const docRef = doc(db2, 'users', uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const userPicture = data.pdp_src || '';

        const picture_sp_user = await getDownloadURL(ref(storage, userPicture));
        setPicture_sp_user_url(picture_sp_user);

      }
      try {
        // Récupérer les messages où currentUser.uid est le destinataire
    
        const senderQuery = query(
          collection(db2, 'messages'),
          where('senderUid', '==', currentUser.uid),
          orderBy('timeStamp', 'desc'),
          limit(20)
        );
        
        const recipientQuery = query(
          collection(db2, 'messages'),
          where('recipientUid', '==', currentUser.uid),
          orderBy('timeStamp', 'desc'),
          limit(20)
        );
        
        const [senderQuerySnapshot, recipientQuerySnapshot] = await Promise.all([
          getDocs(senderQuery),
          getDocs(recipientQuery)
        ]);
        // Vérifier si aucun document n'a été trouvé
        if ([senderQuerySnapshot, recipientQuerySnapshot].empty) {
          return;
        }

        // Vérifier si un document a `senderUid` égal à `currentUser.uid`
        // Fusionner les deux querySnapshot en un seul tableau de documents
        const docs = recipientQuerySnapshot.docs.concat(senderQuerySnapshot.docs);

        // Trier les documents par ordre décroissant du timestamp
        docs.sort((a, b) => b.data().timeStamp - a.data().timeStamp);

        // Créer un objet lastMessages pour stocker le dernier message pour chaque otherUserUid
        const lastMessages = {};
        for (const doc of docs) {
          const currentUserUid = currentUser.uid;
          const otherUserUid =
            doc.data().recipientUid === currentUser.uid ? doc.data().senderUid : doc.data().recipientUid;
          if (
            !lastMessages[otherUserUid] ||
            (lastMessages[otherUserUid] && lastMessages[otherUserUid].data().timeStamp < doc.data().timeStamp)
          ) {
            if (
              doc.data().senderUid === otherUserUid ||
              doc.data().receiverUid === otherUserUid ||
              doc.data().senderUid === currentUserUid ||
              doc.data().receiverUid === currentUserUid 
            ) {
              lastMessages[otherUserUid] = doc;
            }
          }
        }

        // Formater les données
        const list = await Promise.all(
          Object.values(lastMessages).map(async (messageDoc) => {
            const timeStamp = messageDoc.data().timeStamp;
            const formattedDate = format(new Date(timeStamp.seconds * 1000), 'dd-MM-yyyy');
            const otherUserUid =
              messageDoc.data().recipientUid === currentUser.uid ? messageDoc.data().senderUid : messageDoc.data().recipientUid;
            const otherUserDoc = await getDoc(doc(db2, `users/${otherUserUid}`));
            const otherUserDisplayName = otherUserDoc.exists() ? otherUserDoc.data().displayName : 'Unknown user';
            const otherUrlPicture = otherUserDoc.exists() ? otherUserDoc.data().pdp_src : '';

            const otherPicture = await getDownloadURL(ref(storage, otherUrlPicture));

            setPictureOther(otherPicture);
   
            return {
              id: messageDoc.id,
              ...messageDoc.data(),
              date: formattedDate,
              otherUserUid,
              otherUserDisplayName,
            };
          })
        );

        // Mettre à jour l'état avec la liste de messages
        setData(list);
      } catch (err) {

      }
    };
    fetchData();
  }, [currentUser, db2]);

  const handleNavClick = async (doc) => {
    const otherUserUid = doc.otherUserUid;

    // Requête pour récupérer les messages envoyés par l'utilisateur actuel
    const senderQuerySnapshot = await getDocs(
      query(
        collection(db2, 'messages'),
        where('senderUid', '==', currentUser.uid),
        where('recipientUid', '==', otherUserUid),
        orderBy('timeStamp', 'desc'),
        limit(20)
      )
      
    );

    // Requête pour récupérer les messages reçus par l'utilisateur actuel
    const recipientQuerySnapshot = await getDocs(
      query(
        collection(db2, 'messages'),
        where('recipientUid', '==', currentUser.uid),
        where('senderUid', '==', otherUserUid),
        orderBy('timeStamp', 'desc'),
        limit(20)
      )
    );
    

    // Concaténation des résultats des deux requêtes
    const querySnapshot3 = recipientQuerySnapshot.docs.concat(senderQuerySnapshot.docs);

    // Tri des documents dans l'ordre décroissant par date de création
    const sortedDocs = querySnapshot3.sort((a, b) => b.data().timeStamp - a.data().timeStamp);
    
    // Création d'un tableau de messages avec une propriété messageClass pour la classe CSS

    const messages = sortedDocs.map((doc) => {
      const data = doc.data();
      const messageClass = data.senderUid === currentUser.uid ? 'sent' : 'received';
      return { ...data, messageClass };
    });

    // Inversion de l'ordre du tableau trié pour afficher les documents dans l'ordre décroissant
    setMessages(messages.reverse());
    setOtherDude(otherUserUid);
    setOtherDudeName(doc.otherUserDisplayName);
    fetchUserCountry(otherUserUid)
  };

  const fetchUserCountry = async (otherUserUid) => {
    try {
      const docRef = doc(db2, 'users', otherUserUid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const userCountry = data.country || '';
        setCountry(userCountry);

      }
    } catch (error) {
      console.error('Error fetching user country:', error);
    }
  };

  const handleSendMessage = async (text) => {
    try {
      await addDoc(collection(db2, 'messages'), {
        senderUid: currentUser.uid,
        recipientUid: otherDude,
        message: inputValue,
        timeStamp: serverTimestamp(),
      });
      setInputValue('');
      handleNavClick({ otherUserUid: otherDude });
    } catch (err) {

    }
  };

  return (
    <div>
      <h1 className="msg-title">Messagerie</h1>
      <div className="Msg_modal">
        <div className="Msg_pse">
          <ul className="nav_pseu">
            {data.map((doc) => (
              <li className="pseudo_nav" key={doc.id}>
              <button
                  type="button" // Change the button type to "button"
                  className="pseudo_nav_btn"
                  onClick={() => handleNavClick(doc)}
                >
                  <p className="user_msg">
                    {doc.otherUserDisplayName}
                    <p className="Date_msg">{doc.date}</p>
                  </p>
                  <p className={doc.isSent ? 'lastmsgg sent_msges' : 'lastmsgg received_msges'}>&gt; {doc.message}</p>
                </button>
              </li>
            ))}
          </ul>
      
        </div>
        <div className="text_msg">
          <p className="pseudal_msssg">Pseudo : {otherDudeName ? otherDudeName : 'pseudo'} {country ?<CountriePlayer country={country} className="picture_countr" /> :<></> }</p>
          
          <div className="BBas">
            <div className='msg'>
            {messages.map((msg) => (
              <div className={`message ${msg.messageClass}`}>
              <img className="img_msg" src={msg.messageClass === "received" ? pictureOther : picture_sp_user_url} alt="" />
                <p className="p_msg">{msg.message}</p>
              </div>
            ))}
            </div>

          </div>
          {otherDude ?
            
            <form onSubmit={(e) => handleSendMessage(e, doc)} className='form_mlsg'> {/* Prevent the default form submission */}
              {/* Form inputs */}
              <div className="Message">
                <input title="Write Message" tabindex="i" pattern="\d+" placeholder="Message.." className="MsgInput"                 
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown} // Ajouter la gestion de l'événement keydown
        />
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="30.000000pt" height="30.000000pt" viewBox="0 0 30.000000 30.000000" preserveAspectRatio="xMidYMid meet" className="SendSVG">
                  <g transform="translate(0.000000,30.000000) scale(0.100000,-0.100000)" fill="#ffffff70" stroke="none">
                  <path                 type="sumbit" // Change the button type to "button"
                
                onClick={() => handleSendMessage(doc)} d="M44 256 c-3 -8 -4 -29 -2 -48 3 -31 5 -33 56 -42 28 -5 52 -13 52 -16 0 -3 -24 -11 -52 -16 -52 -9 -53 -9 -56 -48 -2 -21 1 -43 6 -48 10 -10 232 97 232 112 0 7 -211 120 -224 120 -4 0 -9 -6 -12 -14z"></path>
                </g>
              </svg><span className="l"></span>
                
              </div>
            
              
            </form>
            : <></>}
        </div>
      </div>
    </div>
  );
};

export default Pagemsg;