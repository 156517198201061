import { PayPalButtons } from "@paypal/react-paypal-js";
import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from '../Context/AuthContext';
import { ToastContainer,toast } from 'react-toastify';
import {  setDoc,doc, firestore, updateDoc, getDoc,FieldValue,serverTimestamp,increment   } from 'firebase/firestore';
import { auth,db2,storage } from '../firebase-config';

const PaypalCheckoutButton = (props) => {
  const { currentUser } = useContext(AuthContext);
  const { product } = props;
   const {nospoint} = props;
   const {amount} = props;
  const [uid_form, setUid_form] = useState(''); 
  const [paidFor, setPaidFor] = useState(false);
  const [error, setError] = useState(null);
  

  const handleApprove = (orderId) => {
    // Call backend function to fulfill order

    // if response is success
    setPaidFor(true);
    // Refresh user's account or subscription status

    // if response is error
    // alert("Your payment was processed successfully. However, we are unable to fulfill your purchase. Please contact us at support@designcode.io for assistance.");
  };

  if (paidFor) {
    // Display success message, modal or redirect user to success page
    console.log("Thank you for your purchase!reload page for ");
  }
  if (error) {
    // Display error message, modal or redirect user to error page
    console.log(error);
  }
  return (
    <PayPalButtons 
    onClick={(data, actions) => {
      // Validate on button click, client or server side
      const hasAlreadyBoughtCourse = false;
    
      if (hasAlreadyBoughtCourse) {
        setError(
          "You already bought this course. Go to your account to view your list of courses."
        );
    
        return actions.reject();
      } else {
        return actions.resolve();
      }
    }}
    createOrder={(data, actions) => {
      return actions.order.create({
        purchase_units: [
          {
            description : "NosCoin buying ",
            amount: {
              currency: 'EUR',
              value: amount
            }
          }
        ]
      });
      
    }}
    
    onApprove={async (data, actions) => {
      const order = await actions.order.capture(); 
      const uid = currentUser.uid;
            const docRef = doc(db2, 'users', uid);
            const setNosdollar = async () => {
              await updateDoc(docRef, { Nosdollar: increment(nospoint) });
              const newUidForm = Date.now().toString();
              setUid_form(newUidForm);

              // Use the updated value of uid_form here
              await setDoc(doc(db2, "transaction", newUidForm), {
                senderUid: currentUser.uid,
                prix: amount,
                timeStamp: serverTimestamp(),
                paiement: "Accepté",
                Nosdollar:nospoint
              });
              toast.success("Le paiement a été effectué ! Merci", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            };
            await setNosdollar(); // Wait for the setNosdollar function to complete
      handleApprove(data.orderID);
    }}
    onError={(err) => {
      setError(err);
      console.error("PayPal Checkout onError", err);
    }}
    onCancel={() => {
      // Display cancel message, modal or redirect user to cancel page or back to cart
    }}
    
    />
  );
}
  
  export default PaypalCheckoutButton;