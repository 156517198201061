import React,{useState} from 'react';
import ImageSp1 from "../../nostale/all_arme/Artiste-martial/80-95/81che.png"
import ImageSp2 from "../../nostale/all_arme/Artiste-martial/80-95/82.png"
import ImageSp3 from "../../nostale/all_arme/Artiste-martial/80-95/83g.png"
import ImageSp4 from "../../nostale/all_arme/Artiste-martial/80-95/84martieuxx.png"
import ImageSp5 from "../../nostale/all_arme/Artiste-martial/80-95/85.png"
import ImageSp6 from "../../nostale/all_arme/Artiste-martial/80-95/85cuir.png"
import ImageSp7 from "../../nostale/all_arme/Artiste-martial/80-95/85hh.png"
import ImageSp8 from "../../nostale/all_arme/Artiste-martial/80-95/85ladinne.png"
import ImageSp9 from "../../nostale/all_arme/Artiste-martial/80-95/86.png"
import ImageSp10 from "../../nostale/all_arme/Artiste-martial/80-95/86acier.png"
import ImageSp11 from "../../nostale/all_arme/Artiste-martial/80-95/86assa.png"
import ImageSp12 from "../../nostale/all_arme/Artiste-martial/80-95/87.png"
import ImageSp13 from "../../nostale/all_arme/Artiste-martial/80-95/88.png"
import ImageSp14 from "../../nostale/all_arme/Artiste-martial/80-95/88black.png"
import ImageSp15 from "../../nostale/all_arme/Artiste-martial/80-95/88fenrris.png"
import ImageSp16 from "../../nostale/all_arme/Artiste-martial/80-95/88goleum.png"
import ImageSp17 from "../../nostale/all_arme/Artiste-martial/80-95/88sicario.png"
import ImageSp18 from "../../nostale/all_arme/Artiste-martial/80-95/89.png"
import ImageSp19 from "../../nostale/all_arme/Artiste-martial/80-95/90.png"
import ImageSp20 from "../../nostale/all_arme/Artiste-martial/80-95/90flamme.png"
import ImageSp21 from "../../nostale/all_arme/Artiste-martial/80-95/90gan.png"
import { useTextHidden } from '../../Components/TextHiddenContext';

const Am80_95 = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
      <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_81che", ImageSp1)}><img src={ImageSp1}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_82", ImageSp2)}><img src={ImageSp2} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_83g", ImageSp3)}><img src={ImageSp3} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_84martieuxx", ImageSp4)}><img src={ImageSp4} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_85", ImageSp5)}><img src={ImageSp5} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_85cuir", ImageSp6)}><img src={ImageSp6} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_85hh", ImageSp7)}><img src={ImageSp7} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_85ladinnee", ImageSp8)}><img src={ImageSp8} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_86", ImageSp9)}><img src={ImageSp9} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_86acier", ImageSp10)}><img src={ImageSp10}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_86assa", ImageSp11)}><img src={ImageSp11} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_87", ImageSp12)}><img src={ImageSp12} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_88", ImageSp13)}><img src={ImageSp13} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_88black", ImageSp14)}><img src={ImageSp14} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_88fenrris", ImageSp15)}><img src={ImageSp15} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_88goleum", ImageSp16)}><img src={ImageSp16} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_88sicario", ImageSp17)}><img src={ImageSp17} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_89", ImageSp18)}><img src={ImageSp18} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_90flamme", ImageSp19)}><img src={ImageSp19}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_90", ImageSp20)}><img src={ImageSp20} ></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_90gan", ImageSp21)}><img src={ImageSp21} ></img></button>
        </div>
    )
};

export default Am80_95;
