import React ,{useState} from 'react';
import imageSp1 from "../../../nostale/Accesoire/Chapeau/0h-30h/30cc.png"
import imageSp2 from "../../../nostale/Accesoire/Chapeau/0h-30h/30hc.png"
import imageSp3 from "../../../nostale/Accesoire/Chapeau/0h-30h/30hcec.png"
import imageSp4 from "../../../nostale/Accesoire/Chapeau/0h-30h/30hcs.png"
import imageSp5 from "../../../nostale/Accesoire/Chapeau/0h-30h/30hi.png"
import imageSp6 from "../../../nostale/Accesoire/Chapeau/0h-30h/30hin.png"
import imageSp7 from "../../../nostale/Accesoire/Chapeau/0h-30h/30jenni.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Chapeau0_30h = (props) => {
      const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
<div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
            <button type='button'  onClick={() => handleClick( "chap_30cc", imageSp1)}><img src={imageSp1}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_30hc", imageSp2)}><img src={imageSp2}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_30hcec", imageSp3)}><img src={imageSp3}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_30hcs", imageSp4)}><img src={imageSp4}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_30hi", imageSp5)}><img src={imageSp5}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_30hin", imageSp6)}><img src={imageSp6}></img></button>
            <button type='button'  onClick={() => handleClick( "chap_30jenni", imageSp7)}><img src={imageSp7}></img></button>

</div>
    );
};

export default Chapeau0_30h;