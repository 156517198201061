import React,{useState} from 'react';
import imageSp1 from "../../nostale/all_arme/archer/80-95/82.png"
import imageSp2 from "../../nostale/all_arme/archer/80-95/83.png"
import imageSp3 from "../../nostale/all_arme/archer/80-95/85chef.png"
import imageSp4 from "../../nostale/all_arme/archer/80-95/85elemenentaire.png"
import imageSp5 from "../../nostale/all_arme/archer/80-95/85fake.png"
import imageSp6 from "../../nostale/all_arme/archer/80-95/88.png"
import imageSp7 from "../../nostale/all_arme/archer/80-95/88C.png"
import imageSp8 from "../../nostale/all_arme/archer/80-95/88D.png"
import imageSp9 from "../../nostale/all_arme/archer/80-95/88H.png"
import imageSp10 from "../../nostale/all_arme/archer/80-95/88hono.png"
import imageSp11 from "../../nostale/all_arme/archer/80-95/90.png"
import imageSp12 from "../../nostale/all_arme/archer/80-95/d80.png"
import imageSp13 from "../../nostale/all_arme/archer/80-95/d82.png"
import imageSp14 from "../../nostale/all_arme/archer/80-95/d84.png"
import imageSp15 from "../../nostale/all_arme/archer/80-95/d85.png"
import imageSp16 from "../../nostale/all_arme/archer/80-95/d85hono.png"
import imageSp17 from "../../nostale/all_arme/archer/80-95/d88.png"
import imageSp18 from "../../nostale/all_arme/archer/80-95/d90.png"
import imageSp19 from "../../nostale/all_arme/archer/80-95/dfake.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const A80_95 = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
      <div className='propose-ritem-group'>
      <div className='propose-ritem propose-item' style={navStyle3}>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_82", imageSp1)} ><img src={imageSp1}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_83", imageSp2)} ><img src={imageSp2}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_85chef", imageSp3)} ><img src={imageSp3}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_85elemenentaire", imageSp4)} ><img src={imageSp4}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_85fake", imageSp5)} ><img src={imageSp5}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_88", imageSp6)} ><img src={imageSp6}></img></button> 
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_88C", imageSp7)} ><img src={imageSp7}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_88D", imageSp8)} ><img src={imageSp8}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_88H", imageSp9)} ><img src={imageSp9}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_88hono", imageSp10)} ><img src={imageSp10}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_90", imageSp11)} ><img src={imageSp11}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_d80", imageSp12)} ><img src={imageSp12}></img></button>
    </div>
      <div className='propose-item item2 propose-ritem' style={navStyle3}>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_d82", imageSp13)}><img src={imageSp13}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_d84", imageSp14)}><img src={imageSp14}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_d85", imageSp15)}><img src={imageSp15}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_d85hono", imageSp16)}><img src={imageSp16}></img></button>      
      <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_d88", imageSp17)} ><img src={imageSp17}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_d90", imageSp18)} ><img src={imageSp18}></img></button>
    <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_dfake", imageSp19)}><img src={imageSp19}></img></button>
            </div>
            </div>

    );
};

export default A80_95;