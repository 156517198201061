import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const RunePvp = (props) => {
  const [valeurSelect, setValeurSelect] = useState('buff_def_pvp');
  const [valeurInput, setValeurInput] = useState(0);
  const { t } = useTranslation();
      const [check, setCheck] = useState(false);
      const checkChanges = () => {
        // Vérifier si les champs ont été modifiés
        // Par exemple, si valeurSelect et valeurInput ont été modifiés
        if (valeurInput !== 0 && valeurInput !== null) {
          // Mettre à jour setCheck si les champs ont été modifiés
          setCheck(true);
        } else {
          // Les champs n'ont pas été modifiés, pas besoin de mettre à jour setCheck
          setCheck(false);
        }
      };

      const confirm_rune = document.querySelector('.confirm_rune');
    
      if (check){
        confirm_rune.style.display = 'none';
      }else {
        confirm_rune.style.display = 'flex';
      }
  // Use useEffect to call onValueChange with initial values

  function handleButtonClick() {
    // Appeler props.onValueChange avec les valeurs actuelles de select et de l'input
    checkChanges()
    props.onValueChange(props.index, valeurSelect, valeurInput);
  }
    const arme_pvp = [
        'buff_def_pvp', 'miss_cac_pvp', 'miss_dist_pvp', 'miss_mag_pvp', 'miss_all_pvp',
         'protect_mp_pvp'
      ];

    return (
        <div>
             <select className='select-add armec' value={valeurSelect} disabled={check}  onChange={e=>setValeurSelect(e.target.value)}>
            <option value={arme_pvp[0]}>{t('buff_def_pvp')}</option>
            <option value={arme_pvp[1]}>{t('miss_cac_pvp')}</option>
            <option value={arme_pvp[2]}>{t('miss_dist_pvp')}</option>
            <option value={arme_pvp[3]}>{t('miss_mag_pvp')}</option>
            <option value={arme_pvp[4]}>{t('miss_all_pvp')}</option>
            <option value={arme_pvp[5]}>{t('protect_mp_pvp')}</option>
          </select>
          <input type="number" className="input_1" value={valeurInput} disabled={check} min='0' onChange={e=>setValeurInput(e.target.value)} />
          {!check ? <button type='button' className='btn_rune' onClick={handleButtonClick}>
        <span className="material-symbols-outlined">
          done_outline
        </span>
      </button>
        :
        <></>
        }
        </div>
    );
};

export default RunePvp;