import React,{useState} from 'react';
import imageSp1 from "../../nostale/SP/escri/sp1.png"
import imageSp2 from "../../nostale/SP/escri/sp2.png"
import imageSp3 from "../../nostale/SP/escri/sp3.png"
import imageSp4 from "../../nostale/SP/escri/sp4.png"
import imageSp5 from "../../nostale/SP/escri/sp5.png"
import imageSp6 from "../../nostale/SP/escri/sp6.png"
import imageSp7 from "../../nostale/SP/escri/sp7.png"
import imageSp8 from "../../nostale/SP/escri/sp8.png"
import imageSp9 from "../../nostale/SP/escri/sp9.png"
import imageSp10 from "../../nostale/SP/escri/sp10.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const SpEscri = (props) => {
      const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
        <div className='propose-item' style={navStyle3}>
        <button   type='button' id="sp1a"  onClick={() => handleClick("Sp1e", imageSp1)} ><img src={imageSp1} ></img></button>
          <button  type='button'  id="sp2a"  onClick={() => handleClick( "Sp2e",imageSp2)}><img src={imageSp2}></img></button>
          <button  type='button'  id="sp3a" onClick={() => handleClick( "Sp3e", imageSp3)}><img src={imageSp3}></img></button>
          <button  type='button'  id="sp4a" onClick={() => handleClick( "Sp4e", imageSp4)}><img src={imageSp4}></img></button>
          <button  type='button'  id="sp5a" onClick={() => handleClick( "Sp5e", imageSp5)}><img src={imageSp5}></img></button>
          <button  type='button'  id="sp6a" onClick={() => handleClick( "sp6e", imageSp6)}><img src={imageSp6}></img></button> 
          <button  type='button'  id="sp7a" onClick={() => handleClick( "Sp7e", imageSp7)}><img src={imageSp7}></img></button>
          <button  type='button'  id="sp8a" onClick={() => handleClick( "Sp8e", imageSp8)}><img src={imageSp8}></img></button>
          <button  type='button'  id="sp9a" onClick={() => handleClick( "Sp9e", imageSp9)}><img src={imageSp9}></img></button>
          <button type='button'  id="sp10a" onClick={() => handleClick("Sp10e",imageSp10)} ><img src={imageSp10}></img></button>
        </div>
    );
};

export default SpEscri;