import React,{useState} from 'react';

import imageSp1 from "../../nostale/Divers/Matériaux_up/ame_bleu.png"
import imageSp2 from "../../nostale/Divers/Matériaux_up/ame_rouge.png"
import imageSp3 from "../../nostale/Divers/Matériaux_up/ame_vert.png"
import imageSp4 from "../../nostale/Divers/Matériaux_up/obsi.png"
import imageSp5 from "../../nostale/Divers/Matériaux_up/pleine_lune.png"
import imageSp6 from "../../nostale/Divers/Matériaux_up/plume.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const Matériaux_up = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
        <div className='propose-item' style={navStyle3}>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("ame_bleu", imageSp1)}><img src={imageSp1}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("ame_rouge", imageSp2)}><img src={imageSp2}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("ame_vert", imageSp3)}><img src={imageSp3}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("obsi", imageSp4)}><img src={imageSp4}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("pleine_lune", imageSp5)}><img src={imageSp5}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("plume", imageSp6)}><img src={imageSp6}></img></button> 
        </div>
    );
};

export default Matériaux_up;