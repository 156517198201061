import React,{useState} from 'react';
import imageSp1 from "../../../nostale/res/gants/80-95/80g.png"
import imageSp2 from "../../../nostale/res/gants/80-95/83g.png"
import imageSp3 from "../../../nostale/res/gants/80-95/85g.png"
import imageSp4 from "../../../nostale/res/gants/80-95/88g.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Grant80_95 = (props) => {
    const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
        <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
        <button type='button' id="sp1a" onClick={() => handleClick("gant_80", imageSp1)} ><img src={imageSp1}></img></button>
          <button type='button' id="sp1a" onClick={() => handleClick("gant_83", imageSp2)} ><img src={imageSp2} ></img></button>
          <button type='button' id="sp1a" onClick={() => handleClick("gant_85", imageSp3)} ><img src={imageSp3} ></img></button>
          <button type='button' id="sp1a" onClick={() => handleClick("gant_88", imageSp4)} ><img src={imageSp4} ></img></button>
        </div>
    );
};

export default Grant80_95;