
import {Routes, Route}from "react-router-dom"
import Bazar from "./Page/Bazar";
import Bazar_vente from "./Page/Bazar_vente";
import Boutique from "./Page/Boutique";
import AcheterNos from "./Page/AcheterNos";
import PrivateHome from "./Page/Private/PrivateHome/PrivateHome";
import ForgotPasswordPage from "./Components/Connection/ForgotPasswordPage"
import Nouveau_account from "./Page/Nouveau_account";
import { Navigate } from "react-router-dom";  
import { useContext } from "react";
import { AuthContext } from "./Context/AuthContext";
import { userInputs } from "./formSource";
import Gestion_Sell from "./Page/Gestion_Sell";
import Messagerie from "./Page/Messagerie";
import Discordjoin from "./Page/Discordjoin";
import Gold_Nostale from "./Page/Gold_Nostale";
import Estimagter from "./Page/Estimagter";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // L'instance de i18next que nous avons créée dans le fichier de configuration
import RapBug from "./Components/RapBug";
import Account from "./Page/Account";
import Estimate_Sell from "./Components/Estimate_Sell"
import SellGoldNos from "./Components/SellGoldNos";
import { TextHiddenProvider } from './Components/TextHiddenContext';
import Sell_account from "./Components/Sell_account";
function App() {
  const {currentUser}= useContext(AuthContext)

  const RequireAuth = ({children}) => {
    return currentUser ? children : <Navigate to="/"/> ;
  };


  return (<>   
            <I18nextProvider i18n={i18n}>   
            <TextHiddenProvider>
            <Routes>

              <Route path="/" element={<Bazar />} />
              <Route path="/new_account" element={<Nouveau_account />} />
              <Route path='/forgot-password' element={<ForgotPasswordPage />} />

              <Route path='/goldnostale' element={<RequireAuth><Gold_Nostale/></RequireAuth>}/>
              <Route path='/sellgoldnst' element={<RequireAuth><SellGoldNos/></RequireAuth>}/>
              <Route path="/nosbaz" element={<RequireAuth><AcheterNos/></RequireAuth>} />
              <Route path="/vente" element={<RequireAuth><Bazar_vente/></RequireAuth>} />
              <Route path="/gestion" element={<RequireAuth><Gestion_Sell/></RequireAuth>} />
              <Route path="/boutique" element={<RequireAuth><Boutique/></RequireAuth>} />
              <Route path='/Discordjoin' element={<RequireAuth><Discordjoin/></RequireAuth>} />
              <Route path="/messagerie" element={<RequireAuth><Messagerie/></RequireAuth>} />
              <Route path='/Estimate' element={<RequireAuth><Estimagter/></RequireAuth>}/>
              <Route path='/RaportBug' element={<RequireAuth><RapBug/></RequireAuth>}/>
              <Route path='/Estimatepost' element={<RequireAuth><Estimate_Sell/></RequireAuth>}/>
              <Route path='/account' element={<RequireAuth><Account/></RequireAuth>}/>
              <Route path='/sell_account' element={<RequireAuth><Sell_account/></RequireAuth>}/>
              <Route path="private">
                <Route path="/private/private-home" element={<RequireAuth><PrivateHome inputs = {userInputs} /></RequireAuth>}/>
              </Route>

            </Routes>
            </TextHiddenProvider>
            </I18nextProvider>
          </>
  );
}

export default App;
