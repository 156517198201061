import React,{useState} from 'react';
import imageSp1 from "../../nostale/SP/Autre/cuisto.png"
import imageSp2 from "../../nostale/SP/Autre/jaja.png"
import imageSp3 from "../../nostale/SP/Autre/mariage.png"
import imageSp4 from "../../nostale/SP/Autre/pech.png"
import imageSp5 from "../../nostale/SP/Autre/pirate.png"
import imageSp6 from "../../nostale/SP/Autre/pyj.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const SpAutre = (props) => {
      const { textHidden, toggleText } = useTextHidden();
  const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
        <div className='propose-item' style={navStyle3}>
          <button type='button' id="sp1a"  onClick={() => handleClick("cuisto", imageSp1)}><img src={imageSp1}></img></button>
          <button type='button' id="sp1a"  onClick={() => handleClick("jaja", imageSp2)}><img src={imageSp2} ></img></button>
          <button type='button' id="sp1a"  onClick={() => handleClick("mariage", imageSp3)}><img src={imageSp3} ></img></button>
          <button type='button' id="sp1a"  onClick={() => handleClick("pech", imageSp4)}><img src={imageSp4} ></img></button>
          <button type='button' id="sp1a"  onClick={() => handleClick("pirate", imageSp5)}><img src={imageSp5} ></img></button>
          <button type='button' id="sp1a"  onClick={() => handleClick("pyj", imageSp6)}><img src={imageSp6} ></img></button>
        </div>
    );
};

export default SpAutre;