
import { createContext, useContext, useState } from 'react';

const TextHiddenContext = createContext();

export const TextHiddenProvider = ({ children }) => {
  const [textHidden, setTextHidden] = useState(false);

  const toggleText = () => {
    setTextHidden(!textHidden);
  };

  return (
    <TextHiddenContext.Provider value={{ textHidden, toggleText }}>
      {children}
    </TextHiddenContext.Provider>
  );
};

export const useTextHidden = () => {
  const context = useContext(TextHiddenContext);
  if (!context) {
    throw new Error('useTextHidden must be used within a TextHiddenProvider');
  }
  return context;
};