import React,{useState,useEffect} from 'react';
import SignnInModal_v2 from '../Components/Connection/SignnInModal_v2';
import btn_co from '../Components/Connection/btn_co';
import SignUpModal from '../Components/Connection/SignUpModal';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import site_imh from '../nostale/anex/name_site_site - Copie.png'
import FlagsSelect,{ Cz,
  De,
  Us,
  Es,
  Fr,
  Hk,
  It,
  Pl,
  Tr } from 'react-flags-select';
  import { ToastContainer,toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
const LanguageSelector = () => {
  const { t } = useTranslation();
  const [flaglanguage, setFlagLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');

  const changeLanguage = (e) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
    setFlagLanguage(selectedLanguage);
    localStorage.setItem('selectedLanguage', selectedLanguage);
  };

  useEffect(() => {
    // Set the initial language on component mount
    i18n.changeLanguage(flaglanguage);
  }, [flaglanguage]);

  return (
    <div className='language_web'>
      <p>Languague WebSite : </p>
      <div className='flag_language'>
        {flaglanguage === 'fr' ? (
          <Fr />
        ) : flaglanguage === 'en' ? (
          <Us />
        ) : flaglanguage === 'de' ? (
          <De />
        ) : flaglanguage === 'es' ? (
          <Es />
        ) : flaglanguage === 'it' ? (
          <It />
        ) : flaglanguage === 'pl' ? (
          <Pl />
        ) : flaglanguage === 'hk' ? (
          <Hk />
        ) : flaglanguage === 'tr' ? (
          <Tr />
        ) : (
          <></>
        )}
      </div>
      <select onChange={changeLanguage} defaultValue={flaglanguage}>
        <option value='fr'>Français</option>
        <option value='en'>English</option>
        <option value='de'>German</option>
        <option value='es'>Spanish</option>
        <option value='it'>Italian</option>
        <option value='pl'>Polski</option>
        <option value='hk'>Cantonese</option>
        <option value='tr'>Türkçe</option>
      </select>
    </div>
  );
};


const Bazar = () => {
  const { t } = useTranslation();

  const [modalState, setModalState] = useState({
    signUpModal: false,
    signInModal: false
  })

  const toggleModals = modal => {
    if(modal === "signIn") {
      setModalState(prevState => ({
        ...prevState,
        signInModal: true
      }))
    }
    if(modal === "signUp") {
      setModalState(prevState => ({
        ...prevState,
        signUpModal: true
      }))
    }
    if(modal === "close") {
      setModalState({
        signUpModal: false,
        signInModal: false
      })
    }
  }



    return  (
        <>  
        <div className="Profilus co tophome">
            <button onClick={() => toggleModals('signIn')}  className='log_btn'>Login</button>
            <h1 className='title_web'><img src={site_imh}/></h1>
            <button onClick={() => toggleModals('signUp')} className='log_btn'>Sign Up</button>
        </div>
        <div className='body_home'>
        <LanguageSelector />
          <div className='Presentations'>
          
          <div className='part1'>
          <div className='présentation_site'>

          <h3>{t('welcome')}</h3>

          <p className='unpre'>
          {t('unpre')}</p>

          <p className='deuxpre'>{t('deuxpre')}</p>

          <p className='troispre'>{t('troispre')}</p>

          <p className='quapre'>{t('quapre')}
                    </p>

         

          </div>
          <div className='cardus'>
          <div className="cardu">
          <p className="headingu">
          {t('add-1-title')}
            </p>
            <p>
            {t('add-1')}
            </p>
            <p>{t('add-1bis')}
          </p></div>
          <div className="cardu">
          <p className="headingu">
          {t('add-2-title')}
            </p>
            <p>
            {t('add-2')}
            </p>
            <p>{t('add-2bis')} 
          </p></div>
          <div className="cardu">
          <p className="headingu">
          {t('add-3-title')}
            </p>
            <p>
            {t('add-3')}
            </p>
            <p>
          </p>
          </div>
          </div>
          <div className="footerusse">
                  <p>{t('apropos')}</p>
                  <p>facebook : <a className='fb_link' href='https://www.facebook.com/people/BazNos/100095523103918/'>BazNos FB</a></p>
        <p>{t('reserv')}</p>
        <p>{t('©')}</p>
    </div>
          </div>

          </div>
          <div className='discordus'>
                <iframe src="https://discord.com/widget?id=1084466438015823892&theme=dark" width="270" height="750px" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
          </div>

        </div>
        
        <div>
        {modalState.signInModal && (<SignnInModal_v2  setModalState={setModalState}/>)}
        {modalState.signUpModal && (<SignUpModal setModalState={setModalState} />)}
        </div>

    <ToastContainer />
        </>
    );
};
export default Bazar;