import React,{useState} from 'react';
import imageSp1 from "../../nostale/Divers/Parchemin/accesoir_draco.png"
import imageSp2 from "../../nostale/Divers/Parchemin/arm_seco_draco.png"
import imageSp3 from "../../nostale/Divers/Parchemin/arme_draco.png"
import imageSp4 from "../../nostale/Divers/Parchemin/armure_draco.png"
import imageSp5 from "../../nostale/Divers/Parchemin/carte__drac.png"
import imageSp6 from "../../nostale/Divers/Parchemin/carte_spé.png"
import imageSp7 from "../../nostale/Divers/Parchemin/chance_runique.png"
import imageSp8 from "../../nostale/Divers/Parchemin/changementde_tatouage.png"
import imageSp9 from "../../nostale/Divers/Parchemin/equipement_dorée.png"
import imageSp10 from "../../nostale/Divers/Parchemin/équipement.png"
import imageSp11 from "../../nostale/Divers/Parchemin/libération.png"
import imageSp12 from "../../nostale/Divers/Parchemin/modificateur_couleur.png"
import imageSp13 from "../../nostale/Divers/Parchemin/modificcateu.png"
import imageSp14 from "../../nostale/Divers/Parchemin/premium.png"
import imageSp15 from "../../nostale/Divers/Parchemin/protec_inf.png"
import imageSp16 from "../../nostale/Divers/Parchemin/prtatouage.png"
import imageSp17 from "../../nostale/Divers/Parchemin/runes_premiul.png"
import imageSp18 from "../../nostale/Divers/Parchemin/runique.png"
import imageSp19 from "../../nostale/Divers/Parchemin/tatouage_random.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const Parchemin = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
        <div className='propose-ritem-group'>
            <div className='propose-ritem propose-item' style={navStyle3}>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("accesoir_draco", imageSp1)}><img src={imageSp1}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("arm_seco_draco", imageSp2)}><img src={imageSp2}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("arme_draco", imageSp3)}><img src={imageSp3}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("armure_draco", imageSp4)}><img src={imageSp4}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("carte__drac", imageSp5)}><img src={imageSp5}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("carte_spé", imageSp6)}><img src={imageSp6}></img></button> 
          <button type='button' id="vente_sp1"  onClick={() => handleClick("chance_runique", imageSp7)}><img src={imageSp7}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("changementde_tatouage", imageSp8)}><img src={imageSp8}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("equipement_dorée", imageSp9)}><img src={imageSp9}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("équipement", imageSp10)}><img src={imageSp10}></img></button>

            </div>
            
            <div className='propose-item item2 propose-ritem' style={navStyle3}>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("libération", imageSp11)}><img src={imageSp11}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("modificateur_couleur", imageSp12)}><img src={imageSp12}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("modificcateu", imageSp13)}><img src={imageSp13}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("premium", imageSp14)}><img src={imageSp14}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("protec_inf", imageSp15)}><img src={imageSp15}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("prtatouage", imageSp16)}><img src={imageSp16}></img></button> 
          <button type='button' id="vente_sp1"  onClick={() => handleClick("runes_premiul", imageSp17)}><img src={imageSp17}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("runique", imageSp18)}><img src={imageSp18}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("tatouage_random", imageSp19)}><img src={imageSp19}></img></button>
            </div>
            </div>
    );
};

export default Parchemin;