import React,{useState} from 'react';
import imageSp1 from "../../nostale/Partennaire/magique/arlequin.png"
import imageSp2 from "../../nostale/Partennaire/magique/cheongbi.png"
import imageSp3 from "../../nostale/Partennaire/magique/Djin.png"
import imageSp4 from "../../nostale/Partennaire/magique/freya.png"
import imageSp5 from "../../nostale/Partennaire/magique/Glace.png"
import imageSp6 from "../../nostale/Partennaire/magique/hongbi.png"
import imageSp7 from "../../nostale/Partennaire/magique/Laurenna.png"
import imageSp8 from "../../nostale/Partennaire/magique/orkani.png"
import imageSp9 from "../../nostale/Partennaire/magique/yuna.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const Magie = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
        <div className='propose-item' style={navStyle3}>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("arlequin", imageSp1)}><img src={imageSp1}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("cheongbi", imageSp2)}><img src={imageSp2}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("Djin", imageSp3)}><img src={imageSp3}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("freya", imageSp4)}><img src={imageSp4}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("Glace", imageSp5)}><img src={imageSp5}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("hongbi", imageSp6)}><img src={imageSp6}></img></button> 
        <button type='button' id="vente_sp1"  onClick={() => handleClick("Laurenna", imageSp7)}><img src={imageSp7}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("orkani", imageSp8)}><img src={imageSp8}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("yuna", imageSp9)}><img src={imageSp9}></img></button>
        </div>
    );
};

export default Magie;