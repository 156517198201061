import React, {  useEffect,useState, useContext } from 'react';
import Select from 'react-select';
import { AuthContext } from '../Context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { db2, storage,auth } from '../firebase-config';
import { collection, doc ,getDocs, where,query,serverTimestamp,addDoc,getDoc,deleteDoc,updateDoc } from 'firebase/firestore';
  import { getStorage, ref,uploadBytes,getDownloadURL,deleteObject } from "firebase/storage";
  import { useTranslation } from 'react-i18next';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';
import imgload from "../photo_anex/load.gif" ;
import aventurier from "../nostale/anex/aventurier.png"
import archer from "../nostale/anex/archer.png"
import escri from "../nostale/anex/escri.png"
import mage from "../nostale/anex/mage.png"
import artist_martial from "../nostale/anex/artist-martial.png"
import{ Cz,
  De,
  Us,
  Es,
  Fr,
  Hk,
  It,
  Pl,
  Tr } from 'react-flags-select';
  import { useTextHidden } from './TextHiddenContext';
  const CountriePlayer = ({ country }) => {
    switch (country) {
      case "CZ":
        return <Cz />;
      case "DE":
        return <De />;
      case "US":
        return <Us />;
      case "ES":
        return <Es />;
      case "FR":
        return <Fr />;
      case "HK":
        return <Hk />;
      case "IT":
        return <It />;
      case "PL":
        return <Pl />;
      case "TR":
        return <Tr />;
      default:
        return <div>Country not supported</div>;
    }
  };
const ImageComponent = ({ doc }) => {
  let classeImage;

  // Sélection de l'image en fonction de la classe
  switch (doc.classe) {
    case 'aventurier':
      classeImage = aventurier;
      break;
    case 'archer':
      classeImage = archer;
      break;
    case 'escri':
      classeImage = escri;
      break;
    case 'mage':
      classeImage = mage;
      break;
    case 'artist_martial':
      classeImage = artist_martial;
      break;
    default:
      // Gérer le cas où la classe n'est pas reconnue
      classeImage = null;
  }

  // Rendu de l'image
  return (
    <div>
      {classeImage && <img src={classeImage} alt={doc.classe} className='logo_clas' />}
    </div>
  );
};

const AccountNst = () => {
    const [data, setData] = useState([]);

const [pseudo,setPseudo]=useState('')
const [dislikeprofil, setdisLikeProfil] = useState(0);
const [likeprofil, setLikeProfil] = useState(0);
const { textHidden, toggleText } = useTextHidden();
const { t } = useTranslation();
const [gif_user_url, setGif_user_url] = useState('');
const [serveur,setServeur]= useState();
const navigate = useNavigate();
const [loading, setLoading] = useState(true);
const [otheruid, setotheruid] = useState();
const [currentPage, setCurrentPage] = useState(1);
const [documentsPerPage] = useState(9);
const indexOfLastDocument = currentPage * documentsPerPage;
const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
const currentDocuments = data.slice(indexOfFirstDocument, indexOfLastDocument);
const [country,setCountry]=useState('');
const [discord, setDiscord] = useState('');
const [comment, setComment] = useState('');
const [modalOpenDelete, setModalOpenDelete] = useState([]);
const [pictureSpUserUrl, setPicture_sp_user_url] = useState('');
const [modalOpen, setModalOpen] = useState(Array(data.length).fill(false));
const { currentUser } = useContext(AuthContext);
const [overlayVisible, setOverlayVisible] = useState(false);
const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedDocument, setSelectedDocument] = useState(null);
const uid = currentUser.uid;
// ... (your existing code)
const paginate = (pageNumber) => {
  if (pageNumber <= Math.ceil(data.length / documentsPerPage)) {
    setCurrentPage(pageNumber);
  }
};
const handleBtnShowClick = async (index, doc) => {

  hourglassElement.style.display = 'inline-block';
  setLoading(true); // set loading state
  try {
    const recipientUid = doc.senderUid;
    await fetchUserCountry(recipientUid);
    setOverlayVisible(true);
    // ... rest of your code
  } catch (error) {
    console.error('Error handling button click:', error);
    hourglassElement.style.display = 'none';
  } finally {
    setLoading(false); // clear loading state, whether successful or not
    hourglassElement.style.display = 'none';
  }
};

const fetchUserCountry = async (senderUid) => {
  try {

    const uid = currentUser.uid;

    const docRef = doc(db2, 'users', senderUid);
    const docSnap = await getDoc(docRef);

    const userDoc = doc(db2, 'score', senderUid);
    const docSnap3 = await getDoc(userDoc);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const userDiscord = data.Discord || '';
      const userCountry = data.country || '';
      const userComment = data.comment || '';
      const userPicture = data.pdp_src || '';
      const userGif = data.gif_src || '';
      const userpseudo = data.displayName || '';
      setPseudo(userpseudo)
      setotheruid(senderUid);
      setCountry(userCountry);
      setDiscord(userDiscord);
      setComment(userComment);

      const data2 = docSnap3.data();
      const likeCount = (data2.like && data2.like.length) || 0;
      const dislikeCount = (data2.dislike && data2.dislike.length) || 0;

      setLikeProfil(likeCount);
      setdisLikeProfil(dislikeCount);

      const picture_sp_user = await getDownloadURL(ref(storage, userPicture));
      setPicture_sp_user_url(picture_sp_user);

      const gif_user_url = await getDownloadURL(ref(storage, userGif));
      setGif_user_url(gif_user_url);
    }
  } catch (error) {
    console.error('Error fetching user country:', error);
  } finally {
    setLoading(false);
  }
};

const handleVoirDetailClick = (doc) => {
  setSelectedDocument(doc);
  setIsModalOpen(true);
};
function handleBtnMasqClick() {
  setOverlayVisible(false);
}

async function handleLike(index, docs) {
  const recipientUid = docs[index]?.senderUid;

  if (!recipientUid) {
      console.error("L'UID du destinataire est manquant.");
      return;
  }
  if (recipientUid === currentUser.uid) {
    toast.warn ('Vous ne pouvez pas votez pour vous même ', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
    return}
  const docRef = doc(db2, 'score', recipientUid);
  const docSnapshot = await getDoc(docRef);
  const uid = currentUser.uid;

  if (docSnapshot.exists()) {
    const userData = docSnapshot.data();

    // Vérifier si current.uid est dans dislike
    if (!userData.dislike || userData.dislike.indexOf(uid) === -1) {
        // Vérifier si current.uid est déjà dans like
        if (!userData.like || userData.like.indexOf(uid) === -1) {
            // Ajouter current.uid à like s'il n'est pas déjà présent
            const updatedLikeprofil = [...(userData.like || []), uid];

            // Mettre à jour le document avec la nouvelle liste like
            await updateDoc(docRef, { like: updatedLikeprofil });

            // Incrémenter la valeur affichée
            const likeCount = updatedLikeprofil.length;
            const likeTextContent = document.querySelector('.like-text-content');
            if (likeTextContent) {
                likeTextContent.innerText = likeCount;
            } else {
                console.error("L'élément .like-text-content est manquant.");
            }
        } else {
          toast.warn ('L utilisateur like déjà cette personne ', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            })
        }
    } else {
      toast.warn ('Vous Dislike cette personne', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }
} else {
    console.error("Le document n'existe pas pour l'UID du destinataire : ", recipientUid);
}
}
async function handledisLike(index, docs) {
  const recipientUid = docs[index]?.senderUid;

  if (!recipientUid) {
      console.error("L'UID du destinataire est manquant.");
      return;
  }
  if (recipientUid === currentUser.uid) {
    toast.warn ('Vous ne pouvez pas votez pour vous même ', {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
    return}
  const docRef = doc(db2, 'score', recipientUid);
  const docSnapshot = await getDoc(docRef);
  const uid = currentUser.uid;

  if (docSnapshot.exists()) {
    const userData = docSnapshot.data();

    // Vérifier si current.uid est dans like
    if (!userData.like || userData.like.indexOf(uid) === -1) {
        // Vérifier si current.uid est déjà dans dislike
        if (!userData.dislike || userData.dislike.indexOf(uid) === -1) {
            // Ajouter current.uid à dislike s'il n'est pas déjà présent
            const updatedDislikeprofil = [...(userData.dislike || []), uid];

            // Mettre à jour le document avec la nouvelle liste dislike
            await updateDoc(docRef, { dislike: updatedDislikeprofil });

            // Incrémenter la valeur affichée
            const dislikeCount = updatedDislikeprofil.length;
            const dislikeTextContent = document.querySelector('.dislike-text-content');
            if (dislikeTextContent) {
                dislikeTextContent.innerText = dislikeCount;
            } else {
                console.error("L'élément .dislike-text-content est manquant.");
            }
        } else {
          toast.warn ('Vous Dislike déjà cette personne', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        }
    } else {
      toast.warn ('L utilisateur like cette personne ', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        })
    }
} else {
    console.error("Le document n'existe pas pour l'UID du destinataire : ", recipientUid);
}
}
const closeModal = () => {
  setSelectedDocument(null);
  setIsModalOpen(false);
};
    const Serveur = [
        { value: 'Valehir', label: 'Valehir' },
        { value: 'Alzanor', label: 'Alzanor' },
        { value: 'Dragonveil', label: 'Dragonveil' },
        { value: 'Cosmos', label: 'Cosmos' },
        { value: 'Jotunheim', label: 'Jotunheim' }
      ];

    const customStyles = {
        container: (provided) => ({
          ...provided,
          width: '150px',
          fontSize: '15px',
          position: 'relative',
          cursor: 'pointer',
          display: 'inline-block' // add this to align with the original .list-choice
        }),
        control: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          color: '#FFF',
          textShadow: '0 1px 0 rgba(144, 142, 142, 0.66)',
          borderRadius: '0.2em',
          border: 'none',
          cursor: 'pointer',
          display: 'flex', // center selected value
          justifyContent: 'center', // center selected value
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          borderRadius: '0.2em',
          overflow: 'hidden'
        }),
        option: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          color: '#FFF',
          cursor: 'pointer'
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#FFF',
          background: 'rgb(116,214,142)',
          background: 'linear-gradient(102deg, rgba(116,214,142,1) 0%, rgba(116,214,142,0.04525560224089631) 59%)',
          height: '39px',
          borderRadius: '2.4px 6px 6px 2.4px',
          marginLeft:'-8px',
          width: '113px', // fill width of control
          display: 'flex',
          alignItems: 'center',
          marginTop: '-1.8px',
          marginBottom: '-2px',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#FFF'
        })
      };
      const hourglassElement = document.querySelector('.loader');

      const handleSelectChange = async (selectedOption) => {
          setData([]);
          const selectedServeur = selectedOption.value;
          setServeur(selectedServeur); // Updating serveur state
      
          try {
              if (hourglassElement) {
                  hourglassElement.style.display = 'inline-block';
              }
      
              let queryRef = collection(db2, "Sell_account");
              const querySnapshot = await getDocs(query(queryRef, where("serveur", "==", selectedServeur)));
      
              const list = await Promise.all(
                  querySnapshot.docs.map(async (doc) => {
                      const timeStamp = doc.data().timeStamp;
                      const formattedDate = format(new Date(timeStamp.seconds * 1000), 'dd-MM-yyyy');
      
                      // retrieve the senderUid value from the current document
                      const senderUid = doc.data().senderUid;
                      const imageUrl = doc.data().image_url[0];

                      // Construct the full URL to the image in storage
                      const imageStorageRef = ref(storage, `${imageUrl}`);
                      const imageUrlFull = await getDownloadURL(imageStorageRef);
              
                      // Add the imageURL to the document data
                      return { id: doc.id, ...doc.data(), date: formattedDate, imageUrl: imageUrlFull };
                  })
              );
      
              setData(list);
              if (hourglassElement) {
                  hourglassElement.style.display = 'none';
              }
          } catch (err) {
              console.error(err);
              if (hourglassElement) {
                  hourglassElement.style.display = 'none';
              }
          }
      }

        const Sellaccount = (e)=>{
            navigate('/sell_account');
        }


        const handleDeleteItem = async (docId, index) => {
          try {
            const bazarCollectionRef = collection(db2, 'Sell_account');
            const hourglassElement = document.querySelector('.loader');
            hourglassElement.style.display = 'inline-block';
        
            // Récupérer le document
            const docRef = doc(bazarCollectionRef, docId);
            const docSnapshot = await getDoc(docRef);
        
            if (docSnapshot.exists()) {
              const docData = docSnapshot.data();
              // Récupérer les URL des images à supprimer
              const imageUrlsToDelete = docData.image_url || [];
              console.log(imageUrlsToDelete);
        
              // Supprimer le document
              await deleteDoc(docRef);
        
              // Supprimer l'élément du tableau de données
              const newData = [...data];
              newData.splice(index, 1);
              setData(newData);
        
              // Fermer le modal de suppression
              handleCloseModalDelete(index);
        
              // Supprimer les objets dans le stockage
              await Promise.all(imageUrlsToDelete.map(async (imageUrl) => {
                await deleteObject(ref(storage, imageUrl));
              }));
        
              // Afficher un message de succès
              toast.success(t('ann_sup'), {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        
              // Recharger la page
              window.location.reload();
            } else {
              console.error("Document not found");
              toast.error(t('err_sup'), {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          } catch (error) {
            console.error(error);
        
            // Gérer les erreurs
            toast.error(t('err_sup'), {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } finally {
            toast.success(t('succes'), {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            // Cacher l'indicateur de chargement, même en cas d'erreur
            hourglassElement.style.display = 'none';
          }
        };


        const OpenDelete = (index) => {
          const newModalOpenDelete = [...modalOpenDelete];
          newModalOpenDelete[index] = true;
          setModalOpenDelete(newModalOpenDelete);
        }
        const handleCloseModalDelete = (index) => {
          const newModalOpenDelete = [...modalOpenDelete];
          newModalOpenDelete[index] = false;
          setModalOpenDelete(newModalOpenDelete);
        };
    return (
        <div className='acnt_div'>
            <h1>Account Nostale</h1>
            <div className='select_btn'>
              <Select styles={customStyles} options={Serveur} placeholder={t('Serveur')} onChange={handleSelectChange}/>
              <button className='creat_post' onClick={Sellaccount}>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"></path></svg> Create
                </span>
              </button>
            </div>
            <br/>
            <p className='text_danger'>❗{t('midlaner')}❗</p>
            <div className='result-account_account'>

            <div className="loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>

            {currentDocuments.map((doc,index) => (
              <div>
        <div key={doc.id} className="card_account">
          <img src={doc.imageUrl} alt={doc.classe} />
          <div className="card__content_account">
            <p className="card__title_account"> <ImageComponent doc={doc}/> lvl : {doc.lvl} ( + {doc.lvl_héro}) <p className='price_cardo'>{doc.prix}  €</p></p>
            <p className="card__description_account">{doc.commentaire}</p>
            <button className="card__button_account"  onClick={() => handleVoirDetailClick(doc)}>voir détail</button>
            <button className="card__button_account secondary" onClick={() => handleBtnShowClick(index, doc)}>Voir la page</button>
          </div>
          
        </div>
            {doc.senderUid === uid ? (
        <button className="tooltip buttonis cart_btnn" onClick={() => OpenDelete(index)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" height="25" width="25">
            <path fill="#6361D9" d="M8.78842 5.03866C8.86656 4.96052 8.97254 4.91663 9.08305 4.91663H11.4164C11.5269 4.91663 11.6329 4.96052 11.711 5.03866C11.7892 5.11681 11.833 5.22279 11.833 5.33329V5.74939H8.66638V5.33329C8.66638 5.22279 8.71028 5.11681 8.78842 5.03866ZM7.16638 5.74939V5.33329C7.16638 4.82496 7.36832 4.33745 7.72776 3.978C8.08721 3.61856 8.57472 3.41663 9.08305 3.41663H11.4164C11.9247 3.41663 12.4122 3.61856 12.7717 3.978C13.1311 4.33745 13.333 4.82496 13.333 5.33329V5.74939H15.5C15.9142 5.74939 16.25 6.08518 16.25 6.49939C16.25 6.9136 15.9142 7.24939 15.5 7.24939H15.0105L14.2492 14.7095C14.2382 15.2023 14.0377 15.6726 13.6883 16.0219C13.3289 16.3814 12.8414 16.5833 12.333 16.5833H8.16638C7.65805 16.5833 7.17054 16.3814 6.81109 16.0219C6.46176 15.6726 6.2612 15.2023 6.25019 14.7095L5.48896 7.24939H5C4.58579 7.24939 4.25 6.9136 4.25 6.49939C4.25 6.08518 4.58579 5.74939 5 5.74939H6.16667H7.16638ZM7.91638 7.24996H12.583H13.5026L12.7536 14.5905C12.751 14.6158 12.7497 14.6412 12.7497 14.6666C12.7497 14.7771 12.7058 14.8831 12.6277 14.9613C12.5495 15.0394 12.4436 15.0833 12.333 15.0833H8.16638C8.05588 15.0833 7.94989 15.0394 7.87175 14.9613C7.79361 14.8831 7.74972 14.7771 7.74972 14.6666C7.74972 14.6412 7.74842 14.6158 7.74584 14.5905L6.99681 7.24996H7.91638Z" clip-rule="evenodd" fill-rule="evenodd"></path>
          </svg>
          <span className="tooltiptext">remove</span>
        </button>
            ) : <></>}
             {modalOpenDelete[index] && (
                                    <> 
                                     <div
                                      className="modal-overlay"
                                      onClick={() => handleCloseModalDelete(index)}
                                    ></div>
                                    <div key={doc.id} className="modal_del">

                                    <div className="card">
                                    <div className="card-content">
                                      <p className="card-heading">Delete file?</p>
                                      <p className="card-description">{t('real_sup')}</p>
                                    </div>
                                    <div className="card-button-wrapper">
                                      <button className="card-button secondary" onClick={() => handleCloseModalDelete(index)}>Cancel</button>
                                      <button className="card-button primary" onClick={() => handleDeleteItem(doc.id, index, doc.imageUrl)}>Delete</button>
                                    </div>
                                    <button className="exit-button" onClick={() => handleCloseModalDelete(index)}>
                                      <svg height="20px" viewBox="0 0 384 512">
                                        <path
                                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
                                    </div>
                                    </>
                                  )}
        {overlayVisible && (
                                            <div className='modal-overlay'>
                                              <div className='PagePlayer'>  
                                                <div className='topeur'>
                                                <button className='close__pagplayer' onClick={handleBtnMasqClick}> 
                                                  <span className="material-symbols-outlined">close</span>
                                                 </button> 
                                                  <h1>{pseudo}</h1>
                                                  <div className='Info_playeer'>
                                                    <img src={pictureSpUserUrl} className="Picture_profil player__src" alt="Profile"></img>
                                                    <CountriePlayer country={country} className="picture_countr" />
                                                    <div className='discrd playyyer'>
                                                    Discord : {discord} 
                                                    </div>

                                                  </div>
                                                  <div className='Commantère'>
                                                  <p className='Com_playeur'>{comment}</p>
                                                  <div className='gif_note'>
                                                  <div >  
                                                    <div className='Lik'>
                                                    <div className="like-dislike-container">
                                                          <div className="tool-box">
                                                            <button className="btn-close">×</button>
                                                          </div>
                                                          <p className="text-content">What did you think<br/>of this Guys?</p>				
                                                          <div className="icons-box">
                                                            <div className="icons">
                                                              <label className="btn-label" for="like-checkbox">
                                                                <span className="like-text-content">{likeprofil}</span>
                                                                <input className="input-box"  onClick={() => handleLike(index, data)} id="like-checkbox" type="checkbox"/>
                                                                <svg className="svgs" id="icon-like-solid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"></path></svg>
                                                                <svg className="svgs" id="icon-like-regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.1s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z"></path></svg>
                                                                <div className="fireworks">
                                                                  <div className="checked-like-fx"></div>
                                                                </div>
                                                              </label>
                                                            </div>
                                                            <div className="icons">
                                                              <label className="btn-label" for="dislike-checkbox">
                                                                <input onClick={() => handledisLike(index, data)} className="input-box" id="dislike-checkbox" type="checkbox"/>
                                                                <div className="fireworks">
                                                                  <div className="checked-dislike-fx"></div>
                                                                </div>
                                                                <svg className="svgs" id="icon-dislike-solid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"></path></svg>
                                                                <svg className="svgs" id="icon-dislike-regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M323.8 34.8c-38.2-10.9-78.1 11.2-89 49.4l-5.7 20c-3.7 13-10.4 25-19.5 35l-51.3 56.4c-8.9 9.8-8.2 25 1.6 33.9s25 8.2 33.9-1.6l51.3-56.4c14.1-15.5 24.4-34 30.1-54.1l5.7-20c3.6-12.7 16.9-20.1 29.7-16.5s20.1 16.9 16.5 29.7l-5.7 20c-5.7 19.9-14.7 38.7-26.6 55.5c-5.2 7.3-5.8 16.9-1.7 24.9s12.3 13 21.3 13L448 224c8.8 0 16 7.2 16 16c0 6.8-4.3 12.7-10.4 15c-7.4 2.8-13 9-14.9 16.7s.1 15.8 5.3 21.7c2.5 2.8 4 6.5 4 10.6c0 7.8-5.6 14.3-13 15.7c-8.2 1.6-15.1 7.3-18 15.1s-1.6 16.7 3.6 23.3c2.1 2.7 3.4 6.1 3.4 9.9c0 6.7-4.2 12.6-10.2 14.9c-11.5 4.5-17.7 16.9-14.4 28.8c.4 1.3 .6 2.8 .6 4.3c0 8.8-7.2 16-16 16H286.5c-12.6 0-25-3.7-35.5-10.7l-61.7-41.1c-11-7.4-25.9-4.4-33.3 6.7s-4.4 25.9 6.7 33.3l61.7 41.1c18.4 12.3 40 18.8 62.1 18.8H384c34.7 0 62.9-27.6 64-62c14.6-11.7 24-29.7 24-50c0-4.5-.5-8.8-1.3-13c15.4-11.7 25.3-30.2 25.3-51c0-6.5-1-12.8-2.8-18.7C504.8 273.7 512 257.7 512 240c0-35.3-28.6-64-64-64l-92.3 0c4.7-10.4 8.7-21.2 11.8-32.2l5.7-20c10.9-38.2-11.2-78.1-49.4-89zM32 192c-17.7 0-32 14.3-32 32V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32z"></path></svg>
                                                                <span className="dislike-text-content">{dislikeprofil}</span>
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </div>
                                                    </div>
                                                  </div>
                                                  <img  className="real_gif_player" src={gif_user_url}></img>
                                                  </div>
                                                  </div>
                                                </div>
                                              </div>
                                              
                                            </div>
                                          )}
                                          
        </div>
      ))}

      {isModalOpen && selectedDocument && (
        <ModalOverlay
          document={selectedDocument}
          onClose={closeModal}
        />
        
      )}

            </div>
            <div className="paginations">
              {data.length > 0 &&
                [...Array(Math.min(9, Math.ceil(data.length / documentsPerPage))).keys()].map((pageNumber) => (
                  <button key={pageNumber} onClick={() => paginate(pageNumber + 1)}>
                    {pageNumber + 1}
                  </button>
                ))}
              {currentPage < Math.ceil(data.length / documentsPerPage) && (
                <button onClick={() => paginate(currentPage + 1)}>{">"}</button>
              )}
            </div> 
            <div className="footerus_acnt">
                  <p>{t('apropos')}</p>
                  <p>facebook : <a className='fb_link' href='https://www.facebook.com/people/BazNos/100095523103918/'>BazNos FB</a></p>
        <p>{t('reserv')}</p>
        <p>{t('©')}</p>
    </div>
            <ToastContainer />
        </div>
    );
};
const ModalOverlay = ({ document, onClose }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [images, setImages] = useState([]);

  useEffect(() => {
  const fetchImages = async () => {
    try {
     
      const imagePromises = (document.image_url ?? []).map(async (imagePath) => {
        const imageStorageRef = ref(storage, imagePath);
        const imageUrl = await getDownloadURL(imageStorageRef);
       
        return imageUrl;
      });
  
      const imageUrls = await Promise.all(imagePromises);
      
      setImages(imageUrls);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  fetchImages();
}, [document.imagesAccount]);
  // You can customize the content of the modal based on the document data
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {/* Display the document details here */}
        <h3><ImageComponent doc={document}/> lvl : {document.lvl} ( + {document.lvl_héro})|| {document.prix}  €</h3>
        <button id="close-crosshair-button" onClick={onClose}> 
         <span className="material-symbols-outlined">close</span>
        </button> 
        <div className='pay_info'>
            <div className='comm_card'>
            <p className='h1_card'>Commentaire : </p>
            <div>
            <p className='comm_cardo'>{document.commentaire}</p>
            </div>
          </div>
                    <div>
                  <p>
                  CarteB:
                    {document.cb ? (
                      <span className="material-symbols-outlined text_succes">check</span>
                    ) : (
                      <span className="material-symbols-outlined text_errir">close</span>
                    )}
                  </p>
                    <p> PayPal:
                    {document.paypal ? (
                      <span className="material-symbols-outlined text_succes">check</span>
                    ) : (
                      <span className="material-symbols-outlined text_errir">close</span>
                    )}
                 </p>
                 </div>
                 <div>
                    <p> Paysafecard: 
                     {document.psf ? (
                      <span className="material-symbols-outlined text_succes">check</span>
                    ) : (
                      <span className="material-symbols-outlined text_errir">close</span>
                    )}
                  </p>
                    <p> Crypto: 
                    {document.crypto ? (
                      <span className="material-symbols-outlined text_succes">check</span>
                    ) : (
                      <span className="material-symbols-outlined text_errir">close</span>
                    )}
                  </p>
                  </div>
                  </div>
                  <div className='picture_acnt'>
                  {images.map((imageUrl, index) => (
            <img key={index} src={imageUrl}  onClick={() => setSelectedImageIndex(index)} alt={`Image ${index + 1}`} className='img_acnt_modal'/>
          ))}
                      {selectedImageIndex !== null && (
              <div className='full-size-image-overlay' onClick={() => setSelectedImageIndex(null)}>
                <img
                  src={images[selectedImageIndex]}
                  alt={`Image ${selectedImageIndex + 1}`}
                  className='full-size-image'
                />
                
              </div>
            )}
          </div>
        {/* Add other details as needed */}
      </div>
    </div>
  );
};
export default AccountNst;