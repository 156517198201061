import React,{useState} from 'react';
import ImageSp1 from "../../nostale/all_armure/mage/80-95/80.png"
import ImageSp2 from "../../nostale/all_armure/mage/80-95/85appara.png"
import ImageSp3 from "../../nostale/all_armure/mage/80-95/85vole.png"
import ImageSp4 from "../../nostale/all_armure/mage/80-95/85fake.png"
import ImageSp5 from "../../nostale/all_armure/mage/80-95/88flamboy.png"
import ImageSp6 from "../../nostale/all_armure/mage/80-95/88vole.png"
import ImageSp7 from "../../nostale/all_armure/mage/80-95/88hono.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
import ImageSp8 from "../../nostale/all_armure/mage/80-95/90.png"
const A80_95 = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
      <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("robe_80", ImageSp1)}><img src={ImageSp1}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("robe_85", ImageSp2)}><img src={ImageSp2} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("robe_85vole", ImageSp3)}><img src={ImageSp3} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("robe_85fake", ImageSp4)}><img src={ImageSp4} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("robe_88inf", ImageSp5)}><img src={ImageSp5} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("robe_88vole", ImageSp6)}><img src={ImageSp6} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("robe_88hono", ImageSp7)}><img src={ImageSp7} ></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("robe_90", ImageSp8)}><img src={ImageSp8} ></img></button>
      
        </div>
    );
};

export default A80_95;