import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword,signInWithPopup,updateProfile,sendEmailVerification } from 'firebase/auth';
import { setDoc, doc,getDoc,serverTimestamp,getDocs,collection,query,where } from 'firebase/firestore';
import { db2,auth,provider } from '../../firebase-config.js';
import { AuthContext } from '../../Context/AuthContext.js';
import { ToastContainer,toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { GoogleAuthProvider } from 'firebase/auth';
import imgload from "../../photo_anex/load.gif" ;
const SignUpModal = (props) => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState('CZ');
  const {setModalState} = props;
  const [isChecked, setIsChecked] = useState(false);
  const { dispatch } = useContext(AuthContext); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const pdpSource ='pdp/user_default.png'
  const gifSource ='gif/user_default.gif'
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
   const hourglassElement = document.querySelector('.loader');
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleForm = async (e) => {
  e.preventDefault();
  setIsLoading(true);
  setError('');
  setShowErrorMessage(false); // Réinitialise le message d'erreur à chaque soumission

  if (!isChecked) {
    setShowErrorMessage(true);
    setIsLoading(false);
    return;
  }
  const passwordInput = formRef.current['signUpPwd'];
  const repeatPasswordInput = formRef.current['repeatPwd'];

  if (passwordInput.value !== repeatPasswordInput.value) {
    // Show an error message if the passwords don't match
    toast.warn(t('mdr_false'), {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

    setIsLoading(false);
    return;
  }
  const usersRef = collection(db2, 'users');
  const querySnapshot = await getDocs(query(usersRef, where('displayName', '==', displayName)));
  if (!querySnapshot.empty) {
    // Afficher un message d'erreur si le pseudo existe déjà
    toast.warn (t('pseu_exi'), {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
    return;
  }
  if (password.length < 6) {
    toast.warn (t('mdp_carac'), {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      });
  
    setIsLoading(false);
    return;
  }
  try { 
    hourglassElement.style.display = 'inline-block';
    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    await setDoc(doc(db2, 'users', user.uid), {
      email: email,
      displayName: displayName,
      pdp_src: pdpSource,
      gif_src: gifSource,
      country:selectedLanguage,
      timeStamp: serverTimestamp(),
      Nosdollar: 0,
    });
    await setDoc(doc(db2, 'score', user.uid), {
      like:[],
      dislike:[],
    });
    dispatch({ type: 'LOGIN', payload: user });
    await updateProfile(auth.currentUser, { displayName });
    formRef.current.reset();
    await sendEmailVerification(auth.currentUser); 
     hourglassElement.style.display = 'none';
    navigate('/new_account');
  } catch (err) {
    setError(err.message);
  }
  
  setIsLoading(false);
};

const handleGoogleSignIn = async () => {
  try {
    const pdpSource = 'pdp/user_default.png'
    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    const user = result.user;

    // Check if user is signing in for the first time
    const userRef = doc(db2, 'users', user.uid);
    const docSnap = await getDoc(userRef);
    if (!docSnap.exists()) {
      // User is signing in for the first time
      await setDoc(userRef, {
        email: user.email,
        displayName: user.displayName,
        pdp_src: pdpSource,
        gif_src: gifSource,
        timeStamp:serverTimestamp(),
        Nosdollar: 0 
      });
      await setDoc(doc(db2, 'score', user.uid), {
        like:[],
        dislike:[],
      });
    }
    dispatch({ type: "LOGIN", payload: user })
    navigate("/nosbaz");
  } catch (error) {
    setError(true);
  }
};
  return (
    <>
      <div className='Modal_all'>

        <div className="Overlay" ></div>

          <div className='Signup_Modal' style={{minnWidth: "400px"}}>

            <div className='modal-dialog'>

              <div className='modal-content_sign'>
              <button onClick={() => setModalState("close")} className='close_btn'>
                  <span className="material-symbols-outlined">
                  close
                  </span>
                </button>
                <div className='header'>
                  <h5 className='modal-title'>Sign Up
                  </h5>
                </div>
                
                <div className='modal-body'>

                  <form className='sign-up-form' onSubmit={handleForm} ref={formRef}>
                    
                    <div className="cat">
                      <div className="sous-cat">
                       <div className="sous-sous-cat">

                       <div className="input-group">
                    <label className="label" htmlFor='signUpEmail'>{t('email')}</label>
                    <input autocomplete="off"    
                    className="input"                    
                     required
                        type='email'
                        id='signUpEmail'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}/>
                    <div></div></div>

                    <div className="input-group">
                    <label className="label" htmlFor='signUpPwd'>{t('pdw')} </label>
                    <input autocomplete="off"    
                    className="input"                    
                     required
                     
                     type='password'
               
                     id='signUpPwd'
                     value={password}
                     onChange={(e) => setPassword(e.target.value)}/>
                    <div></div></div>

                      </div>
                      <div className="sous-sous-cat">
                      <div className="input-group">
                    <label className="label"for='repeatPwd'>{t('repeat_pdw')}</label>
                    <input autocomplete="off"    
                    className="input"                    
                     required
                     name="Pwd"
                     
                     type="password"
                     
                     id="repeatPwd"/>
                    <div>
                    <p className='texte-danger'> </p></div></div>

                    <div className="input-group">
                    <label  className='label' for='repeatPwd'>{t('pseudo')}</label>
                    <input autocomplete="off"    
                    className="input"                    
                    name="Pseudo"
                    required
                    type="Pseudo"
                    id="Pseudo"
                    onChange={(e) => setDisplayName(e.target.value)}/>
                    <div>
                    <p className='texte-danger'> </p></div></div>

                    </div>
                    </div>
                    <div className='Language_insc'>
                    <div className='mb-3'>
                    <label className='label_form' htmlFor='languageSelect'>
                      Language: 
                      <select id='languageSelect' value={selectedLanguage} onChange={handleLanguageChange}>
                        <option value='CZ'>Czech</option>
                        <option value='DE'>German</option>
                        <option value='US'>English </option>
                        <option value='ES'>Spanish</option>
                        <option value='FR'>French</option>
                        <option value='HK'>Hong Kong</option>
                        <option value='IT'>Italian</option>
                        <option value='PL'>Polish</option>
                        <option value='TR'>Turkish</option>
                      </select>
                    </label>
                      <p className='texte-danger'> </p>
                    
                    </div>
                    </div>
                    </div>
                    <div className='conditionq'>
                      <h4 className='condition_uti'>{t('condition_uti')}</h4>
                      <p className='codi_1'>{t('codi_1')}</p>
                      <p className='codi_2'>{t('codi_2')}</p>
                     <p className='codi_3'>{t('codi_3')}
                     </p>
                     <p className='codi_4'>{t('codi_4')}</p>
                    </div>
                    <div className='form-check custom-checkbox'>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        id='acceptTermsCheckbox'
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <label className='form-check-label' htmlFor='acceptTermsCheckbox'>
                      {t('form-check-label')}
                      </label>
                    </div>
                    {showErrorMessage && (
                      <p className='text-danger accep_condi'>
                        {t('accep_condi')}
                      </p>
                    )}
                    <div className='signuppp'>
                    <button className="btn-class-name">
                    <span>{t('Sumbit')}</span>
                    <svg viewBox="0 0 320 512" height="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z">
                    </path>
                    </svg>
                  </button>
                    
                    <button className="buttonyy"onClick={handleGoogleSignIn}>
                          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" viewBox="0 0 256 262">
                          <path fill="#4285F4" d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"></path>
                          <path fill="#34A853" d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"></path>
                          <path fill="#FBBC05" d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"></path>
                          <path fill="#EB4335" d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"></path>
                        </svg>
                          Continue with Google
                        </button>
                    </div>




                  </form>
                </div>

              </div>
            </div>
            <div className="loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
          </div>
          <ToastContainer/>
      </div>
    </>
  );
};
export default SignUpModal;