import React from 'react';
import Navbar from '../Components/Navbar';
import Vente_bazar from '../Components/Ventebazar';

const Bazar_vente = () => {
    return (
        <div>
            <Navbar/>
            <Vente_bazar/>
        </div>
    );
};

export default Bazar_vente;