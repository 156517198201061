import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const RunePvp = (props) => {
  const [valeurSelect, setValeurSelect] = useState('def_en_pvp');
  const [valeurInput, setValeurInput] = useState(0);
  const { t } = useTranslation();
  const [check, setCheck] = useState(false);

  const checkChanges = () => {
    if (valeurInput !== 0 && valeurInput !== null) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  };

  const confirm_rune = document.querySelector('.confirm_rune');

  if (check) {
    confirm_rune.style.display = 'none';
  } else {
    confirm_rune.style.display = 'flex';
  }

  function handleButtonClick() {
    // Appeler props.onValueChange avec les valeurs actuelles de select et de l'input
    checkChanges()
    props.onValueChange(props.index, valeurSelect, valeurInput);
  }

  const arme_pvp = [
    'def_en_pvp', 'feu_enn_pvp', 'eau_enn_pvp', 'lulu_enn_pvp', 'obscu_enn_pvp',
    'rez_enn_pvp', 'pro_pvp', 'deg_pvp', 'no_mp_pvp'
  ];
    return (
        <div>
             <select className='select-add armec' value={valeurSelect} disabled={check}  onChange={e=>setValeurSelect(e.target.value)}>
            <option value={arme_pvp[0]}>{t('def_en_pvp')}</option>
            <option value={arme_pvp[1]}>{t('feu_enn_pvp')}</option>
            <option value={arme_pvp[2]}>{t('eau_enn_pvp')}</option>
            <option value={arme_pvp[3]}>{t('lulu_enn_pvp')}</option>
            <option value={arme_pvp[4]}>{t('obscu_enn_pvp')}</option>
            <option value={arme_pvp[5]}>{t('rez_enn_pvp')}</option>
            <option value={arme_pvp[6]}>{t('pro_pvp')}</option>
            <option value={arme_pvp[7]}>{t('deg_pvp')}</option>
            <option value={arme_pvp[8]}>{t('no_mp_pvp')}</option>
          </select>
          <input type="number" className="input_1" value={valeurInput} disabled={check} onChange={e=>setValeurInput(e.target.value)} min='0'/>

          {!check ? <button type='button' className='btn_rune' onClick={handleButtonClick}>
        <span className="material-symbols-outlined">
          done_outline
        </span>
      </button>
        :
        <></>
        }
        </div> 
    );
};

export default RunePvp;