import React,{useState} from 'react';

import imageSp1 from "../../nostale/Divers/Matériaux_Amélio/Enclume.png"
import imageSp2 from "../../nostale/Divers/Matériaux_Amélio/Marteau.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
import imageSp3 from "../../nostale/Divers/Matériaux_Amélio/resu.png"
import imageSp4 from "../../nostale/Divers/Matériaux_Amélio/vernis.png"

const Matériaux_amelio = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
        <div className='propose-item' style={navStyle3}>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("Enclume", imageSp1)}><img src={imageSp1}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("Marteau", imageSp2)}><img src={imageSp2}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("resu", imageSp3)}><img src={imageSp3}></img></button>
        <button type='button' id="vente_sp1"  onClick={() => handleClick("vernis", imageSp4)}><img src={imageSp4}></img></button>
        </div>
    );
};

export default Matériaux_amelio;