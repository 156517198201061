import React, {  useEffect,useState, useContext } from 'react';
import Select from 'react-select';
import Navbar from './Navbar';
import { db2, storage,auth } from '../firebase-config';
import {v4} from "uuid";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes  } from "firebase/storage";
import { doc, serverTimestamp, setDoc,getDocs,query,collection,where,getDoc,updateDoc,FieldValue } from "firebase/firestore"; 
import { useTranslation } from 'react-i18next';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imgload from "../photo_anex/load.gif" ;
import aventurier from "../nostale/anex/aventurier.png"
import archer from "../nostale/anex/archer.png"
import escri from "../nostale/anex/escri.png"
import mage from "../nostale/anex/mage.png"
import artist_martial from "../nostale/anex/artist-martial.png"
import { te } from 'date-fns/locale';
const Sell_account = () => {
    const { t } = useTranslation();
    const [uid_form,setUid_from] = useState(v4())
    const [serveur,setServeur]= useState('');
    const [picture_spT,setPicture_spT]=useState(0)
    const [lvl,setLvl]= useState(0);
    const [lvlhero,setLvlhero]= useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [otherFieldsDisabled, setOtherFieldsDisabled] = useState(true);
    const [price, setPrice] = useState(0);
    const [comment, setComment] = useState('');
    const [picture_sp,SetpictureSp] = useState({})

    const [paymentMethod, setPaymentMethod] = useState('');
    const [cb,setCB]=useState(false);
    const [paypal,setPaypal]=useState(false);
    const [psf,setPsf]=useState(false);
    const [crypto,setCrypto]=useState(false);

    const { currentUser } = useContext(AuthContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [nosdollarPay,setNosDollarPay]=useState()
    const displayNamer = currentUser.displayName ;
  const [showModal, setShowModal] = useState(false);
  const [texteConfirme, setTextConfirm] = useState(`${t('coutera')} 3 NosCoin.`);
  const[eurogain,SetEuroGain]=useState(0)
  const hourglassElement = document.querySelector('.loader');
  const [selectedImages, setSelectedImages] = useState([]);
const [selectedImageIndex, setSelectedImageIndex] = useState(null);
const [classe,setClasse]=useState('')
  const [spModals, setspModals] = useState({
    modalsellsp:false
  })



    const navigate = useNavigate();
    

    const handlePictureChange = (e) => {
      const newImage = e.target.files[0];

      // Check if the selectedImages array already contains the new image
      if (!selectedImages.some((image) => image.name === newImage.name)) {
        // Limit the number of selected images to 4
        if (selectedImages.length < 4) {
          setSelectedImages([...selectedImages, newImage]);
        }
      }
    
      setModalOpen(true);
      setPicture_spT(1);
    };
    const removeSelectedImage = (index) => {
      const updatedImages = [...selectedImages];
      updatedImages.splice(index, 1);
      setSelectedImages(updatedImages);
    };
    const hideModal = () => {
        setShowModal(false);
        setspModals({ modalsellsp: false });
      };

      const closeModal = () => {
        setModalOpen(false);
      };
    const tryToPay = async (e) => {
        if(!classe){
          toast.warn(t("classe"), {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      if(!lvl){
        toast.warn(t("lvl"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return
      }
      if(!lvlhero){
        toast.warn(t("lvl héro"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return
      }
      if(!serveur){
        toast.warn(t("take_serv"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return
      }
      if(!price){
        toast.warn(t("take_price"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return
      }


     
      
        const executeSetDoc = async () => {

          const imageUrls = [];
          const uploadPromises = [];
          for (let index = 0; index < selectedImages.length; index++) {
            hourglassElement.style.display = 'inline-block';
            const image = selectedImages[index];
            const storageRef = ref(storage, `imagesAccount/${image.name + uid_form}`);
            const uploadTask = uploadBytes(storageRef, image);
            uploadPromises.push(uploadTask);
          }
      
          // Wait for all upload promises to complete
          await Promise.all(uploadPromises);
      
          // Retrieve Nosdollar value
          const docSnapshot = await getDoc(doc(db2, "users", currentUser.uid));
          const data = docSnapshot.data();
          const nosDollarValue = data.Nosdollar || 0;
      
          // Loop through selectedImages to get image URLs
          for (let index = 0; index < selectedImages.length; index++) {
            const image = selectedImages[index];
            const imageUrl = `imagesAccount/${image.name + uid_form}`;
            imageUrls.push(imageUrl);
          }
        
          if (nosDollarValue >= nosdollarPay ) {

            // Décrémente la valeur de Nosdollar dans la table par la valeur de nosdollarPay
            const updatedNosDollarValue = nosDollarValue - nosdollarPay;
            await updateDoc(doc(db2, "users", currentUser.uid), { Nosdollar: updatedNosDollarValue });
            
            await setDoc(doc(db2, "Sell_account", uid_form), {
              senderUid: currentUser.uid,
              classe: classe,
              prix: price,
              lvl:lvl,
              lvl_héro:lvlhero,
              serveur : serveur,
              currentUserUid :currentUser.uid,
              timeStamp: serverTimestamp(),
              image_url : imageUrls,
              Pseudonim : displayNamer,
              commentaire : comment,
              uid_form : uid_form ,
              FirstPost: currentUser.uid,
              cb: cb,
              paypal: paypal,
              psf: psf,
              crypto: crypto
              
            });
      

            hourglassElement.style.display = 'none';
            setShowModal(false);
          
            window.location.reload();
            toast.success(t("posted"), {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            return; // Arrête l'exécution de la fonction ici
          } else {
            toast.error(t("mor_nos"), {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            setShowModal(false);
            navigate('/goldnostale')
            hourglassElement.style.display = 'none';
            return; // Arrête l'exécution de la fonction ici
          }
        };
        
        await executeSetDoc();
      };



    const BuyGold = (e)=>{
        navigate('/account');
    }


    const uploadImage = async (e) => { 
  
      if (picture_spT === 0){
        toast.error(t("Take picture of item"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      } 
    
      const executeSetDoc = async () => {
        const imageUrls = [];
        const uploadPromises = [];
        for (let index = 0; index < selectedImages.length; index++) {
          hourglassElement.style.display = 'inline-block';
          const image = selectedImages[index];
          const storageRef = ref(storage, `imagesAccount/${image.name + uid_form}`);
          const uploadTask = uploadBytes(storageRef, image);
          uploadPromises.push(uploadTask);
        }
    
        // Wait for all upload promises to complete
        await Promise.all(uploadPromises);
    
        // Retrieve Nosdollar value
        const docSnapshot = await getDoc(doc(db2, "users", currentUser.uid));
        const data = docSnapshot.data();
        const nosDollarValue = data.Nosdollar || 0;
    
        // Loop through selectedImages to get image URLs
        for (let index = 0; index < selectedImages.length; index++) {
          const image = selectedImages[index];
          const imageUrl = `imagesAccount/${image.name + uid_form}`;
          imageUrls.push(imageUrl);
        }

        
        // Vérifier si la valeur arrondie est inférieure à 0.1 et ajuster si nécessaire
        const finalAmountNosdollar = 3;
        
        setNosDollarPay(finalAmountNosdollar);
        const confirmText = `${t('coutera')} ${finalAmountNosdollar} NosCoin.`;
        setTextConfirm(confirmText);
        setShowModal(true);
    }
    
      await executeSetDoc();
    };  

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setServeur(selectedOption.value);
        if (['Valehir', 'Alzanor', 'Dragonveil', 'Cosmos','Jotunheim']) {
          setOtherFieldsDisabled(false);
        } else {
          setOtherFieldsDisabled(true);
        }
      }

    const Serveur = [
        { value: 'Valehir', label: 'Valehir' },
        { value: 'Alzanor', label: 'Alzanor' },
        { value: 'Dragonveil', label: 'Dragonveil' },
        { value: 'Cosmos', label: 'Cosmos' },
        { value: 'Jotunheim', label: 'Jotunheim' }
      ];
      const customStyles = {
        container: (provided) => ({
          ...provided,
          width: '150px',
          fontSize: '15px',
          position: 'relative',
          cursor: 'pointer',
          display: 'inline-block' // add this to align with the original .list-choice
        }),
        control: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          color: '#FFF',
          textShadow: '0 1px 0 rgba(144, 142, 142, 0.66)',
          borderRadius: '0.2em',
          border: 'none',
          cursor: 'pointer',
          display: 'flex', // center selected value
          justifyContent: 'center', // center selected value
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          borderRadius: '0.2em',
          overflow: 'hidden'
        }),
        option: (provided) => ({
          ...provided,
          backgroundColor: 'rgba(144, 142, 142, 0.66)',
          color: '#FFF',
          cursor: 'pointer'
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#FFF',
          background: 'rgb(116,214,142)',
          background: 'linear-gradient(102deg, rgba(116,214,142,1) 0%, rgba(116,214,142,0.04525560224089631) 59%)',
          height: '39px',
          borderRadius: '2.4px 6px 6px 2.4px',
          marginLeft:'-8px',
          width: '113px', // fill width of control
          display: 'flex',
          alignItems: 'center',
          marginTop: '-1.8px',
          marginBottom: '-2px',
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#FFF'
        })
      };
      const handleClick = (value) => {
        setClasse(value);
      };

    return (
        <div>
             <Navbar/>
            <div className='buy_goldnos'>
                <h3>{t('titreSell_gold')}</h3>
                <button className='addpost'onClick={BuyGold} >{t('Annonce_buy')}</button>
            </div>
            
            <div className='form_sell'>
                
                    <h1>{t('form')}</h1>
                    
                    <form >
                        <div className='form_sellnd'>
                        <div className='sell_1'>
                    <div>
                        <label>{t('Serveur')} : </label>
                        <Select styles={customStyles} options={Serveur} placeholder={t('Serveur')} onChange={handleSelectChange} />
                    </div>
                    <h5>Main character</h5>
                    <div className="radio-input_acnt">
                      
                  <input value="value-1" name="value-radio_acnt" id="value-1" type="radio" onClick={() => handleClick('aventurier')}/>
                  <label for="value-1"><img src={aventurier} /></label>
                  <input value="value-2" name="value-radio_acnt" id="value-2" type="radio"  onClick={() => handleClick('archer')}/>
                  <label for="value-2"><img src={archer}/></label>
                  <input value="value-3" name="value-radio_acnt" id="value-3" type="radio" onClick={() => handleClick('escri')}/>
                  <label for="value-3"><img src={escri} /></label>
                  <input value="value-5" name="value-radio_acnt" id="value-5" type="radio"  onClick={() => handleClick('mage')}/>
                  <label for="value-5"><img src={mage}/></label>
                  <input value="value-4" name="value-radio_acnt" id="value-4" type="radio" onClick={() => handleClick('artist_martial')}/>
                  <label for="value-4"><img src={artist_martial} /></label>

                </div>
                <div className='div_lvl'>
              
                <div className='raritynp'>
                <p>Level  :  </p>
                <input type="number" className="Rarity" onChange={e=>setLvl(e.target.value)} min="0" max="99" step="0" value={lvl}/>
                </div>
                <div className='raritynp'>
                <p>Level héroique :  </p>
                <input type="number" className="Rarity" onChange={e=>setLvlhero(e.target.value)} min="0" max="90" step="0" value={lvlhero}/>
                </div>
                </div>
                <div className='pricends'>
                        <label>{t('prixsell')} €</label>
                        <input type="number" value={price} onChange={(e) => setPrice(e.target.value)} min="0" step="0" />

                    </div>
              
                    <div id='description_account'>
                      <p>Description : </p>
                      <textarea  type='text' id='description_accountss'  placeholder='Commentaire' value={comment} onChange={(e) => setComment(e.target.value)}/>
                  

                  </div>
                    <div className='center'>
                    <p className='text_info'>{price}€ {t('total')}</p>
                    </div>
                    </div>
                    <div className='sell_2'>
                    <label className='paiementnd'>{t('Paiement')}</label>
                <form action="" className="containerpo">

              <input className="input-btn"  type="checkbox" id="cb" name="valueIs-radio"  checked={cb} onChange={(e) => setCB(e.target.checked)}/>
              <label className="neon-btn" for="cb">
                  <span className="span"></span>
                  <span className="txt">{t('CarteB')}</span>
              </label>

              <input className="input-btn"  type="checkbox" id="paypal" name="valueIs-radio"  checked={paypal} onChange={(e) => setPaypal(e.target.checked)}/>
              <label className="neon-btn" for="paypal">
                  <span className="span"></span>
                  <span className="txt">{t('PayPal')}</span>
              </label>

              <input className="input-btn"  type="checkbox" id="psf" name="valueIs-radio"  checked={psf} onChange={(e) => setPsf(e.target.checked)}/>
              <label className="neon-btn" for="psf">
                  <span className="span"></span>
                  <span className="txt">{t('Paysafecard')}</span>
              </label>

              <input className="input-btn"  type="checkbox" id="crypto" name="valueIs-radio"  checked={crypto} onChange={(e) => setCrypto(e.target.checked)}/>
              <label className="neon-btn" for="crypto">
                  <span className="span"></span>
                  <span className="txt">{t('Crypto')}</span>
              </label>
              </form>

            <div className='bot_go'>
              <div className='file_up'>
              </div>
        <label htmlFor="file_sell" className="custum-file-upload">
          <div className="icon">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 10 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" fill="#000000"></path>
            </svg>
          </div>
          <div className="text">
            <span>Click to upload image</span>
          </div>
          <input 
            id="file_sell"
            type="file"
            accept="image/*"
            onChange={handlePictureChange}
          />
        </label> 
        {modalOpen && selectedImageIndex !== null &&(
                  <div className="modal picture_sell">
                    <div className="modal-content">
                                                
                    <img
                      src={URL.createObjectURL(selectedImages[selectedImageIndex])}
                      alt="Selected"
                    />
                      <span className="material-symbols-outlined close_picture" onClick={() => {
            setModalOpen(false);
            setSelectedImageIndex(null);
          }}>close</span>
                  </div>
                </div>
              )}
          <button type="button" className='sumbit_img' onClick={uploadImage}>
          <p className="button-text">{t('Submit')}</p> <p className="iconer"><svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"></path><path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="currentColor"></path></svg></p> </button>
                        {showModal && (
                        <div className="modal_del">
                          <div className="card">
                        <div className="card-content">
                          <p className="card-heading">{texteConfirme}</p>
                          <p className="card-description">{t('real_sup')}</p>
                        </div>
                        <div className="card-button-wrapper">
                          <button className="card-button secondaryr"  type="button" onClick={hideModal}>Cancel</button>
                          <button className="card-button primaryr" type="button" onClick={tryToPay}>confirme</button>
                        </div>
                       
                        <button className="exit-button" onClick={hideModal}>
                          <svg height="20px" viewBox="0 0 384 512">
                            <path
                              d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                            ></path>
                          </svg>
                        </button>
                      </div>
                        </div>
                      )}
                </div>
                   
                    </div></div>
                    
                    </form>
                   
                                        <div className="loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div className='img_account'>
                    {selectedImages.map((image, index) => (
                    <div key={index} className="selected-image-container">
                      <img
                        src={URL.createObjectURL(image)}
                        alt={`Selected ${index + 1}`}
                        onClick={() => {
                          setSelectedImageIndex(index);
                          setModalOpen(true);
                        }}
                      />
                      <button className="tooltip buttonis" onClick={() => removeSelectedImage(index)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" height="25" width="25">
                          <path fill="#6361D9" d="M8.78842 5.03866C8.86656 4.96052 8.97254 4.91663 9.08305 4.91663H11.4164C11.5269 4.91663 11.6329 4.96052 11.711 5.03866C11.7892 5.11681 11.833 5.22279 11.833 5.33329V5.74939H8.66638V5.33329C8.66638 5.22279 8.71028 5.11681 8.78842 5.03866ZM7.16638 5.74939V5.33329C7.16638 4.82496 7.36832 4.33745 7.72776 3.978C8.08721 3.61856 8.57472 3.41663 9.08305 3.41663H11.4164C11.9247 3.41663 12.4122 3.61856 12.7717 3.978C13.1311 4.33745 13.333 4.82496 13.333 5.33329V5.74939H15.5C15.9142 5.74939 16.25 6.08518 16.25 6.49939C16.25 6.9136 15.9142 7.24939 15.5 7.24939H15.0105L14.2492 14.7095C14.2382 15.2023 14.0377 15.6726 13.6883 16.0219C13.3289 16.3814 12.8414 16.5833 12.333 16.5833H8.16638C7.65805 16.5833 7.17054 16.3814 6.81109 16.0219C6.46176 15.6726 6.2612 15.2023 6.25019 14.7095L5.48896 7.24939H5C4.58579 7.24939 4.25 6.9136 4.25 6.49939C4.25 6.08518 4.58579 5.74939 5 5.74939H6.16667H7.16638ZM7.91638 7.24996H12.583H13.5026L12.7536 14.5905C12.751 14.6158 12.7497 14.6412 12.7497 14.6666C12.7497 14.7771 12.7058 14.8831 12.6277 14.9613C12.5495 15.0394 12.4436 15.0833 12.333 15.0833H8.16638C8.05588 15.0833 7.94989 15.0394 7.87175 14.9613C7.79361 14.8831 7.74972 14.7771 7.74972 14.6666C7.74972 14.6412 7.74842 14.6158 7.74584 14.5905L6.99681 7.24996H7.91638Z" clip-rule="evenodd" fill-rule="evenodd"></path>
                        </svg>
                        <span className="tooltiptext">remove</span>
                      </button>
                                          
                    </div>
                  ))}
                  </div>
                    <p className='text_info'>{t('psell')}</p>
                    <p className='text_danger'>❗{t('midlaner')}❗</p>
                </div>
                <ToastContainer />
        </div>
    );
};

export default Sell_account;