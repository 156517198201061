import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importez ici les fichiers de traduction pour les différentes langues
import translationEN from './locales/en.json'; // Exemple : anglais
import translationFR from './locales/fr.json'; // Exemple : français
import translationCZ from './locales/en.json'; // Exemple : anglais
import translationDE from './locales/de.json';
import translationES from './locales/es.json'; // Exemple : anglais
import translationHK from './locales/hk.json';
import translationIT from './locales/it.json'; // Exemple : anglais
import translationPL from './locales/pl.json';
import translationTR from './locales/tr.json';

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  cz: {
    translation: translationCZ,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
  hk: {
    translation: translationHK,
  },
  it: {
    translation: translationIT,
  },
  pl: {
    translation: translationPL,
  },
  tr: {
    translation: translationTR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Langue par défaut
  fallbackLng: 'en', // Langue de secours en cas de non-disponibilité de la langue préférée
  keySeparator: false, // Pour éviter d'utiliser des clés comme "translation.key" ; utilisez simplement "key"
  interpolation: {
    escapeValue: false, // Les valeurs d'interpolation ne doivent pas être échappées pour les composants React
  },
});

export default i18n;