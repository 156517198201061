import React,{useState} from 'react';
import imageSp1 from "../../nostale/all_arme/Artiste-martial/0h-30h/10h.png"
import imageSp2 from "../../nostale/all_arme/Artiste-martial/0h-30h/10hg.png"
import imageSp3 from "../../nostale/all_arme/Artiste-martial/0h-30h/25h.png"
import imageSp4 from "../../nostale/all_arme/Artiste-martial/0h-30h/25hgbbleeu.png"
import imageSp5 from "../../nostale/all_arme/Artiste-martial/0h-30h/25hgblack.png"
import imageSp6 from "../../nostale/all_arme/Artiste-martial/0h-30h/25hgred.png"
import imageSp7 from "../../nostale/all_arme/Artiste-martial/0h-30h/25hgwhite.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const Am0h_30h = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
<div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
<button type='button' id="vente_sp1"  onClick={() => handleClick("poing_10h", imageSp1)}><img src={imageSp1}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_10hg", imageSp2)}><img src={imageSp2}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_25h", imageSp3)}><img src={imageSp3}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_25hgbbleeu", imageSp4)}><img src={imageSp4}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_25hgblack", imageSp5)}><img src={imageSp5}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_hgredd", imageSp6)}><img src={imageSp6}></img></button>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("poing_hgwhite", imageSp7)}><img src={imageSp7}></img></button>
            </div>
    );
};

export default Am0h_30h;