import React,{useState} from 'react';
import imageSp1 from "../../nostale/all_arme/escri/0h-30h/10h.png"
import imageSp2 from "../../nostale/all_arme/escri/0h-30h/10ha.png"
import imageSp3 from "../../nostale/all_arme/escri/0h-30h/25hac.png"
import imageSp4 from "../../nostale/all_arme/escri/0h-30h/25had.png"
import imageSp5 from "../../nostale/all_arme/escri/0h-30h/25hc.png"
import imageSp6 from "../../nostale/all_arme/escri/0h-30h/25hd.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const E0h_30h = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
      <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("sword_10h", imageSp1)}><img src={imageSp1}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("sword_10ha", imageSp2)}><img src={imageSp2}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("sword_25hac", imageSp3)}><img src={imageSp3}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("sword_25had", imageSp4)}><img src={imageSp4}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("sword_25hc", imageSp5)}><img src={imageSp5}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("sword_25hd", imageSp6)}><img src={imageSp6}></img></button>
    </div>
    );
};

export default E0h_30h;