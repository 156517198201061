import React,{useState} from 'react';
import imageSp1 from "../../nostale/Partennaire/raproché/aegir.png"
import imageSp2 from "../../nostale/Partennaire/raproché/akhé.png"
import imageSp3 from "../../nostale/Partennaire/raproché/Amon.png"
import imageSp4 from "../../nostale/Partennaire/raproché/fiona.png"
import imageSp5 from "../../nostale/Partennaire/raproché/foxy.png"
import imageSp6 from "../../nostale/Partennaire/raproché/halitus_xv.png"
import imageSp7 from "../../nostale/Partennaire/raproché/halitus.png"
import imageSp8 from "../../nostale/Partennaire/raproché/Lucie.png"
import imageSp9 from "../../nostale/Partennaire/raproché/mamann_maru.png"
import imageSp10 from "../../nostale/Partennaire/raproché/Marionnetiste.png"
import imageSp11 from "../../nostale/Partennaire/raproché/maru.png"
import imageSp12 from "../../nostale/Partennaire/raproché/miaou.png"
import imageSp13 from "../../nostale/Partennaire/raproché/Perti.png"
import imageSp14 from "../../nostale/Partennaire/raproché/ragnar.png"
import imageSp15 from "../../nostale/Partennaire/raproché/shinnobi.png"
import { useTextHidden } from '../../Components/TextHiddenContext';

const Cac = (props) => {
  const { textHidden, toggleText } = useTextHidden();
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const {navStyle3} = props;
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
        <div className='propose-ritem-group'>
            <div className='propose-ritem propose-item' style={navStyle3}>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("aegir", imageSp1)}><img src={imageSp1}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("akhé", imageSp2)}><img src={imageSp2}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("Amon", imageSp3)}><img src={imageSp3}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("fiona", imageSp4)}><img src={imageSp4}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("foxy", imageSp5)}><img src={imageSp5}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("halitus_xv", imageSp6)}><img src={imageSp6}></img></button> 
          <button type='button' id="vente_sp1"  onClick={() => handleClick("halitus", imageSp7)}><img src={imageSp7}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("Lucie", imageSp8)}><img src={imageSp8}></img></button>
          

            </div>
            
            <div className='propose-item item2 propose-ritem' style={navStyle3}>
            <button type='button' id="vente_sp1"  onClick={() => handleClick("mamann_maru", imageSp9)}><img src={imageSp9}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("Marionnetiste", imageSp10)}><img src={imageSp10}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("maru", imageSp11)}><img src={imageSp11}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("miaou", imageSp12)}><img src={imageSp12}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("Perti", imageSp13)}><img src={imageSp13}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("ragnar", imageSp14)}><img src={imageSp14}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("shinnobi", imageSp15)}><img src={imageSp15}></img></button>
    
            </div>
            </div>
    );
};

export default Cac;