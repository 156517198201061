import React,{useState} from 'react';
import imageSp1 from "../../nostale/all_armure/Artiste-martial/30h-60h/48h.png"
import imageSp2 from "../../nostale/all_armure/Artiste-martial/30h-60h/53h.png"
import imageSp3 from "../../nostale/all_armure/Artiste-martial/30h-60h/58h.png"
import { useTextHidden } from '../../Components/TextHiddenContext';
const AMarmure30h_60h = (props) => {
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const { textHidden, toggleText } = useTextHidden();
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
      <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_48h", imageSp1)}><img src={imageSp1}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_53h", imageSp2)}><img src={imageSp2}></img></button>
      <button type='button' id="vente_sp1"  onClick={() => handleClick("plastron_58h", imageSp3)}><img src={imageSp3}></img></button>
    </div>
    );
};

export default AMarmure30h_60h;