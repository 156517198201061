import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';


const RuneS = (props) => {
  const [valeurSelect, setValeurSelect] = useState('aug_all_def');
  const [valeurInput, setValeurInput] = useState(0);
  const { t } = useTranslation();

      const [check, setCheck] = useState(false);
      const checkChanges = () => {
        // Vérifier si les champs ont été modifiés
        // Par exemple, si valeurSelect et valeurInput ont été modifiés
        if (valeurInput !== 0) {
          // Mettre à jour setCheck si les champs ont été modifiés
          setCheck(true);
        } else {
          // Les champs n'ont pas été modifiés, pas besoin de mettre à jour setCheck
          setCheck(false);
        }
      };

      const confirm_rune = document.querySelector('.confirm_rune');
    
      if (check){
        confirm_rune.style.display = 'none';
      }else {
        confirm_rune.style.display = 'flex';
      }


      function handleButtonClick() {
        // Appeler props.onValueChange avec les valeurs actuelles de select et de l'input
        checkChanges()
        props.onValueChange(props.index, valeurSelect, valeurInput);
      }
    const armeS = [
        'aug_all_def', 'expo_eff', 'recup_hp_def', 'aug_res',
      ];

    return (
        <div>
                          <select className='select-add armec' value={valeurSelect} disabled={check}  onChange={e=>setValeurSelect(e.target.value)}>
                <option value={armeS[0]}>{t('aug_all_def')}</option>
                <option value={armeS[1]}>{t('expo_eff')}</option>
                <option value={armeS[2]}>{t('recup_hp_def')}</option>
                <option value={armeS[3]}>{t('aug_res')}</option>
              </select>
              <input type="number" className="input_1" value={valeurInput} disabled={check} min='0' onChange={e=>setValeurInput(e.target.value)} />
              {!check ? <button type='button' className='btn_rune' onClick={handleButtonClick}>
        <span className="material-symbols-outlined">
          done_outline
        </span>
      </button>
        :
        <></>
        }
        </div>
    );
};

export default RuneS;