import React,{useContext,useState,useEffect} from 'react';
import { signInWithEmailAndPassword,signInWithPopup } from 'firebase/auth';
import { auth,provider } from '../../firebase-config';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../../Context/AuthContext';
import { db2 } from '../../firebase-config.js';
import { getDoc,setDoc , doc,updateDoc,getFirestore,serverTimestamp } from 'firebase/firestore';
import { GoogleAuthProvider } from 'firebase/auth';
import { useTranslation } from 'react-i18next';

const SignnUpModal_v2 = (props) => {
  const hourglassElement = document.querySelector('.loader');
  const { t } = useTranslation();
    const { setModalState } = props;
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const gifSource ='gif/user_default.gif'
const handleGoogleSignIn = async () => {
  try {
    const pdpSource = 'pdp/user_default.png'
    const result = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    const user = result.user;

    // Check if user is signing in for the first time
    const userRef = doc(db2, 'users', user.uid);
    const docSnap = await getDoc(userRef);

    if (!docSnap.exists()) {
      // User is signing in for the first time
      await setDoc(userRef, {
        email: user.email,
        displayName: user.displayName,
        pdp_src: pdpSource,
        gif_src: gifSource,
        Creat : serverTimestamp(),
        Nosdollar: 0 
      });
      await setDoc(doc(db2, 'score', user.uid), {
        like:[],
        dislike:[],
      });
    }
    
    dispatch({ type: "LOGIN", payload: user })
    navigate("/nosbaz");
  } catch (error) {
    setError(true);
  }
};



    const { dispatch } = useContext(AuthContext)
    const handleForm = async (e) => {
      e.preventDefault()
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          dispatch({ type: "LOGIN", payload: user })
          navigate("/nosbaz");
        })
        .catch((error) => {
          setError(true);
        })
    }
  

    return (    
        <>  
            <div className='Modal_all'>

            <div className="Overlay"></div>
            <div className="loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
            <div className='Signin_Modal' style={{minnWidth: "400px"}}>

                <div className='modal-dialog'>

                <div className='modal-content_sign'>
                <button onClick={() => setModalState("close")} className='close_btn'>
                  <span className="material-symbols-outlined">
                  close
                  </span>
                </button>
                    <div className='header'>
                    <h5 className='modal-title'>SIGN IN
                    </h5>
                    </div>
                    
                    <div className='modal-body'>

                    <form className='sign-up-form' onSubmit={handleForm}>
                    <div className="input-group">
                    <label className="label">{t('email')}</label>
                    <input autocomplete="off" name="Email" id="Email" className="input" type="email" onChange={e=>setEmail(e.target.value)}/>
                    <div></div></div>
                    <div className="input-group">
                    <label className="label">{t('pdw')} </label>
                    <input autocomplete="off" name="Pwd" id="Pwd" className="input" type="password" onChange={e=>setPassword(e.target.value)}/>
                    <div></div>
                    {error ?<p className='texte-danger'>Wrong Email or Password </p>: ""}</div>

                            

                        <div className='butn_co'>
                            
                            <button className='log_btn'  type="submit">{t('Login')}</button>
                            <button className="bn" onClick={() => navigate("/forgot-password")}>

                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"></path>
                            </svg>

                            
                            <div className="text">
                              {t('password-paword-btn')}
                              </div>
                          </button>
                            
                        </div>
                        <button className="buttonyy"onClick={handleGoogleSignIn}>
                          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" viewBox="0 0 256 262">
                          <path fill="#4285F4" d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"></path>
                          <path fill="#34A853" d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"></path>
                          <path fill="#FBBC05" d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"></path>
                          <path fill="#EB4335" d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"></path>
                        </svg>
                          Continue with Google
                        </button>

                    </form>
                    </div>

                </div>
                </div>
            </div>
            </div>  
        </>
    );
};

export default SignnUpModal_v2;