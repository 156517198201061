import React,{useState} from 'react';
import imageSp1 from "../../nostale/all_arme/archer/0h-30h/10h.png"
import imageSp2 from "../../nostale/all_arme/archer/0h-30h/25ha.png"
import imageSp3 from "../../nostale/all_arme/archer/0h-30h/25hd.png"
import imageSp4 from "../../nostale/all_arme/archer/0h-30h/d10h.png"
import imageSp5 from "../../nostale/all_arme/archer/0h-30h/d25ha.png"
import imageSp6 from "../../nostale/all_arme/archer/0h-30h/d25hd.png"
import { useTextHidden } from '../../Components/TextHiddenContext';

const A0h_30h = (props) => {
      const { textHidden, toggleText } = useTextHidden();
 const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
        <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_10h", imageSp1)}><img src={imageSp1}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_25ha", imageSp2)}><img src={imageSp2}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_25hd", imageSp3)}><img src={imageSp3}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_d10h", imageSp4)}><img src={imageSp4}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_d25ha", imageSp5)}><img src={imageSp5}></img></button>
          <button type='button' id="vente_sp1"  onClick={() => handleClick("arc_d25hd", imageSp6)}><img src={imageSp6}></img></button>
        </div>
    );
};

export default A0h_30h;