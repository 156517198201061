import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth,GoogleAuthProvider} from "firebase/auth";
import { getDatabase} from "firebase/database";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}
const app = initializeApp(firebaseConfig);
const db2 = getFirestore(app);
const provider = new GoogleAuthProvider();
const db = getDatabase(app);
const auth = getAuth(app);
const storage = getStorage(app);
const user = auth.currentUser;
const Vendredb = {
  todos: () => {
    return db2.collection('vente')
  }
}

export {auth,provider,db,db2,user,storage,Vendredb};