import React,{useState} from 'react';
import imageSp1 from "../../../nostale/res/gants/0h-30h/20gci.png"
import imageSp2 from "../../../nostale/res/gants/0h-30h/25hg.png"
import imageSp3 from "../../../nostale/res/gants/0h-30h/25hgc.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';

const Granto0h_30h = (props) => {
    const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
        <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
        <button  type='button' id="sp1a" onClick={() => handleClick("gant20gci", imageSp1)}><img src={imageSp1}></img></button>
          <button type='button' id="sp1a" onClick={() => handleClick("gant25hg", imageSp2)}><img src={imageSp2} ></img></button>
          <button type='button' id="sp1a" onClick={() => handleClick("gant25hgc", imageSp3)}><img src={imageSp3} ></img></button>
        </div>
    );
};

export default Granto0h_30h;