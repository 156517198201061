import React from 'react';
import Navbar from '../Components/Navbar';
import AccountNst from '../Components/AccountNst';
const Account = () => {
    return (
        <div>
            <Navbar/>
            <AccountNst/>
        </div>
    );
};

export default Account;