import React from 'react';
import Navbar from '../Components/Navbar';
import Estimator from '../Components/Estimator';
const Estimagter = () => {
    return (
        <div>
             <Navbar/>
             <Estimator/>
        </div>
    );
};

export default Estimagter;