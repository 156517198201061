import React,{useState} from 'react';
import imageSp1 from "../../../nostale/res/gants/60h-80h/60gh.png"
import imageSp2 from "../../../nostale/res/gants/60h-80h/80gh.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Grant60_80 = (props) => {
    const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
        <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
        <button  type='button' id="sp1a" onClick={() => handleClick("gant_60h", imageSp1)} ><img src={imageSp1}></img></button>
          <button type='button' id="sp1a" onClick={() => handleClick("gant_80h", imageSp2)} ><img src={imageSp2} ></img></button>
        </div>
    );
};

export default Grant60_80;