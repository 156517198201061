import React, { useEffect, useState,useContext } from 'react';
import { collection, query, where, getDocs,updateDoc,doc,deleteDoc,getDoc } from 'firebase/firestore';

import {  getDownloadURL, ref, deleteObject } from 'firebase/storage';

import { format } from 'date-fns';
import imageatq from "../nostale/SP/img_up/atq.png"
import imagedef from "../nostale/SP/img_up/def.png"
import imageelem from "../nostale/SP/img_up/elem.png"
import imagehpmp from "../nostale/SP/img_up/hpmp.png"
import imagefeu_res from "../nostale/SP/img_up/res_feu.png"
import imageeau_res from "../nostale/SP/img_up/res_eau.png"
import imagelulu_res from "../nostale/SP/img_up/res_lulu.png"
import imageobscu_res from "../nostale/SP/img_up/res_obscu.png"
import { AuthContext } from '../Context/AuthContext';

import { db2, storage} from '../firebase-config'
import { NavLink } from 'react-router-dom';

import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTextHidden } from './TextHiddenContext';
import imgload from "../photo_anex/load.gif"
import { useTranslation } from 'react-i18next';
const Estimator = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { textHidden, toggleText } = useTextHidden();
const [documentsPerPage] = useState(10);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [data, setData] = useState([]);
    const bazarCollectionRef = collection(db2, 'esti');
    const { currentUser } = useContext(AuthContext);
    const [modalOpen, setModalOpen] = useState(Array(data.length).fill(false));
    const [modalOpenEsti, setModalOpenEsti] = useState(false);
    const uid = currentUser.uid;
    const [orPure, setOrPure] = useState(0);
    const [price,setPrice] =useState();
    const { t } = useTranslation();
            // Fonction pour calculer l'index du dernier document de chaque page
  const indexOfLastDocument = currentPage * documentsPerPage;
  // Fonction pour calculer l'index du premier document de chaque page
  const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
  // Fonction pour récupérer les documents de la page courante
  const currentDocuments = data.slice(indexOfFirstDocument, indexOfLastDocument);
  // Fonction pour changer de page

  const paginate = (pageNumber) => {
    if (pageNumber <= Math.ceil(data.length / documentsPerPage)) {
      setCurrentPage(pageNumber);
    }
  };
    const formatNumber = (num) => {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };
    useEffect(() => {
      setOrPure(price * 1000000);
    }, [price]);
    const handleOpenModalesti = () => {
      setModalOpenEsti(true);
    };
    const handleCloseModalesti = () => {
      setModalOpenEsti(false);
    };
    
    const handleEstiPrice = async (index, docId, price) => {
      const docRef = doc(bazarCollectionRef, docId);
      const docSnapshot = await getDoc(docRef);
      const currentEsti = docSnapshot.data().esti;
      const currentLike = docSnapshot.data().like;
    
      if (currentEsti.includes(price)) {
        toast.warn(t('valu_ex'), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    
      const newEsti = [...currentEsti, price];
      const newLike = { ...currentLike };
    
      let countEquals = 0;
      Object.values(newLike).forEach((value) => {
        const uidValue = value[uid];
        if (uidValue && uidValue === "=") {
          countEquals++;
        }
      });
    
      if (countEquals >= 1) {
        toast.warn(t('too_prop'), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        return;
      }
    
      const updatedLike = { ...newLike };
      if (!updatedLike[price]) {
        updatedLike[price] = {};
      }
      updatedLike[price][uid] = "=";
    
      await updateDoc(docRef, { esti: newEsti, like: updatedLike });
    
      const newData = data.map((item, i) => {
        if (i === index) {
          return { ...item, esti: newEsti, showChangeprix: false };
        }
        return item;
      });
    
      setData(newData);
    };  

    

    const handleLike = async (index, docId, value) => {
      const docRef = doc(bazarCollectionRef, docId);
      const docSnapshot = await getDoc(docRef);
      const currentEsti = docSnapshot.data().esti;
      const currentLike = docSnapshot.data().like;
    
      const newEsti = [...currentEsti];
      const newLike = { ...currentLike };
    
      // Vérifie si l'utilisateur a déjà appuyé deux fois sur le bouton dans tous les tableaux enfants
      let countDislikes = 0;
      Object.values(newLike).forEach((child) => {
        if (child[uid] === "+") {
          countDislikes++;
        }
      });
    
      if (countDislikes >= 1) {
        toast.warn (t('too_like'), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        return; // Arrête la fonction si l'utilisateur a déjà appuyé deux fois
      }
    
      // Vérifie si le tableau enfant existe pour la valeur donnée
      if (!newLike[value]) {
        newLike[value] = {}; // Crée un nouveau tableau enfant pour la valeur donnée
      }
    
      newLike[value][uid] = "+"; // Met à jour la valeur du tableau enfant
    
      await updateDoc(docRef, { esti: newEsti, like: newLike });
    
      const newData = data.map((item, i) => {
        if (i === index) {
          return { ...item, esti: newEsti, showChangeprix: false };
        }
        return item;
      });
      setData(newData);
      const fetchDocuments = async () => {
        try {
          let querySnapshot;
      
          if (showAllPosts) {
            querySnapshot = await getDocs(collection(db2, 'esti'));
          } else {
            querySnapshot = await getDocs(query(bazarCollectionRef, where('senderUid', '==', uid)));
          }
      
          const list = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              const url = await getDownloadURL(ref(storage, doc.data().image_url));
              const timeStamp = doc.data().timeStamp;
              const formattedDate = format(new Date(timeStamp.seconds * 1000), 'dd-MM-yyyy');
      
              return { id: doc.id, ...doc.data(), imageUrl: url, date: formattedDate, showChangeprix: false };
            })
          );
          list.sort((a, b) => b.timeStamp.seconds - a.timeStamp.seconds);
          setData(list);
          setModalOpen(Array(list.length).fill(false));
        } catch (error) {
          // You can add error handling code or logging here if needed.
          console.error(error);
        }
      };
    
      fetchDocuments();
      getCountOfLikes()
    };



    const handledisLike = async (index, docId, value) => {
      const docRef = doc(bazarCollectionRef, docId);
      const docSnapshot = await getDoc(docRef);
      const currentEsti = docSnapshot.data().esti;
      const currentLike = docSnapshot.data().like;
    
      const newEsti = [...currentEsti];
      const newLike = { ...currentLike };
    
      // Vérifie si l'utilisateur a déjà appuyé deux fois sur le bouton dans tous les tableaux enfants
      let countDislikes = 0;
      Object.values(newLike).forEach((child) => {
        if (child[uid] === "-") {
          countDislikes++;
        }
      });
    
      if (countDislikes >= 2) {
        toast.warn (t('tow_prop'), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
        return; // Arrête la fonction si l'utilisateur a déjà appuyé deux fois
      }
    
      // Vérifie si le tableau enfant existe pour la valeur donnée
      if (!newLike[value]) {
        newLike[value] = {}; // Crée un nouveau tableau enfant pour la valeur donnée
      }
    
      newLike[value][uid] = "-"; // Met à jour la valeur du tableau enfant
    
      await updateDoc(docRef, { esti: newEsti, like: newLike });
    
      const newData = data.map((item, i) => {
        if (i === index) {
          return { ...item, esti: newEsti, showChangeprix: false };
        }
        return item;
      });
      setData(newData);
      const fetchDocuments = async () => {
        try {
          let querySnapshot;
    
          if (showAllPosts) {
            querySnapshot = await getDocs(collection(db2, 'esti'));
          } else {
            querySnapshot = await getDocs(query(bazarCollectionRef, where('senderUid', '==', uid)));
          }
    
          const list = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              const url = await getDownloadURL(ref(storage, doc.data().image_url));
              const timeStamp = doc.data().timeStamp;
              const formattedDate = format(new Date(timeStamp.seconds * 1000), 'dd-MM-yyyy');
    
              return { id: doc.id, ...doc.data(), imageUrl: url, date: formattedDate, showChangeprix: false };
            })
          );
          list.sort((a, b) => b.timeStamp.seconds - a.timeStamp.seconds);
          setData(list);
          setModalOpen(Array(list.length).fill(false));
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchDocuments();
      getCountOfLikes()
    };

    const getCountOfLikes = (value) => {
      let count = 0;
      Object.values(data).forEach((doc) => {
        if (doc.like && doc.like[value]) {
          count += Object.values(doc.like[value]).reduce((acc, val) => {
            if (val === "+") {
              return acc + 1;
            } else if (val === "-") {
              return acc - 1;
            }
            return acc;
          }, 0);
        }
      });

      let color = "";
      if (count < 0) {
        color = "orange";
      } else if (count > 0) {
        color = "rgba(0, 255, 13, 1)";
      }
    
      return <span style={{ color }}>{count}</span>;
    };

    
    const [showAllPosts, setShowAllPosts] = useState(true);

    const handleTogglePosts = () => {
      setShowAllPosts((prevShowAllPosts) => !prevShowAllPosts);
      mypost(); // Call mypost function after updating showAllPosts
    };
    
    const mypost = () => {
      const fetchDocuments = async () => {
        try {
          let querySnapshot;
    
          if (showAllPosts) {
            querySnapshot = await getDocs(collection(db2, 'esti'));
          } else {
            querySnapshot = await getDocs(query(bazarCollectionRef, where('senderUid', '==', uid)));
          }
    
          const list = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              const url = await getDownloadURL(ref(storage, doc.data().image_url));
              const timeStamp = doc.data().timeStamp;
              const formattedDate = format(new Date(timeStamp.seconds * 1000), 'dd-MM-yyyy');
    
              return { id: doc.id, ...doc.data(), imageUrl: url, date: formattedDate, showChangeprix: false };
            })
          );
          list.sort((a, b) => b.timeStamp.seconds - a.timeStamp.seconds);
          setData(list);
          setModalOpen(Array(list.length).fill(false));
        } catch (error) {
          console.error(error);
        }
      };
    
      fetchDocuments();
    };
    
    useEffect(() => {
      mypost();
    }, [showAllPosts]);
    
    useEffect(() => {

      const fetchDocuments = async () => {
   const hourglassElement = document.querySelector('.loader');
        try {
    hourglassElement.style.display = 'block';
          const querySnapshot = await getDocs(query(collection(db2, 'esti')));
          const list = await Promise.all(
            querySnapshot.docs.map(async (doc) => {
              const url = await getDownloadURL(ref(storage, doc.data().image_url));
              const timeStamp = doc.data().timeStamp;
              const formattedDate = format(new Date(timeStamp.seconds * 1000), 'dd-MM-yyyy');
              return { id: doc.id, ...doc.data(), imageUrl: url, date: formattedDate, showChangeprix: false, timeStamp: timeStamp };
            })
          );
          // Tri des documents par timeStamp dans l'ordre décroissant
          hourglassElement.style.display = 'none';
          list.sort((a, b) => b.timeStamp.seconds - a.timeStamp.seconds);
          setData(list);
          setModalOpen(Array(list.length).fill(false));

        } catch (error) {
          console.error(error);
            hourglassElement.style.display = 'none';
        }
      };
    
      fetchDocuments();
    }, [currentUser.displayName]);

      const handleOpenModal = (index) => {
        setSelectedDoc(data[index]);
        setModalOpen((prev) => {
          const newState = [...prev];
          newState[index] = true;
          return newState;
        });
      };
    
      function formatDate(timeStamp) {
        const date = new Date(timeStamp.seconds * 1000);
        
       
      const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
      }
    
      const handleCloseModal = (index) => {
        setModalOpen((prev) => {
          const newState = [...prev];
          newState[index] = false;
          return newState;
        });
      };  

      const [modalOpenDelete, setModalOpenDelete] = useState([]);

      const OpenDelete = (index) => {
        const newModalOpenDelete = [...modalOpenDelete];
        newModalOpenDelete[index] = true;
        setModalOpenDelete(newModalOpenDelete);
      }
      const handleDeleteItem = async (docId, index,image_url) => {
           const hourglassElement = document.querySelector('.loader');
   hourglassElement.style.display = 'block';
        const docRef = doc(bazarCollectionRef, docId);
        await deleteDoc(docRef);
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
        handleCloseModalDelete(index)
        toast.success (t('ann_sup'), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          });
          hourglassElement.style.display = 'none';
        deleteObject(ref(storage, image_url)).then(() => {
    }).catch((error) => {
      toast.warn (t('err_ann'), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
        hourglassElement.style.display = 'none';
    });
      };
      const [items, setItems] = useState(["item1", "item2", "item3"]);
      const handleCloseModalDelete = (index) => {
        const newModalOpenDelete = [...modalOpenDelete];
        newModalOpenDelete[index] = false;
        setModalOpenDelete(newModalOpenDelete);
      };
      const navStyle = {
        width: textHidden ? '90%' : '85%', // Adjust '100px' to your desired width
        marginLeft: textHidden ? '120px' : '235px',
        transition: 'margin-left 2s',
      };
      const navStyle2 = { // Adjust '100px' to your desired width
        marginLeft: textHidden ? '-120px' : '0px',
        transition: 'margin-left 2s',
        marginTop: '10px',

      };    

    return (
        <div>
            <div className='title_esti'>
                <h1 className='esti' style={navStyle2}> {t('esti')}</h1>
                <div className='btnn_esti'>    

                 <NavLink to="/Estimatepost" className="Navlink ">
                 <button className='creat_post' >
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"></path><path fill="currentColor" d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"></path></svg> Create
              </span>
            </button>

                  </NavLink>
                    
                  <button type='button' className="btn_post" onClick={handleTogglePosts}>
                  {showAllPosts ? 'My Post' : 'All Post'}
                    </button>
                </div>
            </div>

                <table className='tableSell' style={navStyle}>
                <div className="loader">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <thead>
                        <tr>
                            <th className='th1'>{t('th1')}</th>
                            <th className='th2'>{t('th2')}</th>
                            <th className='th3'>{t('th3')}</th>
                            <th className='th4'>{t('th4')}</th>
                            <th className='th5'>{t('th5')}</th>
                        </tr>
                    </thead>
                    
                    <tbody>
  {currentDocuments.map((doc, index) => (
    <tr key={doc.id}>
      <th>
        <img src={doc.image_gestion} />
      </th>
      <th>{doc.date}</th>
      <th>{doc.Pseudonim}</th>
      <th>{doc.serveur}</th>
      <th className='thdel'>

         <button
          className="detail"
          id="toggle-overlay"
          onClick={() => handleOpenModal(index)}
        >
         <span>{t('see_detai')}</span> 
        </button>
        {doc.senderUid === uid ? (
          <button className="buttonn" onClick={() => OpenDelete(index)}>
            <svg viewBox="0 0 448 512" className="svgIconn">
              <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
            </svg>
          </button>
        ) : <></>}
        {modalOpenDelete[index] && (
          <>
            <div
              className="modal-overlay"
              onClick={() => handleCloseModalDelete(index)}
            ></div>
            <div key={doc.id} className="modal_del">
            <div className="card">
                                    <div className="card-content">
                                      <p className="card-heading">Delete file?</p>
                                      <p className="card-description">{t('real_sup')}</p>
                                    </div>
                                    <div className="card-button-wrapper">
                                      <button className="card-button secondary" onClick={() => handleCloseModalDelete(index)}>Cancel</button>
                                      <button className="card-button primary" onClick={() => handleDeleteItem(doc.id, index, doc.imageUrl)}>Delete</button>
                                    </div>
                                    <button className="exit-button" onClick={() => handleCloseModalDelete(index)}>
                                      <svg height="20px" viewBox="0 0 384 512">
                                        <path
                                          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                                        ></path>
                                      </svg>
                                    </button>
                                  </div>
            </div>
          </>
        )}
        {modalOpen[index] && (
          <div className="modal-overlay">
            <div key={doc.id} className="modal">
              <div className="picture_seller">
                <img src={doc.imageUrl} alt="item" />
              </div>
              <div className="Caractéristique">
                <button id="close-crosshair-button">
                  <span
                    className="material-symbols-outlined"
                    onClick={() => handleCloseModal(index)}
                  >
                    close
                  </span>
                </button>
                <div className="caradroit">
                  <h1 className="TitleSell"> {t('TitleSell')}</h1>
                  {selectedDoc.runeValues ? (
                                                              <ul className='ul_weapons'>
                      {doc.up ? <div className='caract'>
                                              <p className='upeustre'>UP : +{doc.up}</p>
                                              <p className='raritu'>Rarity : +{doc.rarity}</p>
                                            </div> : <></>
                                            }
                  {selectedDoc.runeValues.map((rune, index) => (
                    <li key={index}>
                      {rune.hasOwnProperty('selectValue') 
                      ? `${t(`${rune.selectValue}`)}: ${rune.inputValue}`
                      : `${t(`${rune.field}`)}: ${rune.value}`
                      }
                    </li>
                  ))}
                  </ul>
                  ) : (
                    <li className="list-carac">
                      {doc.Attaque === 0 || doc.Attaque ? (
                        <>
                                                    <div className='caract'>
                            <p className='upeustre'>Up : +{doc.up}</p>
                            <p className='upeustre'>Amélio : +{doc.amelio}</p>
                            </div>
                            <ul>
                            {t('Attaque')}  <img src={imageatq} className='img_sp'/> : <a>{doc.Attaque}</a>
                                            </ul>
                                            <ul>
                                              {t('Element')} <img src={imageelem} className='img_sp'/> : <a>{doc.Element}</a>
                                            </ul>
                                            <ul>
                                              {t('HpMp')} <img src={imagehpmp} className='img_sp'/> : <a>{doc.HpMp}</a>
                                            </ul>
                                            <ul>
                                              {t('Defense')} <img src={imagedef} className='img_sp'/> : <a>{doc.Defense}</a>
                                            </ul>
                                            <ul>
                                              {t('Res_Eau')}<img src={imageeau_res} className='img_sp'/> : <a>{doc.Res_Eau}</a>
                                            </ul>
                                            <ul>
                                              {t('Res_Feu')} <img src={imagefeu_res} className='img_sp'/> : <a>{doc.Res_Feu}</a>
                                            </ul>
                                            <ul>
                                              {t('Res_Obscu')} <img src={imageobscu_res} className='img_sp'/> : <a>{doc.Res_Lulu}</a>
                                            </ul>
                                            <ul>
                                              {t('Res_Lulu')} <img src={imagelulu_res} className='img_sp'/> : <a>{doc.Res_obscu}</a>
                                            </ul></>
                                                      ) : (
                                                        <>
                                      {doc.atq ?<div className='petSkill'>
                                             <ul>
                                             {t('att_pet')} : <a>+{doc.atq}</a>
                                             </ul>
                                             <ul>
                                             {t('def_pet')} : <a>+{doc.def}</a>
                                             </ul>
                                     </div> : <div className="Petskill">
                                      {doc.firstSkill ? 
                                          <>
                                        <ul>
                                        {t('1 Skill')} : <a>{doc.firstSkill} </a>
                                      </ul>
                                      <ul>
                                        {t('2 Skill')} : <a>{doc.secondSkill}</a>
                                      </ul>
                                      <ul>
                                        {t('3 Skill')} : <a>{doc.threeSkill}</a>
                                      </ul>
                                      </>                            
                                      : 
                                      <>
                                     <ul>
                                      {t('Res_Eau')} <img src={imageeau_res} className='img_sp'/> : <a>{doc.Res_Eau} </a>
                                    </ul>
                                    <ul>
                                      {t('Res_Feu')} <img src={imagefeu_res} className='img_sp'/> :  <a>{doc.Res_Feu} </a>
                                    </ul>
                                    <ul>
                                      {t('Res_Obscu')} <img src={imageobscu_res} className='img_sp'/> :  <a>{doc.Res_Lulu} </a>
                                    </ul>
                                    <ul>
                                      {t('Res_Lulu')} <img src={imagelulu_res} className='img_sp'/> :  <a>{doc.Res_obscu} </a>
                                    </ul>
                                     </>
                                      }
                                    
                                     </div>
                                     
                                     }
                        </>
                      )}
                    </li>
                  )}
                  <button className="Esti" onClick={handleOpenModalesti}>
                    Esti
                  </button>
                  {modalOpenEsti && (
                    <div className="modal modalesti">
                      <h3 className="title_esti_modal">{t('title_esti_modal')}</h3>
                      <div className="resultat_esti">
                        {doc.esti.map((value, index) => (
                          <div className="props_esti">
                            <p key={index}> {value} kk</p>
                            <div className="likeur">
                              <button
                                className="esti esti_down"
                                onClick={() => handledisLike(index, doc.id, value)}
                              >
                                <span className="material-symbols-outlined">
                                  remove
                                </span>
                              </button>
                              {getCountOfLikes(value)}
                              <button
                                className="esti esti_up"
                                onClick={() => handleLike(index, doc.id, value)}
                              >
                                <span className="material-symbols-outlined">add</span>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* Contenu de la modal */}
                      {orPure ? (
                        <p className="orpur_esti">Or: {formatNumber(orPure)}</p>
                      ) : (
                        <></>
                      )}
                      <div className="inpu_esti">
                        <input type="number" onChange={e => setPrice(e.target.value)} />
                        <p>kk</p>
                        <button
                          className="btn_esti"
                          onClick={() => handleEstiPrice(index, doc.id, price)}
                        >
                          <span className="material-symbols-outlined">arrow_upward</span>
                        </button>
                      </div>
                      <button onClick={handleCloseModalesti} id="close-crosshair-button2">
                        <span
                          className="material-symbols-outlined"
                          onClick={() => handleCloseModal(index)}
                        >
                          close
                        </span>
                      </button>
                      <div className="Modal_prevEsti">
                        <span className="material-symbols-outlined stop_esti" id="stop">
                          report
                        </span>
                        <div className="Esti_carac">
                          <p>{t('Esti_carac')}</p>
                          <p className="oneline">
                          {t('oneline')}<p className="color_blue"> + </p>
                          </p>
                          <p className="oneline ">
                          {t('twoline')}<p className="color_red"> - </p>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </th>
      
    </tr>

  ))}
<div className="pagination">
  {data.length > 0 &&
    [...Array(Math.min(9, Math.ceil(data.length / documentsPerPage))).keys()].map((pageNumber) => (
      <button key={pageNumber} onClick={() => paginate(pageNumber + 1)}>
        {pageNumber + 1}
      </button>
    ))}
  {currentPage < Math.ceil(data.length / documentsPerPage) && (
    <button onClick={() => paginate(currentPage + 1)}>{">"}</button>
  )}
</div>
</tbody>

              </table>
              <div className="footerus">
                  <p>{t('apropos')}</p>
                  <p>facebook : <a className='fb_link' href='https://www.facebook.com/people/BazNos/100095523103918/'>BazNos FB</a></p>
        <p>{t('reserv')}</p>
        <p>{t('©')}</p>
    </div>
              <ToastContainer />
        </div>
    );
};

export default Estimator;