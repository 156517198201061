import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {auth,db2,storage} from "../firebase-config";
import React from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes  } from "firebase/storage"
import { collection, query, where, getDocs, doc, firestore, updateDoc, getDoc } from 'firebase/firestore';
import sp1A from "../nostale/SP/archer/sp1.png"
import { AuthContext } from "../Context/AuthContext";
import { useTranslation } from 'react-i18next';
import { useTextHidden } from './TextHiddenContext';
import site_imh from '../nostale/anex/name_site_site - Copie.png'
import site_imh2 from '../nostale/anex/nam_site.png'
function Navbar({ setDisplayName }) {
  const { t } = useTranslation();
  const { textHidden, toggleText } = useTextHidden();
  const [picture_sp_user_url, setPicture_sp_user_url] = useState('');
const [nosdollar,setNosdollar]=useState(0)

    const navigate = useNavigate();
    
      const logOut = async () =>{
        try{
          await auth.signOut();
          await signOut(auth)
          navigate("/")
          localStorage.clear();
        }catch{
          toast.warn ("For some reasonns we can't deconnect, please check your internet conneciton", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
        }
      }
    const handleDisplayNameChange = (displayName) => {
      setDisplayName(displayName);
    };
    const { currentUser } = useContext(AuthContext); // Get the currentUser from the AuthContext
  //const{toggleModals}= useContext(UserContext);

  const [nbDocuments, setNbDocuments] = useState("");

  useEffect(() => {
    const fetchDocuments = async () => {
      const uid = currentUser.uid;
      const docRef = doc(db2, 'users', uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const userPicture = data.pdp_src || '';
        const Nosdolar = data.Nosdollar || 0 ;
        setNosdollar(Nosdolar);
        const picture_sp_user = await getDownloadURL(ref(storage, userPicture));
          setPicture_sp_user_url(picture_sp_user);
        }
      const querySnapshot = await getDocs(
        query(collection(db2, 'vente'), where('Pseudonim', '==', currentUser.displayName))
      );
      setNbDocuments(querySnapshot.size);
    };
    fetchDocuments();
  }, [currentUser]);
  const liStyle = {
    width: textHidden ? '75px' : '100%', // Adjust '100px' to your desired width
    transition: 'width 2s', // 2-second transition for the width property
    marginRight: textHidden ? 'auto' : 'none',
  };
  const liStyle2 = {
    marginRight: textHidden ? '22px' : '0px', // Adjust '15px' to your desired left margin
    transition: 'margin-right 2s', // 2-second transition for the margin-left property
  };
  const liStyle3 = {
    width: textHidden ? '75px' : '200px',
    transition: 'width 0.5s',
    marginLeft: textHidden ? '7px' : '1px',
  }
  const liStyle4 = {
    width: textHidden ? 'auto' : '25%',
    transition: 'width  0.5s',
    marginRight: textHidden ? '0px' : '10px',
    marginLeft: textHidden ? '9px' : '5px',
    marginTop: textHidden ? '9px' : '21px',
  }
  const liStyle5 = {
    marginRight: textHidden ? '15px' : '0px',
    transition: 'margin-right 2s',
  }
  const liStyle6 = {
    width: textHidden ? '95px' : '200px',
    transition: 'width 2s',
  }
  const liStyle7 = {
    width: textHidden ? 'auto' : '110px',
    height: textHidden ?'75px' : 'auto',
    transition: 'width 2s',
  }

    return (
      <div className="App">
        <header className="App-header">
          <div className="connnexion">
            <div id="sidebar" style={liStyle6}>
              <ul className="Navlink">
              {textHidden ? <h1 className='Weblogo'><img src={site_imh2} style={liStyle7}/></h1> :
              <h1 className='Weblogo'><img src={site_imh} style={liStyle7}/></h1> 
              }<button className='navinteract' onClick={toggleText} style={liStyle2}>
                <span className="material-symbols-outlined">
              menu
              </span>
              </button>
                <li  style={liStyle}>

                <NavLink to="/nosbaz" className="Navlink">
                <span className="material-symbols-outlined">
                  shopping_cart
                </span>
                {textHidden ? null : t('buy_nav')}
                </NavLink></li>

                <li style={liStyle}>

                  <NavLink to="/vente" className="Navlink">
                  <span className="material-symbols-outlined">
                    sell
                  </span>
                  <p >{textHidden ? null : t('sell_nav')}</p>
                  </NavLink>
                  </li>
                <li style={liStyle}>

                  <NavLink to="/gestion" className="Navlink">
                 
                  <span className="material-symbols-outlined">
                      storefront
                    </span>
                    {textHidden ? null : t('gest_nav')}
                  </NavLink>
                  </li>
                  <li style={liStyle}>

                <NavLink to="/goldnostale" className="Navlink">
                  
                  <span className="material-symbols-outlined">
                    currency_exchange
                    </span>
                    {textHidden ? null : t('Nostale gold')}
                  </NavLink>
                </li>
                  <li style={liStyle}>

                  <NavLink to="/Estimate" className="Navlink">
                  
                  <span className="material-symbols-outlined">
                    search
                    </span>
                    {textHidden ? null : t('esti_nav')}
                  </NavLink>
                </li>
                <li style={liStyle}>

                  <NavLink to="/Account" className="Navlink">
                  <span className="material-symbols-outlined">
                  account_box
                  </span>
                  {textHidden ? null : t('Account Shop')}

                  </NavLink>
                  </li>
                <li style={liStyle}>

                  <NavLink to="/messagerie" className="Navlink">
                  <span className="material-symbols-outlined">
                    chat
                  </span>
                  {textHidden ? null : t('mess_nav')}
                  </NavLink>
                </li>
                <li style={liStyle}>

                  <NavLink to="/boutique" className="Navlink">
                  
                  <span className="material-symbols-outlined">
                      monetization_on
                    </span>
                    {textHidden ? null : t('abo_nav')}
                  </NavLink>
                </li>
                <li style={liStyle}>
               

                  <NavLink to="/Private/Private-Home" className="Navlink">
                  <span className="material-symbols-outlined">
                      settings
                    </span>
                    {textHidden ? null : t('para_nav')}
                  </NavLink>
                </li>
                <li style={liStyle}>

                  <NavLink to="/Discordjoin" className="Navlink">
                  <span className="material-symbols-outlined">
                      stadia_controller
                    </span>
                  {textHidden ? null : t('disc_nav')}

                  </NavLink>
                </li>

                <li className='reportbig' style={liStyle}>

                  <NavLink to="/RaportBug" className="Navlink" id="navrep">
                  
                  <span className="material-symbols-outlined" id="stop2">
                          report
                  </span>
                  {textHidden ? null : t('Report bug')}
                  </NavLink>
                </li>


                <li></li>
                <li></li>
              </ul>                      
                  
              <div className="Profil" style={liStyle3} >
                  <div className="img_pro pdp-containeur" style={liStyle4}>
              <img className="real_img_playeur" src={picture_sp_user_url}/>
              </div>
                  <div className="Logout" onClick={logOut}>
                    <button style={liStyle5} >
                      <span className="material-symbols-outlined">
                        logout
                      </span>
                    </button>
                  </div>
                  {textHidden ? <></> : 
                                    <div className='info_player'>
                                    <p className="pseudalcard">{t('pseu')} :{currentUser.displayName}</p>
                                      <p className='item_sell'>{t('item_sell')} : {nbDocuments}</p>
                                      <p>NosCoin: {nosdollar.toFixed(3)}</p>
                                  </div>
                               
                                }
                </div>
            </div>           
          </div> 
           
          </header>
      </div>
    );
  }
  
  export default Navbar;
  