import React ,{useState} from 'react';
import imageSp1 from "../../../nostale/Accesoire/Collier/80-95/80.png"
import imageSp2 from "../../../nostale/Accesoire/Collier/80-95/85.png"
import imageSp3 from "../../../nostale/Accesoire/Collier/80-95/85v.png"
import imageSp4 from "../../../nostale/Accesoire/Collier/80-95/90.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Collier80_95 = (props) => {
      const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
        <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
        <button type='button'  onClick={() => handleClick( "collier_80", imageSp1)}><img src={imageSp1}></img></button>
        <button type='button'  onClick={() => handleClick( "collier_85", imageSp2)}><img src={imageSp2}></img></button>
        <button type='button'  onClick={() => handleClick( "collier_85v", imageSp3)}><img src={imageSp3}></img></button>
        <button type='button'  onClick={() => handleClick( "collier_90", imageSp4)}><img src={imageSp4}></img></button>
        </div>
    );
};

export default Collier80_95;