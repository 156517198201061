import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase-config';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { ToastContainer,toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
    const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success(t('mdp_3'), {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        navigate("/");
        // Password reset email sent!
        // ..
      })
      .catch((error) => { 
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };

  return (
    <div className='Modal_all'>

      <div className="Overlay"></div>

    <div className='Signup_Modalus' style={{minnWidth: "400px"}}>
      <h2 className='mb-3'>{t('mdp_1')}</h2>
      {message && <div className='alert alert-info'>{message}</div>}
      <form onSubmit={handleSubmit} className='frgt_pswd'>
        <div className='form-groupu'>
          <p>🔐 Pour réinitialiser votre mot de passe, veuillez entrer l'adresse e-mail associée à votre compte. Nous vous enverrons un lien de réinitialisation à cette adresse. 📧</p>
          <input type='email' className='form-control' id='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='email@email.xx' />
        </div>
        <button className="btn-class-name">
                    <span>{t('Sumbit')}</span>
                    <svg viewBox="0 0 320 512" height="1em" xmlns="http://www.w3.org/2000/svg">
                      <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z">
                    </path>
                    </svg>
                  </button>
      </form>
      <ToastContainer />
      </div>

    </div>  
  );
};

export default ForgotPasswordPage;