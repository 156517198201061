import React,{useState} from 'react';
import imageArmure1 from "../../../nostale/res/gants/30h-60h/40grde.png"
import imageArmure2 from "../../../nostale/res/gants/30h-60h/40grdefake.png"

import { useTextHidden } from '../../../Components/TextHiddenContext';
const Grant30h_60h = (props) => {
  const { textHidden, toggleText } = useTextHidden();
   const {navStyle3} = props;
  const { handleSpClick } = props;
  const [selectedSp, setSelectedSp] = useState(null);
  const handleClick = async (id, image) => {
    console.log(image)
    try {
      handleSpClick(id,image);
      setSelectedSp(image);
    } catch (err) {
      console.log(err);
    }
  };
    return (
        <div className='Arme_archer60_80 propose-item' id="Arme_archer60_80" style={navStyle3}>
          <button type='button' id="sp1a" onClick={() => handleClick("gant_40h", imageArmure1)} ><img src={imageArmure1}></img></button>
            <button type='button' id="sp2a" onClick={() => handleClick("gant_40hfake", imageArmure2)} ><img src={imageArmure2}></img></button>
        </div>
    );
};

export default Grant30h_60h;