import React ,{useState} from 'react';
import imageSp1 from "../../../nostale/Accesoire/Anneau/0h-30h/10h.png"
import imageSp2 from "../../../nostale/Accesoire/Anneau/0h-30h/12he.png"
import imageSp3 from "../../../nostale/Accesoire/Anneau/0h-30h/12hz.png"
import imageSp4 from "../../../nostale/Accesoire/Anneau/0h-30h/15h.png"
import imageSp5 from "../../../nostale/Accesoire/Anneau/0h-30h/15ho.png"
import { useTextHidden } from '../../../Components/TextHiddenContext';
const Anneau0_30h = (props) => {
  const { textHidden, toggleText } = useTextHidden();
  const {navStyle3} = props;
    const { handleSpClick } = props;
    const [selectedSp, setSelectedSp] = useState(null);
    const handleClick = async (id, image) => {
      console.log(image)
      try {
        handleSpClick(id,image);
        setSelectedSp(image);
      } catch (err) {
        console.log(err);
      }
    };
    return (
<div className='propose-item' style={navStyle3}>
        <button type='button'  onClick={() => handleClick( "anneau_10h", imageSp1)}><img src={imageSp1}></img></button>
        <button type='button'  onClick={() => handleClick( "anneau_12he", imageSp2)}><img src={imageSp2}></img></button>
        <button type='button'  onClick={() => handleClick( "anneau_12hz", imageSp3)}><img src={imageSp3}></img></button>
        <button type='button'  onClick={() => handleClick( "anneau_15h", imageSp4)}><img src={imageSp4}></img></button>
        <button type='button'  onClick={() => handleClick( "anneau_15ho", imageSp5)}><img src={imageSp5}></img></button>
</div>
    );
};

export default Anneau0_30h;