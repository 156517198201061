import React from 'react';
import Bazar_online_page from '../Components/Bazar_online_page';
import Navbar from '../Components/Navbar';

const AcheterNos = () => {
    return (
        <div>
            <Navbar/>
            <Bazar_online_page/>
        </div>
    );
};

export default AcheterNos;