import React,{useEffect,useState,useContext} from 'react';

import { auth, db2, storage} from "../../../../firebase-config";
import {v4} from "uuid";
import { AuthContext } from '../../../../Context/AuthContext';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes  } from "firebase/storage";
import { doc, serverTimestamp, setDoc,getDocs,query,collection,where,getDoc,updateDoc,FieldValue } from "firebase/firestore"; 
import { useNavigate } from 'react-router-dom';
import imageSp1 from "../../../../nostale/Accesoire/Chapeau/60h-80h/80e.png"
import imageSp2 from "../../../../nostale/Accesoire/Chapeau/60h-80h/80hc.png"
import imageSp3 from "../../../../nostale/Accesoire/Chapeau/60h-80h/80hh.png"
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import imgload from "../../../../photo_anex/load.gif"
    import { useTranslation } from 'react-i18next';
     import { useTextHidden } from '../../../../Components/TextHiddenContext';
const ChapeauVente60_80h = (props) => {
         const { textHidden, toggleText } = useTextHidden();
  const {navStyle3} = props;
      const { t } = useTranslation();
  const [orPure, setOrPure] = useState(0);
  const [price,setPrice] =useState();
  const [uid_form,setUid_from] = useState('')
  const [picture_sp,SetpictureSp] = useState({})
  const [what_sp,SetWhatSp] = useState("")
  const [quantity,setQuantity] = useState(0)
  const navigate = useNavigate(0);  
  const { currentUser } = useContext(AuthContext);
  const {serveur} = props;
  const { setIsDivEntespVisible } = props;
  const[imageGestion,setImageGestion]=useState();
  const [runes, setRunes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const[trade,setTrade]=useState(false);
  const [picture_spT,setPicture_spT]=useState(0)
  const [showModal, setShowModal] = useState(false);
  const [texteConfirme,setTextConfirm]=useState("?")
  const [nosdollarPay,setNosDollarPay]=useState()
  const tryToPay = async (e) => {
   
    const executeSetDoc = async () => {
   
      const docSnapshot = await getDoc(doc(db2, "users", currentUser.uid));
      const data = docSnapshot.data();
      const nosDollarValue = data.Nosdollar || 0;
      
      if (nosDollarValue >= nosdollarPay ) {
        const imageRef = ref(storage, `images/${picture_sp.name + uid_form}`);
        await uploadBytes(imageRef, picture_sp);
    
        
        // Décrémente la valeur de Nosdollar dans la table par la valeur de nosdollarPay
        const updatedNosDollarValue = nosDollarValue - nosdollarPay;
        await updateDoc(doc(db2, "users", currentUser.uid), { Nosdollar: updatedNosDollarValue });
        
        await setDoc(doc(db2, "vente", uid_form), {
          senderUid: currentUser.uid,
          classe: "All",
          itemo: what_sp,
          prix: price,
          serveur : serveur,
          currentUserUid :currentUser.uid,
          quantity: quantity,
          timeStamp: serverTimestamp(),
          image_url : `images/${picture_sp.name + uid_form}`,
          Pseudonim : displayNamer,
          uid_form : uid_form ,
          image_gestion: imageGestion,
          trade: trade
        });
  
        toast.success(t("posted"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setShowModal(false); 
        window.location.reload();
        return; // Arrête l'exécution de la fonction ici
      } else {
        toast.error(t("mor_nos"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setShowModal(false);
        return; // Arrête l'exécution de la fonction ici
      }
    };
    await executeSetDoc();
  };
  
  
  const uploadImage = async (e) => { 
  
    if (picture_spT === 0){
      toast.error(t("Take picture of item"), {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    } 
  
    const executeSetDoc = async () => {
      const querySnapshot = await getDocs(
        query(collection(db2, "vente"), where("FirstPost", "==", currentUser.uid))
      );
      if (querySnapshot.empty) {
        const imageRef = ref(storage, `images/${picture_sp.name + uid_form}`);
        uploadBytes(imageRef, picture_sp).then(() => {
        });
        await setDoc(doc(db2, "vente", uid_form), {
          senderUid: currentUser.uid,
          classe: "All",
          itemo: what_sp,
          prix: price,
          serveur : serveur,
          currentUserUid :currentUser.uid,
          quantity: quantity,
          timeStamp: serverTimestamp(),
          image_url : `images/${picture_sp.name + uid_form}`,
          Pseudonim : displayNamer,
          uid_form : uid_form ,
          image_gestion: imageGestion,
          trade: trade,
          FirstPost: currentUser.uid,
        });
        toast.success(t("posted"), {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        window.location.reload();
        return; // Arrête l'exécution de la fonction ici
      } else {
              const amountNosdollar = (price / 200) * 0.75;
      const roundedAmountNosdollar = amountNosdollar.toFixed(2); // Arrondir à 2 décimales
      
      // Vérifier si la valeur arrondie est inférieure à 0.1 et ajuster si nécessaire
      const finalAmountNosdollar = roundedAmountNosdollar < 0.1 ? 0.1 : roundedAmountNosdollar;
      
      setNosDollarPay(finalAmountNosdollar);
      const confirmText = `${t('coutera')} ${finalAmountNosdollar} NosCoin.`;
      setTextConfirm(confirmText);
      setShowModal(true);
      }
    };
  
    await executeSetDoc();
  };
  const hideModal = () => {
    setShowModal(false);
    setspModals({ modalsellsp: false });
  };
  const setTradeur = (event) => {
    event.preventDefault();
    setTrade(!trade);
  };
const handlePictureChange = (e) => {
  SetpictureSp(e.target.files[0]);
  setModalOpen(true);
  setPicture_spT(1);
};

const closeModal = () => {
  setModalOpen(false);
};


  const displayNamer = currentUser.displayName ;

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  useEffect(() => {
    setOrPure(price * 1000000);
  }, [price]);


      const [spModals, setspModals] = useState({
        modalsellsp:false
      })
    
      const sellModals = modal => {
        setUid_from (v4())
        if(modal === "sp1") {
          setspModals({
            modalsellsp:true
          })
          SetpictureSp (imageSp1) 
          SetWhatSp ("chap_80e")
          setIsDivEntespVisible(true) 
          setImageGestion('../../../nostale/Accesoire/Chapeau/60h-80h/80e.png')
          
        }
        if(modal === "sp2") {
          setspModals({
            modalsellsp:true
          })
          SetpictureSp (imageSp2)
          SetWhatSp ("chap_80hc") 
          setIsDivEntespVisible(true)
          setImageGestion('../../../nostale/Accesoire/Chapeau/60h-80h/80hc.png')
        }
        if(modal === "sp3") {
    
          setspModals({
            modalsellsp:true
          })
          SetpictureSp (imageSp3)
          SetWhatSp ("chap_80hh") 
          setIsDivEntespVisible(true)
          setImageGestion('../../../nostale/Accesoire/Chapeau/60h-80h/80hh.png')
        }
       
    }
    return (
        <>
    <div className='propose-item' style={navStyle3}>
    <button type='button' id="vente_sp1" onClick={() => sellModals('sp1')}><img src={imageSp1}></img></button>
      <button type='button' id="vente_sp1" onClick={() => sellModals('sp2')}><img src={imageSp2}></img></button>
      <button type='button'  id="vente_sp1" onClick={() => sellModals('sp3')}><img src={imageSp3}></img></button>
    
      
    </div>
    <>
    {spModals.modalsellsp && (
    
    <div id="diventesp" className="vente">
  
      <div className="card_left">
  
        <h3>{t('detail_sell')}</h3>
        <img src={picture_sp}/>
        {orPure ?
    <p className="orpur">Or: {formatNumber(orPure)}</p>: <></>}
   <div className="innputor"><input type="number" placeholder="Price(kk)" className='inputvalu' onChange={e=>setPrice(e.target.value)}/><p> kk</p></div>
   <div className="quantiT">
    <p>Quantity :</p><input type="number"  onChange={e=>setQuantity(e.target.value)} className='quantitu' min="0" max="999" step="0" value={quantity}/></div>
        <div className='Trade_sell'>
    <p>{t('trade')} :</p>
    <button
    className={`btn_trade_sell ${trade ? 'blue' : 'red'}`}
    onClick={setTradeur}
  >
        <span className="material-symbols-outlined">
          deployed_code_alert
        </span>
      </button>
    </div>
      </div>
  
    <div className="card_right">
                    <h3>Image</h3>
                    <div className='file_up'>

<label htmlFor="file_sell" className="custum-file-upload">
  <div className="icon">
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 10 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" fill="#000000"></path>
    </svg>
  </div>
  <div className="text">
    <span>Click to upload image</span>
  </div>
  <input 
    id="file_sell"
    type="file"
    accept="image/*"
    onChange={handlePictureChange}
  />
</label> 
{modalOpen && (
        <div className="modal picture_sell">
          <div className="modal-contents">
                                      
            <img src={URL.createObjectURL(picture_sp)} alt="Selected" />
            <span className="material-symbols-outlined close_picture" onClick={closeModal}>close</span>
          </div>
        </div>
      )}
  <button type="button" className='sumbit_img' onClick={uploadImage}>
  <p className="button-text">{t('Submit')}</p> <p className="iconer"><svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"></path><path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="currentColor"></path></svg></p> </button>
   {showModal && (
                <div className="modal_del">
                  <div className="card">
                <div className="card-content">
                  <p className="card-heading">{texteConfirme}</p>
                  <p className="card-description">{t('real_creat')}</p>
                </div>
                <div className="card-button-wrapper">
                  <button className="card-button secondaryr"  type="button" onClick={hideModal}>Cancel</button>
                  <button className="card-button primaryr" type="button" onClick={tryToPay}>confirme</button>
                </div>
                
                <button className="exit-button" onClick={hideModal}>
                  <svg height="20px" viewBox="0 0 384 512">
                    <path
                      d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
                    ></path>
                  </svg>
                </button>
              </div>
                </div>
              )}
                </div>
  </div>
        </div>
  
      )}
    </>
    <ToastContainer />
    </>
    );
};

export default ChapeauVente60_80h;